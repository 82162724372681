import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Editor from '@monaco-editor/react'
import configs from './config/config.json'
import examples from './config/examples'

class CodeEditor extends React.Component {
  constructor () {
    super()
    this.state = {
      theme: 'vs-dark',
      language: 'javascript',
      languageId: 19,
      themes: [],
      languages: [],
    }
  }
  componentDidMount () {
    let themes = []
    configs.themes.forEach((item, i) => (
      themes.push(<option key={i} value={item.key}>{item.name}</option>)
    ))
    let languages = []
    configs.languages.forEach((item, i) => (
      languages.push(<option key={i} value={item.id}>{item.name}</option>)
    ))
    this.setState({
      languages: languages,
      themes: themes
    })
  }
  changeLanguage(e) {
    this.setState({
      languageId: e.target.value,
      language: configs.languages[e.target.value-1].name
    })
  }
  changeTheme(e) {
    this.setState({
      theme: e.target.value
    })
  }
  render () {
    return (
      <div>
        <div>
          <select className="course-creation-input-select"
                  onChange={this.changeTheme.bind(this)} value={this.state.theme}>
            {this.state.themes}
          </select>
          <select className="course-creation-input-select"
                  onChange={this.changeLanguage.bind(this)} value={this.state.languageId}>
            {this.state.languages}
          </select>
        </div>
        <Editor
          height="80vh"
          theme={this.state.theme}
          path={this.state.language}
          defaultLanguage={this.state.language}
          defaultValue={examples[this.state.languageId]}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CodeEditor)))

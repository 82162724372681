import React from 'react'
import SearchPanel from '../search/SearchPanel'
import { faEnvelope, faLock, faTimes, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import './session.css'
import SocialLogin from './SocialLogin'

class SessionForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      userName: '',
      password: '',
      email: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  update (field) {
    return e => this.setState({
      [field]: e.currentTarget.value
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    this.props.processForm(this.state).then(() => {
      this.props.fetchAllMenus()
    })
  }

  renderUserName () {
    const { t } = this.props
    if (this.props.formType === 'Sign Up') {
      return (
        <label className="login-label"><FontAwesomeIcon icon={faUser}/>
          <input type="text"
                 value={this.state.userName}
                 onChange={this.update('userName')}
                 className="login-input"
                 placeholder={t('session.placeholder.name')}
          />
        </label>
      )
    }
  }

  renderButton () {
    const { t } = this.props
    if (this.props.formType === 'Sign Up') {
      return (
        <div className="login-bottom">
          <span>{t('session.title.signUp')}</span>
          <span onClick={() => this.props.removeErrors()}>{this.props.navLink}</span>
        </div>
      )
    } else {
      return (
        <div className="login-bottom">
          <span>{t('session.title.signIn')}</span>
          <span onClick={() => this.props.removeErrors()}>{this.props.navLink}</span>
        </div>
      )
    }
  }

  renderErrorsComponent () {
    const { t } = this.props
    if (this.props.errors.length !== 0) {
      return (
        <div className="error-box">{t('error.signIn')}</div>
      )
    }
  }

  render () {
    const { t } = this.props
    return (
      <div className="no-scroll">
        <SearchPanel/>
        <div className="modal-back"></div>
        <div className="login-form-container">
          <div className="login-header">
            {this.props.header}
            <Link to="/" className="modal-close" onClick={() => this.props.removeErrors()}>
              <FontAwesomeIcon icon={faTimes}/>
            </Link>
          </div>
          <div className="login-content">
            <SocialLogin/>
            <div className="or-separator">
              <span className="or-text">OR</span>
            </div>
          </div>
          <form onSubmit={this.handleSubmit} className="login-form-box">
            {this.renderErrorsComponent()}
            {this.renderUserName()}
            <label className="login-label">
              <FontAwesomeIcon icon={faEnvelope}/>
              <input type="text"
                     value={this.state.email}
                     onChange={this.update('email')}
                     className="login-input"
                     placeholder={t('session.placeholder.email')}
              />
            </label>
            <label className="login-label"><FontAwesomeIcon icon={faLock}/>
              <input type="password"
                     value={this.state.password}
                     onChange={this.update('password')}
                     className="login-input"
                     placeholder={t('session.placeholder.password')}
              />
            </label>
            <input className="session-submit" type="submit" value={t(`${this.props.formType}`)}/>
          </form>
          <div>{this.renderButton()}</div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(SessionForm)

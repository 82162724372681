import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { store } from 'react-notifications-component'

export const getDuration = (time) => {
  if (time <= 0) {
    return ''
  }

  let hours = Math.floor(time / 3600)
  let mins = Math.floor(time / 60) - (hours * 60)
  let secs = (time % 60).toString()
  if (secs.length === 1) {
    secs = `0${secs}`
  }
  let res = `${mins}:${secs}`
  if (hours > 0) {
    res = `${hours}:${res}`
  }
  return res
}

export const getPrice = (price) => {
  if (!price || price === 0) {
    return ''
  }

  let str = price.toString()
  return str.slice(0, str.length - 2) + '.' + str.slice(str.length - 2)
}

export const getRating = (rating) => {
  let items = []
  for (let i = 0; i < 5; i++) {
    if (rating >= i + 1) {
      items.push(<FontAwesomeIcon key={i} style={{ padding: '3px', color: '#f4c150' }} icon={faStar}/>)
    } else if (rating < i + 1 && rating > i) {
      items.push(<FontAwesomeIcon key={i} style={{ padding: '3px', color: '#f4c150' }} icon={faStarHalfAlt}/>)
    } else {
      items.push(<FontAwesomeIcon key={i} style={{ padding: '3px', color: '#dedfe0' }} icon={faStar}/>)
    }
  }

  return (
    <div> {items} </div>
  )
}

export const addNotification = (message, type = 'success') => {
  store.addNotification({
    message: message,
    type: type,
    insert: 'top',
    container: 'top-center',
    dismiss: {
      duration: 1000
    }
  })
}

export const coalesce = (...args) => args.find(_ => ![undefined, null].includes(_));

export const getUrlParameter = (url, name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(url)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

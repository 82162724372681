import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './process-file.css'
import { getFile, uploadMultipleFile } from '../../services/FileService'
import { withTranslation } from 'react-i18next'
import { DOC_TYPE_IMAGE, DOC_TYPE_VIDEO } from '../../utils/Contants'

class MultipleFileUpload extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      files: [],
      progress: 0,
      submitted: false,
      fileId: null,
      previewUrl: '',
    }
    this.timer = 0
    this.updateProgress = this.updateProgress.bind(this)
  }
  componentDidMount () {
    console.log(this.props.fileIds)

  }

  updateProgress () {
    this.props.getFile(this.state.fileId).then(response => {
      this.setState({
        progress: response.file.progress
      })
      if (response.file.progress === 100) {
        clearInterval(this.timer)
        this.setState({
          submitted: false
        })
      }
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    const formData = new FormData()
    formData.append('files', this.state.files)
    formData.append('async', this.props.async)
    if (this.props.path) {
      formData.append('path', this.props.path)
    }
    this.props.uploadMultipleFile(formData, this.props.docType).then(response => {
      if (response.file.type === DOC_TYPE_IMAGE) {
        this.props.onUpdateImage(response.file.id)
      } else if (response.file.type === DOC_TYPE_VIDEO) {
        this.props.onUpdateVideo(this.props.i, this.props.j, response.file.id)
      } else {
        this.props.onUpdateFile(response.file.id)
      }
      this.setState({
        files: this.state.files.concat(response.file),
        submitted: true,
      }, () => {
        if (this.timer === 0) {
          this.timer = setInterval(this.updateProgress, 5000)
        }
      })
    })

  }

  handleFileChange (e) {
    e.preventDefault()

    let files = e.target.files
    if (!this.props.async) {
      const formData = new FormData()
      if (this.props.path) {
        formData.append('path', this.props.path)
      }
      for (let file of files) {
        formData.append('files', file)
      }
      this.props.uploadMultipleFile(formData, this.props.docType).then(response => {
        const data = response.file
        let fileIds = []
        for (let item of data) {
          const type = item.type
          const fileId = item.id
          if (type === DOC_TYPE_IMAGE) {
            this.props.onUpdateImage(fileId)
          } else if (type === DOC_TYPE_VIDEO) {
            this.props.onUpdateVideo(this.props.i, this.props.j, fileId)
          } else if (this.props.onUpdateFile) {
            this.props.onUpdateFile(fileId)
          } else {
            fileIds.push(fileId)
          }
        }
        if (this.props.onUpdateMultipleFile) {
          this.props.onUpdateMultipleFile(fileIds)
        }
        this.setState({
          files: this.state.files.concat(data),
        })
      })
    }
  }

  render () {
    const fileItems = this.state.files.map((item, index) => (
      <div key={index}>
        {item.name}
      </div>
    ))
    return (
      <div>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          {this.props.directory ?
            (<input className="file-input" type="file" directory="" webkitdirectory=""
                    onChange={(e) => this.handleFileChange(e)}/>) :
            (<input className="file-input" type="file" multiple={true}
                    onChange={(e) => this.handleFileChange(e)}/>)}
          {this.props.async ?
            <button type="submit" className="upload-button"
                    onClick={(e) => this.handleSubmit(e)}>Upload</button>
            : null}
        </form>
        <div>
          {fileItems}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  file: Object.values(state.file),
})

const mapDispatchToProps = dispatch => ({
  uploadMultipleFile: (data, docType) => dispatch(uploadMultipleFile(data, docType)),
  getFile: (id) => dispatch(getFile(id)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MultipleFileUpload)))

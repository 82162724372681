import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { fetchMyClasses } from '../../services/LearningService'
import PagingDataTable from '../../components/table/PagingDataTable'
import MyClassItem from '../../components/classes/MyClassItem'

class MyClass extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      query: {
        page: 0,
        size: 20,
      }
    }
  }

  componentDidMount () {
    this.props.fetchMyClasses(this.state.query)
  }

  handleClickPage (page) {
    this.setState({
      currentPage: page
    }, () => {
      this.search()
    })
  }

  search () {
    let request = this.state.query
    request.page = this.state.currentPage - 1
    this.props.filterCourses(request)
  }

  render () {
    const { t } = this.props
    const items = this.props.classes[0]?.data?.map((x, i) =>
      <div key={i} className="course-tile">
        <MyClassItem key={i} class={x}/>
      </div>
    )
    return (
      <div className="index">
        <div className="heading-creation-box">
          {t('home.title.myClass')}
        </div>
        <PagingDataTable items={items} currentPage={this.state.currentPage} totalPage={this.props.classes[0]?.totalPage}
                         onChangePage={this.handleClickPage.bind(this)}/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  classes: Object.values(state.myClasses)
})

const mapDisPatchToProps = dispatch => ({
  fetchMyClasses: (query) => dispatch(fetchMyClasses(query)),
})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(MyClass)))

import React from 'react'
import { faComment, faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTranslation } from 'react-i18next'
import Languages from '../../components/common/languages.json'
import defaultImage from '../../assets/default-image.png'
import * as CommonUtils from '../../utils/CommonUtils'
import './course.css'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import CoursePathIndicator from '../common/CoursePathIndicator'

class CourseHeading extends React.Component {
  constructor (props) {
    super(props)

    this.videoOpenHandle = this.videoOpenHandle.bind(this)
  }

  videoOpenHandle () {
    let courseId = this.props.video.contentId
    this.props.showPlayer(0, courseId)
  }

  render () {
    const { t } = this.props
    const image = this.props.course.image ? this.props.course.image : defaultImage
    return (
      <div className="heading_box">
        <div className="course-feed-img-box">
          <div className="img-play-box" onClick={this.videoOpenHandle}>
            <img className="course-header-image" src={image} alt=""/>
            <div className="play-circle">
              <FontAwesomeIcon icon={faPlayCircle} className="play-circle-icon"/>
            </div>
          </div>
          <div className="course-header-price">{CommonUtils.getPrice(this.props.course.price)}</div>
          {this.props.session.id ? (
            <button onClick={this.props.onAddCourse} className="add-course-button">
              {t('label.addCourse')}
            </button>
          ) : null}
        </div>
        <div>
          <CoursePathIndicator className="index-rating-span" data={this.props.course}/>
          <div className="course-title">{this.props.course.title}</div>
          <div className="course-subtitle">{this.props.course.subtitle}</div>
          <div className="index-card-ratting-feed">
            <span className="index-rating-span">{CommonUtils.getRating(this.props.course.rating)}</span>
            <span className="index-rating-span">{this.props.course.rating}</span>
            <span className="index-rating-span">({this.props.course.ratingNumber} {t('label.ratings')})</span>
            <span className="index-rating-span">{this.props.course.view} {t('label.view')}</span>
          </div>

          <div><span className="course-instructor">{t('label.createdBy')} {this.props.course.instructor}</span>
            <FontAwesomeIcon style={{ color: '#fff' }} icon={faComment}/>{Languages[this.props.course.language]}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  session: state.session
})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CourseHeading)))

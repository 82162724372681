import * as API from './API'

export const connectConnection = (request) => (
  API.post(`labs/connections/connect`, request)
)

export const disconnectConnection = (connectionId) => (
    API.post(`labs/connections/disconnect/${connectionId}`, {})
)

export const fetchConnection = (request) => (
  API.get(`labs/connections?laboratoryId=${request.laboratoryId}`)
)

export const filterConnections = (query) => (
  API.post(`labs/connections/filter`, query)
)

export const detailConnection = (connectionId) => (
  API.get(`labs/connections/detail/${connectionId}`)
)

export const releaseConnection = (connectionId) => (
  API.post(`labs/connections/${connectionId}/release`)
)

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import MultipleLineTable from '../../components/table/MultipleLineTable'
import { fetchBestClasses, fetchMoreBestClasses } from '../../services/ClassService'
import ClassItem from '../../components/classes/ClassItem'

class BestClass extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      page: 0,
      size: 10,
      more: true
    }
  }

  componentDidMount () {
    this.props.fetchBestClasses(0, this.state.size)
  }

  seeMore () {
    const page = this.state.page + 1
    this.props.fetchMoreBestClasses(page, this.state.size).then((response) => {
      const more = response.classes.length > 0
      this.setState({
        more: more,
        page: page
      })
    })
  }

  exploreAll () {
    this.props.history.push('/classes/search/')
  }

  render () {
    const { t } = this.props
    if (this.props.classes.length === 0) {
      return null
    }

    const items = this.props.classes.map((x, i) =>
      <div key={i} className="course-tile">
        <ClassItem key={i} class={x}/>
      </div>
    )
    return (
      <div className="index">
        <div className="index-header-container">
          <div className="index-header">{t('home.title.class')}</div>
        </div>
        <MultipleLineTable items={items} more={this.state.more}
                           onSeeMore={this.seeMore.bind(this)} onExploreAll={this.exploreAll.bind(this)}/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  classes: Object.values(state.bestClasses)
})

const mapDisPatchToProps = dispatch => ({
  fetchBestClasses: (page, size) => dispatch(fetchBestClasses(page, size)),
  fetchMoreBestClasses: (page, size) => dispatch(fetchMoreBestClasses(page, size))
})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(BestClass)))

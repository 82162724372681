import * as API from './API'

export const crawlingYoutube = (data) => (
  API.post(`crawling/youtube`, data)
)

export const fetchAllCrawling = (query) => {
  let url = `crawling?page=${query.page}&size=${query.size}`
  url = query.value ? `${url}&value=${query.value}` : url
  return API.get(url)
}

export const deleteCrawling = (id) => (
  API.remove(`crawling/${id}`)
)

export const deleteAllCrawling = () => (
  API.remove(`crawling`)
)

export const applyCrawling = (id, data) => (
  API.post(`crawling/${id}/apply`, data)
)

export const applyMultipleCrawling = (data) => (
  API.post(`crawling/apply`, data)
)

export const triggerCrawlingJob = (data) => (
  API.post(`jobs/trigger`, data)
)

export const getRunningJob = () => (
  API.get(`jobs`)
)

import React from 'react'
import { withTranslation } from 'react-i18next'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import './calendar.css'

class CalendarComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      date: null,
      mark: this.props.calendar?.actualDays
    }
  }

  onChange () {

  }

  render () {
    return (
      <Calendar
        onChange={this.onChange.bind(this)}
        value={this.state.date}
        tileClassName={({ date, view }) => {
          if (this.state.mark.find(x => x === date.getTime())) {
            return 'highlight'
          }
        }}
      />
    )
  }
}

export default withTranslation()(CalendarComponent)

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { addNotification } from '../../../../utils/CommonUtils'
import { createMachine, editMachine, fetchMachine } from '../../../../services/MachineService'
import { fetchConfiguration } from '../../../../services/ConfigurationService'

class MachineCreation extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      script: '',
      network: {},
      credential: {},
      limit: {},
      os: {},
      status: '',
      edit: false,
    }
  }

  componentDidMount () {
    this.props.fetchConfiguration()
    this.updateState()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.match.params.machineId !== prevProps.match.params.machineId) {
      this.updateState()
    }
  }

  updateState () {
    const machineId = this.props.match.params.machineId
    if (machineId) {
      this.setState({
        edit: true
      })
      this.props.fetchMachine(machineId).then(response => {
        this.setState({
          name: response.data.name,
          script: response.data.script,
          description: response.data.description,
          network: response.data.network,
          credential: response.data.credential,
          limit: response.data.limit,
          os: response.data.os ? response.data.os : {},
          status: response.data.status,
        })
      })
    } else {
      this.setState({
        name: '',
        script: '',
        description: '',
        network: {},
        credential: {},
        limit: {},
        os: {},
        status: '',
        edit: false,
      })
    }
  }

  changeName (e) {
    this.setState({
      name: e.target.value
    })
  }

  changeLimit (e) {
    const limit = {
      max: e.target.value
    }
    this.setState({
      limit: limit
    })
  }

  changeOsHandle (e) {
    const os = {
      distro: e.target.value,
    }
    this.setState({
      os: os
    })
  }

  changeDescription (e) {
    this.setState({
      description: e.target.value
    })
  }

  changeScript (e) {
    this.setState({
      script: e.target.value
    })
  }

  changePrivateIp (e) {
    let network = this.state.network
    network.privateIp = e.target.value
    this.setState({
      network: network
    })
  }

  changePublicIp (e) {
    let network = this.state.network
    network.publicIp = e.target.value
    this.setState({
      network: network
    })
  }

  changeDomain (e) {
    let network = this.state.network
    network.domain = e.target.value
    this.setState({
      network: network
    })
  }

  changeUserName (e) {
    let credential = this.state.credential
    credential.userName = e.target.value
    this.setState({
      credential: credential
    })
  }

  changePrivateKey (e) {
    let credential = this.state.credential
    credential.privateKey = e.target.value
    this.setState({
      credential: credential
    })
  }

  submitHandle (status) {
    const { t } = this.props
    const data = {
      name: this.state.name,
      description: this.state.description,
      script: this.state.script,
      limit: this.state.limit,
      os: this.state.os,
      network: this.state.network,
      credential: this.state.credential,
      status: status === '' ? null : status,
    }
    const machineId = this.props.match.params.machineId
    if (machineId) {
      this.props.editMachine(machineId, data)
    } else {
      this.props.createMachine(data)
    }
    addNotification(t('notification.class.create'))
    this.props.history.push('/admin/machines/management')
  }

  renderButton () {
    const { t } = this.props
    if (this.state.edit) {
      return (
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this, '')} className="submit-button">
            {t('SAVE')}
          </button>
          {this.state.status === 'DRAFT' ? (
            <button onClick={this.submitHandle.bind(this, 'SUBMITTED')} className="submit-button">
              {t('SUBMIT')}
            </button>
          ) : null
          }
        </div>
      )
    } else {
      return (
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this, 'DRAFT')} className="submit-button">
            {t('SAVE DRAFT')}
          </button>
          <button onClick={this.submitHandle.bind(this, 'SUBMITTED')} className="submit-button">
            {t('SUBMIT')}
          </button>
        </div>
      )
    }
  }

  render () {
    const { t } = this.props
    const distros = this.props.configuration?.distros?.map((item, i) => (
      <option key={i} value={item}>{t(`${item}`)}</option>
    ))
    return (
      <div>
        <div className="heading-creation-box">
          {t('management.machine.title.create')}
        </div>
        <div className="course-creation-item">
          <span>{t('management.machine.name')}:</span>
          <input className="course-creation-input-text" type="text" value={this.state.name}
                 onChange={this.changeName.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.machine.limit')}:</span>
          <input className="course-creation-input-text" type="text" value={this.state.limit.max}
                 onChange={this.changeLimit.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <div className="laboratory-creation-input-label">{t('management.machine.os')}:</div>
          <select className="course-creation-input-select" value={this.state.os.distro}
                  onChange={this.changeOsHandle.bind(this)}>
            <option value="">{t('selection.default')}</option>
            {distros}
          </select>
        </div>
        <div className="course-creation-item">
          <span>{t('management.machine.description')}:</span>
          <textarea className="learning-goals-textarea" value={this.state.description || ''}
                    onChange={this.changeDescription.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.machine.script')}:</span>
          <textarea className="learning-goals-textarea" value={this.state.script || ''}
                    onChange={this.changeScript.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <div className="input-box">
            <div className="machine-creation-input-div">
              <div className="machine-creation-input-label">{t('management.machine.network.privateIp')}:</div>
              <input className="course-creation-input-text" type="text" value={this.state.network.privateIp}
                     onChange={this.changePrivateIp.bind(this)}/>
            </div>
            <div className="machine-creation-input-div">
              <div className="machine-creation-input-label">{t('management.machine.network.publicIp')}:</div>
              <input className="course-creation-input-text" type="text" value={this.state.network.publicIp}
                     onChange={this.changePublicIp.bind(this)}/>
            </div>
            <div className="machine-creation-input-div">
              <div className="machine-creation-input-label">{t('management.machine.network.domain')}:</div>
              <input className="course-creation-input-text" type="text" value={this.state.network.domain}
                     onChange={this.changeDomain.bind(this)}/>
            </div>
          </div>
        </div>
        <div className="course-creation-item">
          <div className="machine-creation-input-label">{t('management.machine.credential.userName')}:</div>
          <input className="course-creation-input-text" type="text" value={this.state.credential.userName}
                 onChange={this.changeUserName.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <div className="machine-creation-input-label">{t('management.machine.credential.privateKey')}:</div>
          <textarea className="learning-goals-textarea" value={this.state.credential.privateKey || ''}
                    onChange={this.changePrivateKey.bind(this)}/>
        </div>
        {this.renderButton()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  machines: Object.values(state.machines),
  configuration: state.configuration
})

const mapDispatchToProps = dispatch => ({
  createMachine: (data) => dispatch(createMachine(data)),
  editMachine: (machineId, data) => dispatch(editMachine(machineId, data)),
  fetchMachine: (machineId) => dispatch(fetchMachine(machineId)),
  fetchConfiguration: () => dispatch(fetchConfiguration()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MachineCreation)))

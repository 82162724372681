import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import './tool.css'
import Jitsi from 'react-jitsi'
import { jitsi } from '../../utils/Settings'
import { createMeeting, getInvitedMeeting, inviteMeeting } from '../../services/MeetingService'

class MeetingTool extends React.Component {
  constructor () {
    super()
    this.state = {
      startMeeting: false,
      meetingUrl: '',
      meetingPassword: '',
      meeting: null,
      invitedUsers: '',
      invitees: [],
    }
  }

  componentDidMount () {
    this.props.getInvitedMeeting().then((response) => {
      this.setState({
        invitees: response.data
      })
    })
  }

  handleAPI (JitsiMeetAPI) {
    JitsiMeetAPI.executeCommand('toggleVideo')
    JitsiMeetAPI.addEventListener('passwordRequired', () => {
      JitsiMeetAPI.executeCommand('password', this.state.meeting.password)
    })
  }

  createMeeting () {
    this.startMeeting()
    this.props.createMeeting({}).then((response) => {
      this.setState({
        meeting: response.data
      })
    })
  }

  joinMeeting (meeting) {
    this.startMeeting()
    this.setState({
      meeting: meeting
    })
  }

  inviteMeeting () {
    const invitedUsers = this.state.invitedUsers.split(',')
    const meeting = {
      invitees: invitedUsers
    }
    this.props.inviteMeeting(this.state.meeting.id, meeting).then(() => {
      this.setState({
        invitedUsers: ''
      })
    })
  }

  startMeeting () {
    this.setState({
      startMeeting: true
    })
  }

  changeInvitedUsers (e) {
    this.setState({
      invitedUsers: e.target.value
    })
  }

  renderAction () {
    const { t } = this.props
    const invitedItems = this.state.invitees.map((item, index) => (
      <div key={index} className="meeting-join-url" onClick={this.joinMeeting.bind(this, item)}>
        {item.ownerName} - {item.roomName}
      </div>
    ))
    return (
      <div>
        <div>
          <div className="heading-creation-box">
            {t('title.meetingTool')}
          </div>
          <div>
            <button className="submit-sub-button" onClick={this.createMeeting.bind(this)}>
              {t('CREATE MEETING')}
            </button>
          </div>
          <div>
            {this.state.invitees.length > 0 ? (
              <div>
                <div className="meeting-input-label">
                  {t('JOIN MEETING')}
                </div>
                {invitedItems}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  renderInvite () {
    if (this.props.currentUser.id !== this.state.meeting.owner) {
      return null
    }

    const { t } = this.props
    return (
      <div>
        <button className="submit-sub-button" onClick={this.inviteMeeting.bind(this)}>
          {t('INVITE MEETING')}
        </button>
        <span className="meeting-input-label">{t('label.meeting.email')}</span>
        <input className="meeting-input-url" type="text" value={this.state.invitedUsers}
               onChange={this.changeInvitedUsers.bind(this)}/>
      </div>
    )
  }

  renderMeeting () {
    if (!this.state.meeting) {
      return null
    }

    return (
      <div>
        {this.renderInvite()}
        <Jitsi
          domain={jitsi.domain}
          onAPILoad={this.handleAPI.bind(this)}
          roomName={this.state.meeting.roomName}
          displayName={this.props.currentUser.userName}
          interfaceConfig={jitsi.interfaceConfig}
          config={jitsi.config}
          password={this.state.meeting.password}
          containerStyle={{ width: '100%', height: window.innerHeight - 200 }}
        />
      </div>
    )
  }

  render () {
    return (
      <div>
        {this.state.startMeeting ? this.renderMeeting() : this.renderAction()}
      </div>
    )
  }
}

const mapStateToProps = ({ session, entities: { users } }, state) => ({
  currentUser: users[session.id],
})

const mapDispatchToProps = dispatch => ({
  createMeeting: (meeting) => dispatch(createMeeting(meeting)),
  inviteMeeting: (id, meeting) => dispatch(inviteMeeting(id, meeting)),
  getInvitedMeeting: () => dispatch(getInvitedMeeting()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MeetingTool)))

import React from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTranslation } from 'react-i18next'

class CourseInfo extends React.Component {
  constructor (props) {
    super(props)

    this.renderDescription = this.renderDescription.bind(this)
  }

  renderDescription () {
    let description = this.props.course.description
    if (!description) {
      return null
    }

    let button = document.getElementsByClassName('course-see-more')[0]
    if (button) {
      button.style.display = description.length < 500 ? 'none' : 'block'
    }
    let descriptionBox = document.getElementsByClassName('description-content')[0]
    if (descriptionBox) {
      descriptionBox.classList.remove('see-more')
    }
    return (
      <div className="description-content" dangerouslySetInnerHTML={{ __html: description }}>
      </div>
    )
  }

  seeMore () {
    let descriptionBox = document.getElementsByClassName('description-content')[0]
    descriptionBox.classList.add('see-more')
    let button = document.getElementsByClassName('course-see-more')[0]
    button.style.display = 'none'
  }

  render () {
    const { t } = this.props
    if (this.props.course) {
      return (
        <div>
          <div className="course-feed-text-box">
            <div className="learn-box">
              <div className="learn-title">{t('label.whatYouLearn')}</div>
              <div>{this.props.course.learningGoal &&
              this.props.course.learningGoal.split('\n')
                .map((li, i) => <div className="learn-text" key={i}>< FontAwesomeIcon icon={faCheck}
                                                                                      style={{ color: '#9c9a9a' }}/> {li}
                </div>)}
              </div>
            </div>
            <div id="description">{this.renderDescription()}</div>
          </div>
          <div className="course-see-more" onClick={this.seeMore}>+ {t('label.seeMore')}</div>
        </div>
      )
    } else {
      return null
    }

  }
}

export default withTranslation()(CourseInfo)

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import '../laboratory.css'
import { fetchContainer, releaseContainer } from '../../../../services/ContainerService'

class ContainerDetail extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    const containerId = this.props.match.params.containerId
    this.props.fetchContainer(containerId)
  }

  back (e) {
    this.props.history.push('/admin/containers/management')
  }

  release (e) {
    const containerId = this.props.match.params.containerId
    this.props.fetchContainer(containerId)
    this.props.history.push('/admin/containers/management')
  }

  render () {
    const { t } = this.props
    const containerId = this.props.match.params.containerId
    const containers = this.props.containers.length > 0 ? this.props.containers[0]?.data.filter(function (item) {return item.id === containerId}) : []
    const container = containers.length > 0 ? containers[0] : {}

    return (
      <div>
        <div className="heading-creation-box">
          {t('management.container.title.detail')}
        </div>
        <div>
          <button onClick={this.back.bind(this)} className="submit-button">
            {t('BACK')}
          </button>
          <button onClick={this.release.bind(this)} className="submit-button">
            {t('RELEASE')}
          </button>
        </div>
        <div className="item-detail">
          <span className="item-detail-label">{t('management.container.name')}:</span>
          <span>{container.name}</span>
        </div>
        <div className="item-detail">
          <span className="item-detail-label">{t('management.container.image')}:</span>
          <span>{container.image}</span>
        </div>
        <div className="item-detail">
          <span className="item-detail-label">{t('management.container.machine')}:</span>
          <span>{container.machineName}</span>
        </div>
        <div className="item-detail">
          <span className="item-detail-label">{t('management.container.laboratory')}:</span>
          <span>{container.laboratoryName}</span>
        </div>
        <div className="item-detail">
          <span className="item-detail-label">{t('management.container.os')}:</span>
          <span>{container.os.distro}</span>
        </div>
        <div className="item-detail">
          <span className="item-detail-label">{t('management.container.service')}:</span>
          <span>{container.service.type}</span>
        </div>
        <div className="item-detail">
          <span className="item-detail-label">{t('management.container.client')}:</span>
          <span>{container.client.name}</span>
        </div>
        <div className="item-detail">
          <span className="item-detail-label">{t('management.container.connection')}:</span>
          <span>{container.connection.number} / {container.connection.limit}</span>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  containers: Object.values(state.containers),
})

const mapDispatchToProps = dispatch => ({
  fetchContainer: (containerId) => dispatch(fetchContainer(containerId)),
  releaseContainer: (containerId) => dispatch(releaseContainer(containerId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContainerDetail)))

import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { AuthRoute, ProtectedRoute } from '../../utils/RouteUtil'
import NavBarContainer from './NavBar'
import Footer from './Footer.jsx'
import SignInForm from '../session/SignInForm'
import SignupForm from '../session/SignupForm'
import Home from './Home'
import SearchList from '../course/SearchCourse'
import CourseDetail from '../course/CourseDetail'
import CourseCreation from '../institute/course/CourseCreation'
import CourseList from '../institute/course/CourseList'
import CourseCreationAdmin from '../admin/course/CourseCreationAdmin'
import CourseListAdmin from '../admin/course/CourseListAdmin'
import CategoryManagement from '../admin/category/CategoryManagement'
import TopicManagement from '../admin/topic/TopicManagement'
import InstructorStarter from '../institute/InstituteStarter'
import ReviewCourse from '../admin/course/ReviewCourse'
import CrawlingYoutube from '../admin/crawling/CrawlingYoutube'
import ClassCreation from '../institute/classes/ClassCreation'
import ClassDashboard from '../institute/classes/ClassDashboard'
import SearchClass from '../classes/SearchClass'
import ClassDetail from '../classes/ClassDetail'
import MyClass from '../learning/MyClass'
import MyCourse from '../learning/MyCourse'
import ReviewClass from '../admin/classes/ReviewClass'
import CrawlingJob from '../admin/crawling/CrawlingJob'
import * as APIUtil from '../../apis/SessionApi'
import OAuth2RedirectHandler from '../../components/session/OAuth2RedirectHandler'
import Introduction from '../institute/Introduction'
import InstituteDashboard from '../institute/InstituteDashboard'
import ClassRoom from '../learning/ClassRoom'
import ChatTool from '../tool/ChatTool'
import MeetingTool from '../tool/MeetingTool'
import MeetingRoom from '../learning/MeetingRoom'
import ReviewRegister from '../institute/register/ReviewRegister'
import RemoteDesktop from '../tool/RemoteDesktop'
import Feedback from '../tool/Feedback'
import ReviewFeedback from '../admin/feedback/ReviewFeedback'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import InstructorManagement from '../institute/instructor/InstructorManagement'
import ClassManagement from '../institute/classes/ClassManagement'
import InstructorDashboard from '../institute/instructor/InstructorDashboard'
import LaboratoryCreation from '../laboratory/LaboratoryCreation'
import LaboratoryPreview from '../laboratory/LaboratoryPreview'
import MachineManagement from '../admin/laboratory/machine/MachineManagement'
import MachineCreation from '../admin/laboratory/machine/MachineCreation'
import LaboratoryList from '../laboratory/LaboratoryList'
import LaboratoryManagement from '../admin/laboratory/LaboratoryManagement'
import ImageCreation from '../admin/laboratory/image/ImageCreation'
import ImageManagement from '../admin/laboratory/image/ImageManagement'
import CommandUtil from '../admin/laboratory/util/CommandUtil'
import ClientCreation from '../admin/laboratory/client/ClientCreation'
import ClientManagement from '../admin/laboratory/client/ClientManagement'
import ConnectionManagement from '../admin/laboratory/connection/ConnectionManagement'
import ConnectionDetail from '../admin/laboratory/connection/ConnectionDetail'
import ContainerManagement from '../admin/laboratory/container/ContainerManagement'
import ContainerDetail from '../admin/laboratory/container/ContainerDetail'
import CodeEditorTool from '../tool/CodeEditorTool'
import CodeDiffEditorTool from '../tool/CodeDiffEditorTool'
import DocumentViewer from '../../components/document/DocumentViewer'

class App extends React.Component {
  constructor (props) {
    super(props)

  }

  componentDidMount () {
    APIUtil.init()
  }

  render () {
    return (
      <div className="app-container">
        <ReactNotification/>
        <NavBarContainer/>
        <Switch>
          <Route exact path="/" component={Home}/>
          <AuthRoute path="/login" component={SignInForm}/>
          <AuthRoute path="/signup" component={SignupForm}/>
          <Route path="/oauth2/redirect" component={OAuth2RedirectHandler}/>

          <Route exact path="/courses/search/" component={SearchList}/>
          <Route exact path="/courses/" component={Home}/>
          <Route exact path="/courses/search/:searchString" component={SearchList}/>
          <Route exact path="/courses/category/:searchString" component={SearchList}/>
          <Route exact path="/courses/topic/:searchString" component={SearchList}/>
          <Route exact path="/courses/:courseId" component={CourseDetail}/>

          <Route exact path="/classes/search" component={SearchClass}/>
          <Route exact path="/classes/category/:searchString" component={SearchClass}/>
          <Route exact path="/classes/topic/:searchString" component={SearchClass}/>
          <Route exact path="/class/:classUrl" component={ClassDetail}/>

          <ProtectedRoute exact path="/learning/course" component={MyCourse}/>
          <ProtectedRoute exact path="/learning/class" component={MyClass}/>
          <ProtectedRoute exact path="/learning/class/:classUrl" component={ClassRoom}/>
          <ProtectedRoute exact path="/learning/class/meeting/:classUrl" component={MeetingRoom}/>

          <ProtectedRoute exact path="/document/viewer/:documentId" component={DocumentViewer}/>

          <ProtectedRoute exact path="/institute/course/create" component={CourseCreation}/>
          <ProtectedRoute exact path="/institute/course/update/:courseId" component={CourseCreation}/>
          <ProtectedRoute exact path="/institute/course/list" component={CourseList}/>
          <Route exact path="/institute/starter" component={InstructorStarter}/>

          <ProtectedRoute exact path="/institute/introduction" component={Introduction}/>
          <ProtectedRoute exact path="/institute/introduction/page" component={Introduction}/>

          <Route exact path="/institute/dashboard/:instituteUrl" component={InstituteDashboard}/>

          <ProtectedRoute exact path="/institute/classes/create" component={ClassCreation}/>
          <ProtectedRoute exact path="/institute/classes/update/:classId" component={ClassCreation}/>
          <Route exact path="/institute/classes/dashboard" component={ClassDashboard}/>
          <ProtectedRoute exact path="/institute/classes/management" component={ClassManagement}/>

          <ProtectedRoute exact path="/institute/register/review" component={ReviewRegister}/>

          <ProtectedRoute exact path="/institute/instructor/management" component={InstructorManagement}/>
          <ProtectedRoute exact path="/institute/instructor/classes" component={InstructorDashboard}/>

          <ProtectedRoute exact path="/admin/course/create" component={CourseCreationAdmin}/>
          <ProtectedRoute exact path="/admin/course/update/:courseId" component={CourseCreationAdmin}/>
          <ProtectedRoute exact path="/admin/course/list" component={CourseListAdmin}/>
          <ProtectedRoute exact path="/admin/course/review" component={ReviewCourse}/>
          <ProtectedRoute exact path="/admin/category" component={CategoryManagement}/>
          <ProtectedRoute exact path="/admin/topic" component={TopicManagement}/>
          <ProtectedRoute exact path="/admin/laboratory/list" component={LaboratoryManagement}/>
          <ProtectedRoute exact path="/admin/machines" component={MachineCreation}/>
          <ProtectedRoute exact path="/admin/machines/update/:machineId" component={MachineCreation}/>
          <ProtectedRoute exact path="/admin/machines/management" component={MachineManagement}/>
          <ProtectedRoute exact path="/admin/images" component={ImageCreation}/>
          <ProtectedRoute exact path="/admin/images/update/:imageId" component={ImageCreation}/>
          <ProtectedRoute exact path="/admin/images/management" component={ImageManagement}/>
          <ProtectedRoute exact path="/admin/clients" component={ClientCreation}/>
          <ProtectedRoute exact path="/admin/clients/update/:clientId" component={ClientCreation}/>
          <ProtectedRoute exact path="/admin/clients/management" component={ClientManagement}/>
          <ProtectedRoute exact path="/admin/connections/management" component={ConnectionManagement}/>
          <ProtectedRoute exact path="/admin/connections/:connectionId" component={ConnectionDetail}/>
          <ProtectedRoute exact path="/admin/containers/management" component={ContainerManagement}/>
          <ProtectedRoute exact path="/admin/containers/:containerId" component={ContainerDetail}/>
          <ProtectedRoute exact path="/admin/commands/util" component={CommandUtil}/>

          <ProtectedRoute exact path="/admin/crawling/youtube" component={CrawlingYoutube}/>
          <ProtectedRoute exact path="/admin/crawling/job" component={CrawlingJob}/>

          <Route exact path="/admin/classes/review" component={ReviewClass}/>
          <Route exact path="/admin/feedback/review" component={ReviewFeedback}/>

          <ProtectedRoute exact path="/tool/chat" component={ChatTool}/>
          <ProtectedRoute exact path="/tool/meeting" component={MeetingTool}/>
          <ProtectedRoute exact path="/tool/remote-desktop" component={RemoteDesktop}/>
          <ProtectedRoute exact path="/tool/code-editor" component={CodeEditorTool}/>
          <ProtectedRoute exact path="/tool/code-diff-editor" component={CodeDiffEditorTool}/>
          <ProtectedRoute exact path="/tool/feedback" component={Feedback}/>

          <Route exact path="/instructor/laboratories" component={LaboratoryCreation}/>
          <Route exact path="/instructor/laboratories/update/:laboratoryId" component={LaboratoryCreation}/>
          <Route exact path="/instructor/laboratories/management" component={LaboratoryList}/>
          <Route exact path="/instructor/laboratories/preview/:laboratoryId" component={LaboratoryPreview}/>
        </Switch>
        <Footer/>
      </div>
    )
  }
}

export default App

import { RECEIVE_MY_CLASSES, RECEIVE_MY_COURSES } from '../services/LearningService'

export const MyClassReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_MY_CLASSES:
      nextState.classes = action.classes
      return nextState
    default:
      return state
  }
}

export const MyCourseReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_MY_COURSES:
      nextState.courses = action.courses
      return nextState
    default:
      return state
  }
}

import { RECEIVE_MACHINE, REMOVE_MACHINE } from '../services/MachineService'

export const MachineReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_MACHINE:
      nextState[action.data.id] = action.data
      return nextState
    case REMOVE_MACHINE:
      delete nextState[action.machineId]
      return nextState
    default:
      return state
  }
}

export default MachineReducer

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { addNotification } from '../../../../utils/CommonUtils'
import { createImage, editImage, fetchImage } from '../../../../services/ImageService'
import '../laboratory.css'
import { DOC_TYPE_DOCUMENT } from '../../../../utils/Contants'
import MultipleFileUpload from '../../../../components/file/MultipleFileUpload'

class ImageCreation extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      script: '',
      os: {},
      service: {},
      language: {},
      client: {},
      mainPort: null,
      availablePorts: '',
      params: '',
      status: '',
      dockerfiles: [],
      option: {},
      type: '1',
      edit: false,
    }
  }

  componentDidMount () {
    this.updateState()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.match.params.imageId !== prevProps.match.params.imageId) {
      this.updateState()
    }
  }

  updateState () {
    const imageId = this.props.match.params.imageId
    if (imageId) {
      this.setState({
        edit: true
      })
      this.props.fetchImage(imageId).then(response => {
        this.setState({
          name: response.data.name,
          script: response.data.script,
          description: response.data.description,
          os: response.data.os,
          service: response.data.service,
          language: response.data.language,
          client: response.data.client,
          mainPort: response.data.mainPort,
          availablePorts: response.data.availablePorts ? response.data.availablePorts.join(',') : '',
          params: response.data.params,
          dockerfiles: response.data.dockerfiles,
          option: response.data.option,
          status: response.data.status,
        })
      })
    } else {
      this.setState({
        name: '',
        script: '',
        os: {},
        service: {},
        language: {},
        client: {},
        mainPort: null,
        availablePorts: '',
        params: '',
        dockerfiles: [],
        option: {},
        type: '1',
        status: '',
        edit: false,
      })
    }
  }

  changeName (e) {
    this.setState({
      name: e.target.value
    })
  }

  changeDescription (e) {
    this.setState({
      description: e.target.value
    })
  }

  changeScript (e) {
    this.setState({
      script: e.target.value
    })
  }

  changeService (e) {
    const service = {
      type: e.target.value,
    }
    this.setState({
      service: service,
      language: {},
      os: {},
      client: {}
    })
  }
  changeLanguage (e) {
    const language = {
      name: e.target.value,
    }
    this.setState({
      service: {},
      language: language,
      os: {},
      client: {}
    })
  }

  changeOS (e) {
    const os = {
      type: 'LINUX',
      distro: e.target.value,
    }
    this.setState({
      service: {},
      language: {},
      os: os,
      client: {}
    })
  }

  changeClient (e) {
    const client = {
      name: e.target.value
    }
    this.setState({
      service: {},
      language: {},
      os: {},
      client: client
    })
  }

  changeMainPort (e) {
    this.setState({
      mainPort: e.target.value
    })
  }

  changeAvailablePorts (e) {
    this.setState({
      availablePorts: e.target.value
    })
  }

  changeParams (e) {
    this.setState({
      params: e.target.value
    })
  }

  updateDockerFiles (fileIds) {
    this.setState({
      dockerfiles: fileIds
    })
  }

  changeSelection (e) {
    this.setState({
      type: e.currentTarget.value
    })
  }
  changeLimit (e) {
    let option = this.state.option
    option.connectionLimit = e.target.value
    this.setState({
      option: option
    })
  }
  changeSingleConnection(e) {
    let option = this.state.option
    option.singleConnection = e.target.checked
    this.setState({
      option: option
    })
  }

  submitHandle (status) {
    const { t } = this.props
    const data = {
      name: this.state.name,
      description: this.state.description,
      script: this.state.script,
      os: this.state.os,
      service: this.state.service,
      language: this.state.language,
      client: this.state.client,
      mainPort: this.state.mainPort,
      availablePorts: this.state.availablePorts ? this.state.availablePorts.split(',') : [],
      dockerfiles: this.state.dockerfiles,
      option: this.state.option,
      params: this.state.params,
    }
    const imageId = this.props.match.params.imageId
    if (imageId) {
      this.props.editImage(imageId, data)
    } else {
      this.props.createImage(data)
    }
    addNotification(t('notification.class.create'))
    this.props.history.push('/admin/images/management')
  }

  renderButton () {
    const { t } = this.props
    if (this.state.edit) {
      return (
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this, '')} className="submit-button">
            {t('SAVE')}
          </button>
          {this.state.status === 'DRAFT' ? (
            <button onClick={this.submitHandle.bind(this, 'SUBMITTED')} className="submit-button">
              {t('SUBMIT')}
            </button>
          ) : null
          }
        </div>
      )
    } else {
      return (
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this, 'DRAFT')} className="submit-button">
            {t('SAVE DRAFT')}
          </button>
          <button onClick={this.submitHandle.bind(this, 'SUBMITTED')} className="submit-button">
            {t('SUBMIT')}
          </button>
        </div>
      )
    }
  }

  render () {
    const { t } = this.props
    return (
      <div>
        <div className="heading-creation-box">
          {t('management.image.title.create')}
        </div>
        <div className="course-creation-item">
          <span>{t('management.image.name')}:</span>
          <input className="course-creation-input-text" type="text" value={this.state.name}
                 onChange={this.changeName.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.image.description')}:</span>
          <textarea className="learning-goals-textarea" value={this.state.description || ''}
                    onChange={this.changeDescription.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <div className="input-box">
            <div className="image-creation-input-div">
              <span className="image-creation-input-label">{t('management.image.os')}:</span>
              <input className="course-creation-input-text" type="text" value={this.state.os?.distro}
                     onChange={this.changeOS.bind(this)}/>
            </div>
            <div className="image-creation-input-div">
              <span className="image-creation-input-label">{t('management.image.service')}:</span>
              <input className="course-creation-input-text" type="text" value={this.state.service?.type}
                     onChange={this.changeService.bind(this)}/>
            </div>
            <div className="image-creation-input-div">
              <span className="image-creation-input-label">{t('management.image.language')}:</span>
              <input className="course-creation-input-text" type="text" value={this.state.language?.name}
                     onChange={this.changeLanguage.bind(this)}/>
            </div>
            <div className="image-creation-input-div">
              <span className="image-creation-input-label">{t('management.image.client')}:</span>
              <input className="course-creation-input-text" type="text" value={this.state.client?.name}
                     onChange={this.changeClient.bind(this)}/>
            </div>
          </div>
        </div>
        <div className="course-creation-item">
          <div className="input-box">
            <div className="image-creation-input-div">
              <span className="image-creation-input-label">{t('management.image.mainPort')}:</span>
              <input className="course-creation-input-text" type="text" value={this.state.mainPort}
                     onChange={this.changeMainPort.bind(this)}/>
            </div>
            <div className="image-creation-input-div">
              <span className="image-creation-input-label">{t('management.image.availablePorts')}:</span>
              <input className="course-creation-input-text" type="text" value={this.state.availablePorts}
                     onChange={this.changeAvailablePorts.bind(this)}/>
            </div>
          </div>
        </div>
        <div className="course-creation-item">
          <span>{t('management.image.params')}:</span>
          <input className="course-creation-input-text" type="text" value={this.state.params}
                 onChange={this.changeParams.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <div className="input-box">
            <div className="image-creation-input-div">
              <span>{t('management.image.limit')}:</span>
              <input className="course-creation-input-text" type="text" value={this.state.option?.connectionLimit}
                     onChange={this.changeLimit.bind(this)}/>
            </div>
            <div className="image-creation-input-div">
              <span>{t('management.image.singleConnection')}:</span>
              <input type="checkbox" className="class-calendar-day-item"
                     checked={this.state.option?.singleConnection}
                     onChange={this.changeSingleConnection.bind(this)}/> {t('Single Connection')}
            </div>
          </div>
        </div>
        <div className="course-creation-item">
          <span>{t('management.image.dockerfile')}:</span>
          <div className="upload-option-box">
            <input className="upload-option-item" type="radio" value="0" name="option"
                   checked={this.state.type === '0'} onChange={this.changeSelection.bind(this)}/>File
            <input className="upload-option-item" type="radio" value="1" name="option"
                   checked={this.state.type === '1'} onChange={this.changeSelection.bind(this)}/>Directory
          </div>
          <MultipleFileUpload docType={DOC_TYPE_DOCUMENT} directory={this.state.type === '1'}
                              onUpdateMultipleFile={this.updateDockerFiles.bind(this)}/>
        </div>
        {this.renderButton()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  images: Object.values(state.images),
})

const mapDispatchToProps = dispatch => ({
  createImage: (data) => dispatch(createImage(data)),
  editImage: (imageId, data) => dispatch(editImage(imageId, data)),
  fetchImage: (imageId) => dispatch(fetchImage(imageId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ImageCreation)))

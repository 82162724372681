import { RECEIVE_FILE } from '../services/FileService'

const FileReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_FILE:
      nextState[action.file.id] = action.file
      return nextState
    default:
      return state
  }
}

export default FileReducer

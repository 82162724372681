import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import '../../institute/institute.css'
import { withTranslation } from 'react-i18next'
import PagingNumber from '../../../components/table/PagingNumber'
import ReactReviewTable from '../../../components/table/ReactReviewTable'
import { fetchAllFeedbacks } from '../../../services/FeedbackService'

class ReviewFeedback extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      order: 0,
      size: 15,
      status: 'SUBMITTED',
      filter: ''
    }
    this.search = this.search.bind(this)
  }

  componentDidMount () {
    this.search()
  }

  search () {
    const request = {
      value: this.state.filter,
      query: {
        status: this.state.status === '' ? null : this.state.status
      },
      page: this.state.currentPage - 1,
      size: this.state.size
    }
    this.props.fetchAllFeedbacks(request)
  }

  handleClickPage (page) {
    this.setState({
      currentPage: page
    }, () => {
      this.search()
    })
  }

  changeStatus (e) {
    this.setState({
      status: e.target.value
    }, () => this.search())
  }

  render () {
    const { t } = this.props
    const header = (
      <div className="heading-creation-box">
        {t('management.feedback.title.review')}
      </div>
    )
    if (this.props.feedbacks.length === 0) {
      return header
    }

    this.props.feedbacks[0].data.map((item, i) => {
      item['status'] = t(`${item['status']}`)
    })
    return (
      <div>
        {header}
        <div className="input-box">
          <div className="course-creation-input-label">{t('label.status')}:</div>
          <select className="course-creation-input-select" value={this.state.status}
                  onChange={this.changeStatus.bind(this)}>
            <option value="">{t('ALL')}</option>
            <option value="SUBMITTED">{t('SUBMIT')}</option>
            <option value="PROCESSING">{t('PROCESSING')}</option>
            <option value="PROCESSED">{t('PROCESSED')}</option>
          </select>
        </div>
        <div>
          <ReactReviewTable
            data={this.props.feedbacks[0].data}
            columns={[
              {
                Header: t('management.feedback.column.name'),
                accessor: 'userName',
                minWidth: 50,
              },
              {
                Header: t('management.feedback.column.subject'),
                accessor: 'subject',
                minWidth: 50
              },
              {
                Header: t('management.feedback.column.content'),
                accessor: 'content',
                minWidth: 300
              }
            ]}
          />
        </div>
        <PagingNumber currentPage={this.state.currentPage} totalPage={this.props.feedbacks[0].totalPage}
                      onChangePage={this.handleClickPage.bind(this)}/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  feedbacks: Object.values(state.feedbacks),
})

const mapDispatchToProps = dispatch => ({
  fetchAllFeedbacks: (query) => dispatch(fetchAllFeedbacks(query)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReviewFeedback)))

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import './tool.css'
import { createFeedback } from '../../services/FeedbackService'

class Feedback extends React.Component {
  constructor () {
    super()
    this.state = {
      subject: '',
      content: '',
      submitted: false,
    }
  }

  changeSubject (e) {
    this.setState({
      subject: e.target.value
    })
  }

  changeContent (e) {
    this.setState({
      content: e.target.value
    })
  }

  submitHandle () {
    const data = {
      subject: this.state.subject,
      content: this.state.content,
    }
    this.props.createFeedback(data).then(() => {
      this.setState({
        submitted: true
      })
    })
  }

  render () {
    const { t } = this.props
    const header = (
      <div className="heading-creation-box">
        {t('management.feedback.title.feedback')}
      </div>
    )
    if (this.state.submitted) {
      return (
        <div>
          {header}
          <div>
            {t('management.feedback.thanks')}
          </div>
        </div>
      )
    }

    return (
      <div>
        {header}
        <div className="course-creation-item">
          <span>{t('management.feedback.subject')}:</span>
          <input className="course-creation-input-text" type="text" value={this.state.subject}
                 onChange={this.changeSubject.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.feedback.content')}:</span>
          <textarea className="learning-goals-textarea" value={this.state.content || ''}
                    onChange={this.changeContent.bind(this)}/>
        </div>
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this)} className="submit-button">
            {t('SUBMIT')}
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  createFeedback: (data) => dispatch(createFeedback(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Feedback)))

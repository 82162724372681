import React from 'react'
import ReactTable from './ReactTable'
import ReactTooltip from 'react-tooltip'
import { withTranslation } from 'react-i18next'
import './table.css'
import { TABLE_DATA_TYPE } from '../../utils/Contants'

class ClassManagementTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      rowData: {},
    }
  }

  saveItem (id) {
    const data = {
      instructor: this.state.rowData[id]
    }
    this.props.onSaveItem(id, data)
    this.setState({
      rowData: {},
    })
  }

  changeSelection (column, e) {
    let rowData = this.state.rowData
    rowData[column.value] = e.target.value
    this.setState({
      rowData: rowData
    })
  }

  deleteItem (id) {
    this.props.onDeleteItem(id)
  }

  editItem (id) {
    this.props.onEditItem(id)
  }

  renderActionCell (cellInfo) {
    const { t } = this.props

    let selectValues = this.props.selections.map((item, i) => (
      <option key={i} value={item.value}>{item.label}</option>
    ))
    return (
      <div className="button-panel">
        <div>
          <select className="course-creation-input-select"
                  onChange={this.changeSelection.bind(this, cellInfo)} value={this.state.rowData[cellInfo.value]}>
            <option value="">{t('selection.default')}</option>
            {selectValues}
          </select>
          <a className="list-action-button" onClick={() => { this.saveItem(cellInfo.row.original.id) }}>{t('ADD')}</a>
        </div>
        <a className="list-action-button" onClick={() => { this.editItem(cellInfo.row.original.id) }}>{t('EDIT')}</a>
        <a className="list-action-button"
           onClick={() => { this.deleteItem(cellInfo.row.original.id) }}>{t('DELETE')}</a>
      </div>
    )
  }

  renderTooltipCell (cellInfo) {
    const { t } = this.props

    return (
      <div>
        <a className="tooltip-item" data-tip={cellInfo.row.original.members}
           data-event="click focus">{cellInfo.value} {t('members')}</a>
        <ReactTooltip globalEventOff="click"/>
      </div>
    )
  }

  render () {
    const { t } = this.props
    let columns = this.props.columns
    columns.map((column, index) => {
      if (column.type === TABLE_DATA_TYPE.TEXT_TOOLTIP) {
        column['Cell'] = this.renderTooltipCell.bind(this)
      }
    })
    const actionColumn = {
      Header: t('Action'),
      id: 'button',
      accessor: 'id',
      minWidth: 50,
      Cell: (cellInfo) => (this.renderActionCell(cellInfo))
    }
    columns.push(actionColumn)
    return (
      <ReactTable
        data={this.props.data}
        columns={columns}
      />
    )
  }
}

export default withTranslation()(ClassManagementTable)

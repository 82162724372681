import * as APIUtil from '../apis/CourseApi'

export const RECEIVE_BEST_COURSES = 'RECEIVE_BEST_COURSES'
export const RECEIVE_COURSES = 'RECEIVE_COURSES'
export const RECEIVE_SIMILAR_COURSES = 'RECEIVE_SIMILAR_COURSES'
export const RECEIVE_COURSE = 'RECEIVE_COURSE'
export const RECEIVE_SUGGESTION_COURSES = 'RECEIVE_SUGGESTION_COURSES'
export const REMOVE_COURSE = 'REMOVE_COURSE'
export const RECEIVE_MORE_COURSES = 'RECEIVE_MORE_COURSES'

const receiveBestCourses = courses => ({
  type: RECEIVE_BEST_COURSES,
  courses
})

const receiveMoreCourses = courses => ({
  type: RECEIVE_MORE_COURSES,
  courses
})

const receiveCourses = (courses) => ({
  type: RECEIVE_COURSES,
  courses
})

const receiveSimilarCourses = similar => ({
  type: RECEIVE_SIMILAR_COURSES,
  similar
})

const receiveCourse = course => ({
  type: RECEIVE_COURSE,
  course
})

const receiveSuggestionCourses = suggestions => ({
  type: RECEIVE_SUGGESTION_COURSES,
  suggestions
})

const removeCourse = courseId => ({
  type: REMOVE_COURSE,
  courseId
})

export const fetchBestCourses = (page, size) => dispatch => (
  APIUtil.fetchBestCourses(page, size)
    .then(courses => dispatch(receiveBestCourses(courses.data)))
)

export const fetchMoreBestCourses = (page, size) => dispatch => (
  APIUtil.fetchBestCourses(page, size)
    .then(courses => dispatch(receiveMoreCourses(courses.data)))
)

export const filterCourses = (query) => dispatch => (
  APIUtil.filterCourses(query)
    .then(courses => dispatch(receiveCourses(courses.data)))
)

export const fetchCourses = (query, field, sort, order, page, size) => dispatch => (
  APIUtil.fetchCourses(query, field, sort, order, page, size)
    .then(courses => dispatch(receiveCourses(courses.data)))
)

export const fetchOwnerCourses = (query, field, sort, order, page, size) => dispatch => (
  APIUtil.fetchOwnerCourses(query, field, sort, order, page, size)
    .then(courses => dispatch(receiveCourses(courses.data)))
)

export const fetchCourse = courseId => dispatch => (
  APIUtil.fetchCourse(courseId)
    .then(course => dispatch(receiveCourse(course.data)))
)

export const fetchCourseByUrl = url => dispatch => (
  APIUtil.fetchCourseByUrl(url)
    .then(course => dispatch(receiveCourse(course.data)))
)

export const fetchSimilarCourses = (courseId) => dispatch => (
  APIUtil.fetchSimilarCourses(courseId)
    .then(similar => dispatch(receiveSimilarCourses(similar.data)))
)

export const fetchSuggestionCourses = () => dispatch => (
  APIUtil.fetchSuggestionCourses()
    .then(suggestions => dispatch(receiveSuggestionCourses(suggestions.data)))
)

export const createCourse = (course) => dispatch => (
  APIUtil.createCourse(course)
    .then(response => dispatch(receiveCourse(response.data)))
)

export const updateCourse = (courseId, course) => dispatch => (
  APIUtil.updateCourse(courseId, course)
    .then(response => dispatch(receiveCourse(response.data)))
)

export const deleteCourse = (courseId) => dispatch => (
  APIUtil.deleteCourse(courseId)
    .then(course => dispatch(removeCourse(courseId)))
)

export const viewCourse = courseId => dispatch => (
  APIUtil.viewCourse(courseId)
    .then(course => dispatch(receiveCourse(course.data)))
)

export const fetchReviewCourses = (query) => dispatch => (
  APIUtil.fetchReviewCourses(query)
    .then(courses => dispatch(receiveCourses(courses.data)))
)

export const reviewCourse = (courseId, data) => dispatch => (
  APIUtil.reviewCourse(courseId, data)
    .then(course => dispatch(receiveCourse(course.data)))
)

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import './document.css'
import moment from 'moment'
import { downloadFile } from '../../services/FileService'

class DocumentItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      document: null
    }
  }

  viewDocument () {
    const document = this.props.document
    this.props.history.push(`/document/viewer/${document.fileId}?fileType=${document.fileType}`)
  }

  render () {
    return (
      <div className="document-item-container">
        <div className="comment-content-detail">{this.props.document.description}</div>
        <div className="document-item-file" onClick={this.viewDocument.bind(this)}>
          {this.props.document.name}
        </div>
        <div className="comment-user-name">
          {this.props.document.userName}
          <div className="comment-item-time">
            {moment(this.props.document.createdDate).startOf('min').fromNow()}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => ({
  downloadFile: (id) => dispatch(downloadFile(id))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentItem)))

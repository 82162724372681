import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Jitsi from 'react-jitsi'
import { jitsi } from '../../utils/Settings'
import { getClassMeeting } from '../../services/MeetingService'

class MeetingRoom extends React.Component {
  constructor () {
    super()
    this.state = {
      meeting: null
    }
  }

  componentDidMount () {
    this.props.getClassMeeting(this.props.match.params.classUrl).then((response) => {
      this.setState({
        meeting: response.data
      })
    })
  }

  handleAPI (JitsiMeetAPI) {
    JitsiMeetAPI.executeCommand('toggleVideo')
    JitsiMeetAPI.addEventListener('passwordRequired', () => {
      JitsiMeetAPI.executeCommand('password', this.state.meeting.password)
    })
  }

  render () {
    if (!this.state.meeting) {
      return null
    }

    return (
      <div>
        <Jitsi
          domain={jitsi.domain}
          onAPILoad={this.handleAPI.bind(this)}
          roomName={this.state.meeting.roomName}
          displayName={this.props.currentUser.userName}
          interfaceConfig={jitsi.interfaceConfig}
          config={jitsi.config}
          password={this.state.meeting.password}
          containerStyle={{ width: '100%', height: window.innerHeight - 200 }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ session, entities: { users } }, state) => ({
  currentUser: users[session.id],
})

const mapDispatchToProps = dispatch => ({
  getClassMeeting: (classUrl) => dispatch(getClassMeeting(classUrl)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MeetingRoom)))

import * as API from './API'

export const fetchAllCategories = () => (
  API.get('categories')
)

export const fetchTopCategories = () => (
  API.get('categories/top')
)

export const fetchCategory = categoryId => (
  API.get(`categories/${categoryId}`)
)

export const createCategory = (category) => (
  API.post(`categories`, category)
)

export const editCategory = (categoryId, category) => (
  API.put(`categories/${categoryId}`, category)
)

export const deleteCategory = (categoryId) => (
  API.remove(`categories/${categoryId}`)
)

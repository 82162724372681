import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { fetchOwnerClasses } from '../../../services/ClassService'
import './classes.css'
import MyClassItem from '../../../components/classes/MyClassItem'
import PagingDataTable from '../../../components/table/PagingDataTable'

class ClassDashboard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      query: {
        page: 0,
        size: 20,
      }
    }
  }

  componentDidMount () {
    this.props.fetchOwnerClasses()
  }

  handleClickPage (page) {
    this.setState({
      currentPage: page
    }, () => {
      this.props.fetchOwnerClasses()
    })
  }

  render () {
    const { t } = this.props
    const items = this.props.classes.map((item, index) =>
      <div key={index} className="course-tile">
        <MyClassItem class={item}/>
      </div>
    )
    return (
      <div>
        <div className="heading-creation-box">
          {t('management.classes.title.dashboard')}
        </div>
        <div>
          <div className="my-classes-box">
            <div>{t('management.classes.title.listClass')}</div>
            <PagingDataTable items={items} currentPage={this.state.currentPage}
                             totalPage={this.props.classes[0]?.totalPage}
                             onChangePage={this.handleClickPage.bind(this)}/>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  classes: Object.values(state.classes),
})

const mapDispatchToProps = dispatch => ({
  fetchOwnerClasses: () => dispatch(fetchOwnerClasses()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClassDashboard)))

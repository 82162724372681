import React from 'react'
import { withTranslation } from 'react-i18next'
import MultipleLineTable from '../table/MultipleLineTable'
import ClassItem from '../classes/ClassItem'
import { fetchInstructorClasses, fetchMoreInstructorClasses } from '../../services/ClassService'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

class InstituteInfo extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      page: 0,
      size: 10,
      more: true
    }
  }

  componentDidMount () {
    const request = {
      institute: this.props.institute.userId,
      page: this.state.page,
      size: this.state.size
    }
    this.props.fetchInstructorClasses(request).then((response) => {
      const more = response.classes.length > 0
      this.setState({
        more: more,
        page: this.state.page + 1
      })
    })
  }

  seeMore () {
    const request = {
      institute: this.props.institute.ownerId,
      page: this.state.page,
      size: this.state.size
    }
    this.props.fetchMoreInstructorClasses(request).then((response) => {
      const more = response.classes.length > 0
      this.setState({
        more: more,
        page: this.state.page + 1
      })
    })
  }

  exploreAll () {
    this.props.history.push('/classes/search/')
  }

  render () {
    if (!this.props.institute) {
      return null
    }

    const { t } = this.props
    const items = this.props.classes.map((x, i) =>
      <div key={i} className="course-tile">
        <ClassItem key={i} class={x}/>
      </div>
    )
    return (
      <div>
        <div
          className="ck ck-editor__main ck-blurred ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline"
          dangerouslySetInnerHTML={{ __html: this.props.institute.introduction }}>
        </div>
        <div className="index">
          <div>
            <div>{t('label.classes')}</div>
          </div>
          <MultipleLineTable items={items} more={this.state.more} onSeeMore={this.seeMore.bind(this)}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  classes: Object.values(state.instructorClasses)
})

const mapDisPatchToProps = dispatch => ({
  fetchInstructorClasses: (query) => dispatch(fetchInstructorClasses(query)),
  fetchMoreInstructorClasses: (query) => dispatch(fetchMoreInstructorClasses(query)),
})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(InstituteInfo)))

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './index.css'
import { withTranslation } from 'react-i18next'
import ReactEditableTable from '../../../components/table/ReactEditableTable'
import {
  createInstructor,
  deleteInstructor,
  fetchAllInstructors,
  updateInstructor
} from '../../../services/InstructorService'
import { TABLE_DATA_TYPE } from '../../../utils/Contants'

class InstructorManagement extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
    }
  }

  componentDidMount () {
    this.props.fetchAllInstructors()
  }

  changeInstructor (e) {
    this.setState({
      email: e.target.value
    })
  }

  saveItem (instructorId, data) {
    this.props.updateInstructor(instructorId, data)
  }

  deleteItem (instructorId) {
    this.props.deleteInstructor(instructorId).then(() => {
      this.props.fetchAllInstructors()
    })
  }

  createInstructor () {
    let data = {
      email: this.state.email,
    }
    this.props.createInstructor(data).then(() => {
      this.setState({
        email: '',
      })
    })
  }

  render () {
    const { t } = this.props

    return (
      <div>
        <div className="heading-creation-box">
          {t('Instructor Management')}
        </div>
        <div>
          <div className="category-creation-box">
            <div className="category-creation-item">
              <span className="label-item">{t('label.email')}:</span>
              <input className="category-creation-input-text" type="text" value={this.state.email}
                     onChange={this.changeInstructor.bind(this)}/>
            </div>
          </div>
          <button onClick={this.createInstructor.bind(this)} className="create-button">
            {t('management.instructor.create')}
          </button>
          <ReactEditableTable
            onSaveItem={this.saveItem.bind(this)}
            onDeleteItem={this.deleteItem.bind(this)}
            data={this.props.instructors}
            columns={[
              {
                Header: t('management.instructor.column.email'),
                accessor: 'email',
                minWidth: 50,
                type: TABLE_DATA_TYPE.TEXT_TYPE
              },
              {
                Header: t('management.instructor.column.name'),
                accessor: 'userName',
                minWidth: 50,
                type: TABLE_DATA_TYPE.TEXT_TYPE
              }
            ]}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  instructors: Object.values(state.instructors),
})

const mapDispatchToProps = dispatch => ({
  fetchAllInstructors: () => dispatch(fetchAllInstructors()),
  createInstructor: (data) => dispatch(createInstructor(data)),
  updateInstructor: (instructorId, category) => dispatch(updateInstructor(instructorId, category)),
  deleteInstructor: (instructorId) => dispatch(deleteInstructor(instructorId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InstructorManagement)))

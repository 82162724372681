import React from 'react'
import { withRouter } from 'react-router-dom'
import { faPlayCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import VideoPlayer from 'react-video-js-player'
import { hidePlayer, showPlayer } from '../../services/VideoService'
import { connect } from 'react-redux'
import ExternalVideo from './ExternalVideo'
import * as CommonUtils from '../../utils/CommonUtils'
import { isMobile } from '../../utils/ResponsiveUtil'

class VideoDetail extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
  }

  onPlayerReady (player) {
    this.player = player
  }

  onVideoPlay (duration) {
  }

  onVideoEnd () {
  }

  render () {
    const videos = this.props.videos[this.props.player.contentId]
    const video = videos[this.props.player.index]
    if (video) {
      return (
        <div className="modal-back-video">
          <div id="video-container">
            <div className="video-title">
              <div>{video.title}</div>
              <div onClick={() =>
                this.props.hidePlayer(video.index, this.props.player.contentId)}
                   className="video-close">
                < FontAwesomeIcon icon={faTimes}/></div>
            </div>
            {video.external ?
              <ExternalVideo url={video.url}/>
              : <VideoPlayer
                controls={true}
                src={video.url}
                poster={video.poster}
                width={isMobile() ? window.innerWidth : 720}
                onReady={this.onPlayerReady.bind(this)}
                onPlay={this.onVideoPlay.bind(this)}
                onEnd={this.onVideoEnd.bind(this)}
              />}
            <div className="video-list-container">
              {videos.map((v, i) => (
                <div key={i} className="video-list-box">
                  <div className={v.index === this.props.player.index ? 'video-list-item-selected' : 'video-list-item'}
                       onClick={() => this.props.showPlayer(v.index, this.props.player.contentId)}>
                    <div>
                      <FontAwesomeIcon icon={faPlayCircle}/>
                      <span style={{ marginLeft: '11px' }}>{v.title}</span>
                    </div>
                    <div>{CommonUtils.getDuration(v.duration)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = state => ({
  videos: state.videos,
  video: state.video,
  player: state.player
})

const mapDisPatchToProps = dispatch => ({
  hidePlayer: (index, contentId) => dispatch(hidePlayer(index, contentId)),
  showPlayer: (index, contentId) => dispatch(showPlayer(index, contentId))
})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(VideoDetail))

import React from 'react'
import CategoryItem from '../../components/category/CategoryItem'
import { withRouter } from 'react-router-dom'
import { fetchTopCategories } from '../../services/CategoryService'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import SingleLineTable from '../../components/table/SingleLineTable'
import './category.css'
import { isMobile } from '../../utils/ResponsiveUtil'

class CategoryList extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    this.props.fetchTopCategories()
  }

  render () {
    const { t } = this.props
    const items = this.props.categories.map((x, i) =>
      <div key={i} className="course-tile">
        <CategoryItem key={i} category={x}/>
      </div>
    )
    return (
      <div className="index">
        <div className="index-header-container">
          <div className="index-header">{t('home.category.label')}</div>
        </div>
        {isMobile() ?
          <div className="category-box">
            {items}
          </div>
          : <SingleLineTable items={items}/>}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  categories: Object.values(state.categories)
})

const mapDisPatchToProps = dispatch => ({
  fetchTopCategories: () => dispatch(fetchTopCategories())
})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(CategoryList)))

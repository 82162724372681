import React from 'react'
import { withTranslation } from 'react-i18next'
import { fetchAllTopics } from '../../services/TopicService'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './Filter.css'
import { faAngleDown, faAngleUp, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const EN = 'en'
export const VN = 'vn'

class CourseFilter extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      topics: [],
      rating: null,
      languages: [],
      topicExpand: false,
      ratingExpand: false,
      languageExpand: false,
    }
    this.changeRating = this.changeRating.bind(this)
    this.changeLanguage = this.changeLanguage.bind(this)
  }

  componentDidMount () {
    this.props.fetchAllTopics()
  }

  changeTopic (selection) {
    let topics = this.state.topics
    if (topics.includes(selection)) {
      topics.splice(topics.indexOf(selection), 1)
    } else {
      topics.push(selection)
    }

    this.setState({
      topics: topics
    }, () => (this.filter()))
  }

  changeRating (e) {
    this.setState({
      rating: e.currentTarget.value
    }, () => (this.filter()))
  }

  changeLanguage (selection) {
    let languages = this.state.languages
    if (languages.includes(selection)) {
      languages.splice(languages.indexOf(selection), 1)
    } else {
      languages.push(selection)
    }

    this.setState({
      languages: languages
    }, () => (this.filter()))
  }

  filter () {
    const query = {
      queryIn: {
        topicId: this.state.topics,
        language: this.state.languages
      },
      queryGte: {
        rating: this.state.rating
      }
    }

    this.props.onFilter(query)
  }

  expendHandle (type) {
    switch (type) {
      case 'TOPIC':
        this.setState({
          topicExpand: !this.state.topicExpand
        })
        break
      case 'RATING':
        this.setState({
          ratingExpand: !this.state.ratingExpand
        })
        break
      case 'LANGUAGE':
        this.setState({
          languageExpand: !this.state.languageExpand
        })
        break
    }
  }

  render () {
    const { t } = this.props
    const topics = this.props.topics.map((topic, i) => (
      <label key={i}>
        <input type="checkbox" checked={this.state.topics.includes(topic.id)}
               onChange={this.changeTopic.bind(this, topic.id)}/> {topic.name}
      </label>
    ))
    return (
      <div className="filter-box">
        <div className="filter-box">
          <div className="filter-item-header" onClick={this.expendHandle.bind(this, 'TOPIC')}>
            <div>
              {this.props.t('label.topic')}
            </div>
            <div>
              {this.state.topicExpand ? (
                <FontAwesomeIcon icon={faAngleUp}/>
              ) : (
                <FontAwesomeIcon icon={faAngleDown}/>
              )}
            </div>
          </div>
          {this.state.topicExpand ? (
            <div className="filter-item-list">
              {topics}
            </div>
          ) : null
          }
        </div>
        <div className="filter-box">
          <div className="filter-item-header" onClick={this.expendHandle.bind(this, 'RATING')}>
            <div>
              {this.props.t('label.ratings')}
            </div>
            <div>
              {this.state.ratingExpand ? (
                <FontAwesomeIcon icon={faAngleUp}/>
              ) : (
                <FontAwesomeIcon icon={faAngleDown}/>
              )}
            </div>
          </div>
          {this.state.ratingExpand ? (
            <div className="filter-item-list">
              <label>
                <input className="upload-option-item" type="radio" value="" name="option"
                       checked={this.state.rating === ''} onChange={this.changeRating}/>
                {t('label.filter.all')}
              </label>
              <label>
                <input className="upload-option-item" type="radio" value="4" name="option"
                       checked={this.state.rating === '4'} onChange={this.changeRating}/>
                {[1, 2, 3, 4].map((value, i) => (
                  <FontAwesomeIcon key={i} style={{ padding: '3px', color: '#f4c150' }} icon={faStar}/>
                ))}
                {t('label.filter.rating')}
              </label>
              <label>
                <input className="upload-option-item" type="radio" value="3" name="option"
                       checked={this.state.rating === '3'} onChange={this.changeRating}/>
                {[1, 2, 3].map((value, i) => (
                  <FontAwesomeIcon key={i} style={{ padding: '3px', color: '#f4c150' }} icon={faStar}/>
                ))}
                {t('label.filter.rating')}
              </label>
              <label>
                <input className="upload-option-item" type="radio" value="2" name="option"
                       checked={this.state.rating === '2'} onChange={this.changeRating}/>
                {[1, 2].map((value, i) => (
                  <FontAwesomeIcon key={i} style={{ padding: '3px', color: '#f4c150' }} icon={faStar}/>
                ))}
                {t('label.filter.rating')}
              </label>
              <label>
                <input className="upload-option-item" type="radio" value="1" name="option"
                       checked={this.state.rating === '1'} onChange={this.changeRating}/>
                <FontAwesomeIcon style={{ padding: '3px', color: '#f4c150' }} icon={faStar}/> {t('label.filter.rating')}
              </label>
            </div>
          ) : null
          }
        </div>
        <div className="filter-box">
          <div className="filter-item-header" onClick={this.expendHandle.bind(this, 'LANGUAGE')}>
            <div>
              {this.props.t('label.language')}
            </div>
            <div>
              {this.state.languageExpand ? (
                <FontAwesomeIcon icon={faAngleUp}/>
              ) : (
                <FontAwesomeIcon icon={faAngleDown}/>
              )}
            </div>
          </div>
          {this.state.languageExpand ? (
            <div className="filter-item-list">
              <label>
                <input type="checkbox" checked={this.state.languages.includes(EN)}
                       onChange={this.changeLanguage.bind(this, EN)}/> English
              </label>
              <label>
                <input type="checkbox" checked={this.state.languages.includes(VN)}
                       onChange={this.changeLanguage.bind(this, VN)}/> Vietnam
              </label>
            </div>
          ) : null
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  topics: Object.values(state.topics),
})
const mapDispatchToProps = dispatch => ({
  fetchAllTopics: () => dispatch(fetchAllTopics('')),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CourseFilter)))

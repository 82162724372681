import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import './laboratory.css'
import {fetchConnection, connectConnection, disconnectConnection} from '../../services/ConnectionService'
import { fetchLaboratory } from '../../services/LaboratoryService'
import CodeEditor from '../../components/ide/CodeEditor'

class LaboratoryPreview extends React.Component {
  constructor (props) {
    super(props)
    this.processId = 0
    this.state = {
      connectionId: null,
      showClient: false,
      laboratory: {},
      clientUrl: '',
      consoleUrl: null,
      userName: '',
      password: '',
    }
  }

  componentDidMount () {
    const laboratoryId = this.props.match.params.laboratoryId
    this.props.connectConnection({laboratoryId: laboratoryId})
        .then(response => this.setState({connectionId: response.data.id}))

    const request = {
      laboratoryId: laboratoryId
    }
    this.processId = setInterval(() => this.props.fetchConnection(request).then((response) => {
      if (response.data && response.data.containerId) {
        clearInterval(this.processId)

        const connection = response.data
        const userName = connection?.service?.credential?.userName
        const password = connection?.service?.credential?.password
        const param = connection?.client?.parameter
        let clientUrl = connection?.client?.url
        clientUrl = clientUrl ? clientUrl + param : null
        const consoleUserName = connection?.credential?.userName
        const consolePassword = connection?.credential?.password
        const consolePort = connection?.network?.port
        const consoleHost = process.env.CONSOLE_PRIVATE_IP === 'true' ? connection?.network?.privateIp : connection?.network?.publicIp
        let consoleUrl = `${process.env.CONSOLE_APP_URL}/${connection?.id}/${consoleHost}`
        consoleUrl += `?username=${consoleUserName}&password=${consolePassword}&port=${consolePort}`
        this.setState({
          userName: userName,
          password: password,
          clientUrl: clientUrl,
          consoleUrl: consoleUrl,
        })
      }
    }), 2000)
  }

  componentWillUnmount () {
    clearInterval(this.processId)
    this.props.disconnectConnection(this.state.connectionId)
  }

  toggleClient () {
    const showClient = !this.state.showClient
    this.setState({
      showClient: showClient
    })
  }

  render () {
    const { t } = this.props

    return (
      <div>
        <div>
          <span>{t('management.laboratory.userName')}: {this.state.userName} </span>
          <span>{t('management.laboratory.password')}: {this.state.password} </span>
          <a onClick={this.toggleClient.bind(this)} className="laboratory-client">
            {this.state.showClient ? t('label.laboratory.viewConsole') : t('label.laboratory.viewClient')}
          </a>
        </div>
        <div>
          <div hidden={this.state.showClient}>
            <iframe id="lab-console" className="client-box-container"
                    sandbox="allow-same-origin allow-scripts allow-forms allow-top-navigation allow-popups allow-popups-to-escape-sandbox"
                    src={this.state.consoleUrl}/>
          </div>
          <div hidden={!this.state.showClient}>
            {this.state.clientUrl ?
              (<iframe id="lab-client" className="client-box-container"
                       sandbox="allow-same-origin allow-scripts allow-forms allow-top-navigation allow-popups allow-popups-to-escape-sandbox"
                       src={this.state.clientUrl}/>) :
              (<CodeEditor />)}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  connection: state.connection
})

const mapDispatchToProps = dispatch => ({
  connectConnection: (request) => dispatch(connectConnection(request)),
  disconnectConnection: (connectionId) => dispatch(disconnectConnection(connectionId)),
  fetchConnection: (request) => dispatch(fetchConnection(request)),
  fetchLaboratory: (laboratoryId) => dispatch(fetchLaboratory(laboratoryId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LaboratoryPreview)))

import React from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import './classes.css'
import { connect } from 'react-redux'
import { createRegister } from '../../services/RegisterService'

class RegisterClass extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      phone: '',
      description: ''
    }
  }

  changePhone (e) {
    this.setState({
      phone: e.target.value
    })
  }

  changeDescription (e) {
    this.setState({
      description: e.target.value
    })
  }

  submitHandle () {
    const data = {
      classId: this.props.classId,
      phone: this.state.phone,
      description: this.state.description,
    }
    this.props.createRegister(data).then(() => {
      this.props.closePopup()
    })
  }

  render () {
    const { t } = this.props
    return (
      <div className="register-form-container">
        <div className="register-header">
          {t('title.registerClass')}
          <div className="modal-close">
            <FontAwesomeIcon onClick={this.props.closePopup} icon={faTimes}/>
          </div>
        </div>
        <div className="register-form-box">
          <div className="register-creation-item">
            <span>{t('management.classes.phone')}:</span>
            <input className="course-creation-input-text" type="text" value={this.state.phone}
                   onChange={this.changePhone.bind(this)}/>
          </div>
          <div className="register-creation-item">
            <span>{t('management.classes.description')}:</span>
            <textarea className="learning-goals-textarea" value={this.state.description || ''}
                      onChange={this.changeDescription.bind(this)}/>
          </div>
          <div className="main-button-panel">
            <button onClick={this.submitHandle.bind(this)} className="submit-button">
              {t('SUBMIT')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  createRegister: (data) => dispatch(createRegister(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegisterClass)))

import React from 'react'
import ReactPlayer from 'react-player'
import './video.css'

class ExternalVideo extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          url={this.props.url}
          className="react-player"
          playing={false}
          controls={true}
          width="100%"
          height="100%"
        />
      </div>
    )
  }
}

export default ExternalVideo

import * as APIUtil from '../apis/MenuApi'

export const RECEIVE_ALL_MENUS = 'RECEIVE_ALL_MENUS'

const receiveAllMenus = menus => ({
  type: RECEIVE_ALL_MENUS,
  menus
})

export const fetchAllMenus = () => dispatch => (
  APIUtil.fetchAllMenus()
    .then(menus => dispatch(receiveAllMenus(menus.data)))
)

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { getRcToken } from '../../utils/SessionUtil'
import './tool.css'

const CHAT_URL = process.env.CHAT_URL

class ChatTool extends React.Component {
  constructor () {
    super()
  }

  componentDidMount () {
    this.rcLogin()
  }

  rcLogin () {
    document.getElementById('rcChannel').contentWindow.postMessage({
      externalCommand: 'login-with-token',
      token: getRcToken()
    }, '*')
  }

  render () {
    return (
      <div>
        <iframe id="rcChannel" className="chat-tool-container" onLoad={this.rcLogin.bind(this)}
                sandbox="allow-same-origin allow-scripts allow-forms allow-top-navigation allow-popups allow-popups-to-escape-sandbox"
                src={`${CHAT_URL}/channel/general`}/>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatTool)))

import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import './topic.css'

class TopicItem extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    const { t } = this.props
    let topics = this.props.topic.topics.map((topic, i) =>
      <Link key={i} to={`/courses/topic/${topic.url}`} style={{ textDecoration: 'none' }}>
        <div className="topic-card-name">{t(`${topic.name}`)}</div>
        <div className="topic-card-course">{topic.courseNumber} {t('label.courses')}</div>
      </Link>
    )
    return (
      <div className="topic-item-container">
        <div className="topic-card-title">{t(`${this.props.topic.category.name}`)}</div>
        <div className="topic-card-text">
          {topics}
        </div>
      </div>
    )
  }
}

export default withTranslation()(TopicItem)

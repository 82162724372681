import React from 'react'
import { withRouter } from 'react-router'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import UserInfo from '../user/UserInfo'
import logo from '../../assets/logo.png'
import SideMenu from './SideMenu'
import './navbar.css'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ROLES } from '../../utils/Contants'

class NavBar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      iconClassName: 'search-icon',
      inputClassName: 'search-input-normal',
      searchString: '',
      searchInputFull: false
    }
    this.inputFocus = this.inputFocus.bind(this)
    this.inputUnFocus = this.inputUnFocus.bind(this)
    this.getSearchInput = this.getSearchInput.bind(this)
    this.searchClickHandle = this.searchClickHandle.bind(this)
    this.searchSmallClickHandle = this.searchSmallClickHandle.bind(this)
  }

  getSearchInput (e) {
    if (e.key === 'Enter') {
      this.searchClickHandle()
    } else {
      this.setState({
        searchString: e.target.value
      })
    }
  }

  searchClickHandle () {
    this.setState({
      iconClassName: 'search-icon',
      inputClassName: 'search-input-normal',
      searchInputFull: false
    }, () => {
      this.props.history.push(`/courses/search/${this.state.searchString}`)
    })
  }

  searchSmallClickHandle () {
    this.setState({
      iconClassName: 'search-icon-open',
      inputClassName: 'search-input-full',
      searchInputFull: true
    })
  }

  inputFocus () {
    this.setState({
      iconClassName: 'search-icon-open'
    })
  }

  inputUnFocus () {
    this.setState({
      iconClassName: 'search-icon',
      inputClassName: 'search-input-normal'
    })
  }

  onClear () {
    this.setState({
      searchString: '',
      searchInputFull: false
    })
  }

  becomeInstructor () {
    this.props.history.push('/institute/starter')
  }

  render () {
    const { t } = this.props
    const normalResult = (
      <div className="header-box">
        <SideMenu/>
        <Link to="/">
          <div className="system-logo">
            <img src={logo} width="260" height="40" alt="Logo"/>
          </div>
        </Link>
        <label className="search-label">
          <FontAwesomeIcon icon={faSearch} style={{ width: '24px' }}
                           className={this.state.iconClassName} onClick={this.searchClickHandle}/>
          <input className="search-input" type="text" placeholder={t('search.placeholder')}
                 onFocus={this.inputFocus} onBlur={this.inputUnFocus} onKeyUp={this.getSearchInput}/>
        </label>
        {!this.props.session.roles || this.props.session.roles.length === 0 ||
        (this.props.session.roles.length === 1 && this.props.session.roles[0] === ROLES.DEFAULT) ?
          <div className="institute-nav" onClick={this.becomeInstructor.bind(this)}>{t('INSTITUTE')}</div>
          : null}
        <UserInfo/>
      </div>
    )
    const smallResult = (
      <div className="header-box">
        <SideMenu/>
        <Link to="/">
          <div className="system-logo">
            <img src={logo} width="250" height="40" alt="Logo"/>
          </div>
        </Link>
        <label className="search-label">
          <FontAwesomeIcon icon={faSearch} style={{ width: '24px' }} className={this.state.iconClassName}
                           onClick={this.searchSmallClickHandle}/>
          {this.state.searchInputFull ? (
            <div>
              <input className="search-input-full" type="text" placeholder={t('search.placeholder')}
                     onFocus={this.inputFocus} onBlur={this.inputUnFocus} onKeyUp={this.getSearchInput}
                     onSubmit={this.searchClickHandle}/>
              <div className="input-clear" onClick={this.onClear.bind(this)}>X</div>
            </div>
          ) : null}
        </label>
      </div>
    )

    return (
      window.innerWidth > 720 ? normalResult : smallResult
    )
  }
}

const mapStateToProps = (state) => ({
  session: state.session
})

const mapDisPatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(NavBar)))

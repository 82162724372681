import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import './tool.css'
import CodeEditor from '../../components/ide/CodeEditor'

class CodeEditorTool extends React.Component {
  constructor () {
    super()
  }

  render () {
    return (
      <div>
       <CodeEditor />
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CodeEditorTool)))

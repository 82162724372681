import { RECEIVE_ALL_FEEDBACKS } from '../services/FeedbackService'

const FeedbackReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_ALL_FEEDBACKS:
      nextState.feedbacks = action.feedbacks
      return nextState
    default:
      return state
  }
}

export default FeedbackReducer

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ChatBox from '../../components/chat/ChatBox'
import MyClassHeading from '../../components/classes/MyClassHeading'
import { fetchClassesByUrl } from '../../services/ClassService'
import MyClassInfo from '../../components/classes/MyClassInfo'
import './learning.css'
import DocumentList from '../../components/document/DocumentList'
import MyClassMeeting from '../../components/classes/MyClassMeeting'
import MyClassLaboratory from '../../components/classes/MyClassLaboratory'

class ClassRoom extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      class: {}
    }
  }

  componentDidMount () {
    this.fetchData(this.props.match.params.classUrl)
  }

  fetchData (classUrl) {
    this.props.fetchClassesByUrl(classUrl).then((response) => {
      this.setState({
        class: response.data
      })
    })
  }

  render () {
    if (!this.state.class.id) {
      return null
    }

    return (
      <div className="app-container">
        <MyClassHeading class={this.state.class}/>
        <div className="class-container">
          <div className="class-room-container">
            <div className="class-room-info">
              <MyClassInfo class={this.state.class}/>
              <MyClassMeeting class={this.state.class}/>
              <MyClassLaboratory class={this.state.class}/>
              <DocumentList classId={this.state.class.id}/>
            </div>
            <div className="class-room-chat">
              <ChatBox class={this.state.class}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  classes: Object.values(state.classes),
})

const mapDisPatchToProps = dispatch => ({
  fetchClassesByUrl: (url) => dispatch(fetchClassesByUrl(url)),
})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(ClassRoom)))

import React from 'react'
import defaultImage from '../../assets/user.png'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import './comment.css'
import { createComment } from '../../services/CommentService'

class CommentBox extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      content: ''
    }
  }

  changeContent (e) {
    this.setState({
      content: e.target.value
    })
  }

  postComment () {
    if (this.props.session.id === null) {
      this.props.history.push('/login')
      return
    }

    let comment = {
      content: this.state.content,
      classId: this.props.classId,
      instituteId: this.props.instituteId,
      replyId: this.props.replyId,
    }
    this.props.createComment(comment)
    this.props.onReply()
  }

  render () {
    const { t } = this.props
    const avatar = this.props.session.avatar ? this.props.session.avatar : defaultImage
    return (
      <div className="comment-box-container">
        <div>
          <img className="comment-image" src={avatar} alt=""/>
        </div>
        <div className="comment-content">
          <textarea value={this.state.content || ''} onChange={this.changeContent.bind(this)}/>
          <button className="comment-post-button" onClick={this.postComment.bind(this)}>{t('Post')}</button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  session: state.session
})

const mapDispatchToProps = dispatch => ({
  createComment: (comment) => dispatch(createComment(comment))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CommentBox)))

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import './institute.css'
import { ROLES } from '../../utils/Contants'

class BecomeInstitute extends React.Component {
  constructor (props) {
    super(props)
  }

  submitHandle () {
    this.props.history.push('/institute/starter')
  }

  render () {
    if (this.props.session.roles && this.props.session.roles.includes(ROLES.INSTITUTE_ADMIN)) {
      return null
    }

    const { t } = this.props
    return (
      <div className="institute-container">
        <div className="index-header-container">
          <div className="index-header">{t('Become an institute')}</div>
        </div>
        <div>
          <div className="institute-container-wrapper">
            {t('institute.description')}
          </div>
          <div className="carousel-rel-wrapper">
            <button onClick={this.submitHandle.bind(this)} className="submit-institute-button">
              {t('Become an institute')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  session: state.session
})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BecomeInstitute)))

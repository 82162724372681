import { RECEIVE_ALL_COMMENTS, RECEIVE_COMMENT, REMOVE_COMMENT } from '../services/CommentService'

const CommentReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_ALL_COMMENTS:
      let comments = action.comments.reduce(function (map, obj) {
        map[obj.id] = obj
        return map
      }, {})
      nextState = comments
      return nextState
    case RECEIVE_COMMENT:
      nextState[action.comment.id] = action.comment
      return nextState
    case REMOVE_COMMENT:
      delete nextState[action.commentId]
      return nextState
    default:
      return state
  }
}

export default CommentReducer

import React from 'react'
import { withTranslation } from 'react-i18next'
import defaultImage from '../../assets/default-image.png'
import * as CommonUtils from '../../utils/CommonUtils'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ClassPathIndicator from '../common/ClassPathIndicator'
import RegisterClass from './RegisterClass'

class ClassHeading extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showPopup: false
    }
  }

  togglePopup () {
    this.setState({
      showPopup: !this.state.showPopup
    })
  }

  render () {
    if (!this.props.class) {
      return null
    }

    const { t } = this.props
    const image = this.props.class.banner ? this.props.class.banner : defaultImage
    const backImg = {
      backgroundImage: `url('${image}')`,
      backgroundPosition: 'top',
      backgroundRepeat: 'noRepeat',
      backgroundSize: 'cover',
    }
    return (
      <div className="heading_box" style={backImg}>
        <div>
          <ClassPathIndicator className="index-rating-span" data={this.props.class}/>
          <div className="class-name">{this.props.class.name}</div>
          <div className="class-description">{this.props.class.description}</div>
          <div>
            <div className="class-header-price">{CommonUtils.getPrice()}</div>
            <button onClick={this.togglePopup.bind(this)} className="add-class-button">
              {t('label.registerClass')}
            </button>
            {this.state.showPopup ?
              <RegisterClass classId={this.props.class.id} closePopup={this.togglePopup.bind(this)}/>
              : null}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  session: state.session
})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClassHeading)))

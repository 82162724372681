import * as APIUtil from '../apis/MeetingApi'

export const createMeeting = (meeting) => dispatch => (
  APIUtil.createMeeting(meeting)
)

export const getClassMeeting = (classUrl) => dispatch => (
  APIUtil.getClassMeeting(classUrl)
)

export const getOwnerMeeting = () => dispatch => (
  APIUtil.getOwnerMeeting()
)

export const inviteMeeting = (id, meeting) => dispatch => (
  APIUtil.inviteMeeting(id, meeting)
)

export const getInvitedMeeting = () => dispatch => (
  APIUtil.getInvitedMeeting()
)

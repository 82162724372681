import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import '../../institute/institute.css'
import { withTranslation } from 'react-i18next'
import PagingNumber from '../../../components/table/PagingNumber'
import ReactReviewTable from '../../../components/table/ReactReviewTable'
import { deleteClass, fetchReviewClasses, reviewClass } from '../../../services/ClassService'

class ReviewClass extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      order: 0,
      size: 15,
      status: 'SUBMITTED',
      filter: ''
    }
    this.search = this.search.bind(this)
  }

  componentDidMount () {
    this.search()
  }

  search () {
    const request = {
      value: this.state.filter,
      query: {
        status: this.state.status === '' ? null : this.state.status
      },
      page: this.state.currentPage - 1,
      size: this.state.size
    }
    this.props.fetchReviewClasses(request)
  }

  approveItem (classId) {
    const data = {
      'status': 'APPROVED'
    }
    this.props.reviewClass(classId, data).then(() => {
      this.search()
    })
  }

  rejectItem (classId) {
    const data = {
      'status': 'REJECTED'
    }
    this.props.reviewClass(classId, data).then(() => {
      this.search()
    })
  }

  editItem (classId) {
    this.props.history.push(`/institute/classes/update/${classId}`)
  }

  deleteItem (classId) {
    this.props.deleteClass(classId).then(() => {
      this.search()
    })
  }

  filterTable (filter) {
    this.setState({
      filter: filter
    }, () => this.search())
  }

  handleClickPage (page) {
    this.setState({
      currentPage: page
    }, () => {
      this.search()
    })
  }

  changeStatus (e) {
    this.setState({
      status: e.target.value
    }, () => this.search())
  }

  render () {
    const { t } = this.props
    const header = (
      <div className="heading-creation-box">
        {t('management.classes.title.review')}
      </div>
    )
    if (this.props.classes.length === 0) {
      return header
    }

    this.props.classes[0].data.map((item, i) => {
      item['categoryName'] = t(`${item['categoryName']}`)
      item['topicName'] = t(`${item['topicName']}`)
      item['status'] = t(`${item['status']}`)
    })
    return (
      <div>
        {header}
        <div className="input-box">
          <div className="course-creation-input-label">{t('label.status')}:</div>
          <select className="course-creation-input-select" value={this.state.status}
                  onChange={this.changeStatus.bind(this)}>
            <option value="">{t('ALL')}</option>
            <option value="SUBMITTED">{t('SUBMIT')}</option>
            <option value="APPROVED">{t('APPROVE')}</option>
            <option value="REJECTED">{t('REJECT')}</option>
          </select>
        </div>
        <div>
          <ReactReviewTable
            onApproveItem={this.approveItem.bind(this)}
            onRejectItem={this.rejectItem.bind(this)}
            onEditItem={this.editItem.bind(this)}
            onDeleteItem={this.deleteItem.bind(this)}
            isFilter={true}
            onFilter={this.filterTable.bind(this)}
            data={this.props.classes[0].data}
            columns={[
              {
                Header: t('management.classes.column.category'),
                accessor: 'categoryName',
                minWidth: 50,
              },
              {
                Header: t('management.classes.column.topic'),
                accessor: 'topicName',
                minWidth: 50
              },
              {
                Header: t('management.classes.column.institute'),
                accessor: 'instituteName',
                minWidth: 50,
              },
              {
                Header: t('management.classes.column.status'),
                accessor: 'status',
                minWidth: 50,
              },
              {
                Header: t('management.classes.column.name'),
                accessor: 'name',
                minWidth: 300
              }
            ]}
          />
        </div>
        <PagingNumber currentPage={this.state.currentPage} totalPage={this.props.classes[0].totalPage}
                      onChangePage={this.handleClickPage.bind(this)}/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  classes: Object.values(state.reviewClasses),
})

const mapDispatchToProps = dispatch => ({
  fetchReviewClasses: (query) => dispatch(fetchReviewClasses(query)),
  reviewClass: (classId, data) => dispatch(reviewClass(classId, data)),
  deleteClass: (classId) => dispatch(deleteClass(classId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReviewClass)))

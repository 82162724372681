import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

import rootReducer from '../reducers/RootReducer'

const configureStore = (preloadedState = {}) => {
  const middleware = [thunk]

  if (process.env.NODE_ENV !== 'production') {
    middleware.push(logger)
  }
  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(...middleware)
  )
}

export default configureStore

import * as API from './API'

export const createMeeting = (meeting) => (
  API.post(`meetings`, meeting)
)

export const getClassMeeting = (classUrl) => (
  API.get(`meetings/class?classUrl=${classUrl}`)
)

export const getOwnerMeeting = () => (
  API.get(`meetings/owner`)
)

export const inviteMeeting = (id, meeting) => (
  API.post(`meetings/${id}/invite`, meeting)
)

export const getInvitedMeeting = () => (
  API.get(`meetings/invite`)
)

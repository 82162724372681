import React from 'react'
import { withRouter } from 'react-router-dom'
import TopicItem from '../../components/topic/TopicItem'
import { fetchTopTopics } from '../../services/TopicService'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import SingleLineTable from '../../components/table/SingleLineTable'
import './topic.css'

class TopicList extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    this.props.fetchTopTopics()
  }

  render () {
    const { t } = this.props
    let items = this.props.topTopics.map((topic, i) =>
      <div key={i} className="topic-tile">
        <TopicItem key={i} topic={topic}/>
      </div>
    )
    return (
      <div>
        <div className="index-header-container">
          <div className="index-header">{t('home.topic.label')}</div>
        </div>
        <SingleLineTable items={items}/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  topTopics: Object.values(state.topTopics)
})

const mapDisPatchToProps = dispatch => ({
  fetchTopTopics: () => dispatch(fetchTopTopics())
})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(TopicList)))

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { filterMachines } from '../../../../services/MachineService'
import * as APIUtil from '../../../../apis/CommandApi'

class CommandUtil extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      machineId: '',
      command: '',
      results: [],
    }
  }

  componentDidMount () {
    this.search()
  }

  changeMachine (e) {
    this.setState({
      machineId: e.target.value,
    })
  }

  changeCommand (e) {
    this.setState({
      command: e.target.value
    })
  }

  submitHandle () {
    const data = {
      machineId: this.state.machineId ? this.state.machineId : this.props.machines[0]?.data[0]?.id,
      command: this.state.command
    }
    APIUtil.executeCommand(data).then(response => {
      this.setState({
        results: response.data.results
      })
    })
  }

  search () {
    const request = {}
    this.props.filterMachines(request)
  }

  render () {
    const { t } = this.props
    const machines = this.props.machines[0]?.data?.map((item, i) => (
      <option key={i} value={item.id}>{item.name}</option>
    ))
    return (
      <div>
        <div className="heading-creation-box">
          {t('Command Util')}
        </div>
        <div>
          <div className="input-box">
            <div className="laboratory-creation-input-label">{t('management.laboratory.os')}:</div>
            <select className="course-creation-input-select" value={this.state.machineId}
                    onChange={this.changeMachine.bind(this)}>
              {machines}
            </select>
          </div>
          <div className="course-creation-item">
            <span>{t('management.laboratory.description')}:</span>
            <textarea className="learning-goals-textarea" value={this.state.command || ''}
                      onChange={this.changeCommand.bind(this)}/>
          </div>
          <div className="main-button-panel">
            <button onClick={this.submitHandle.bind(this)} className="submit-button">
              {t('EXECUTE')}
            </button>
          </div>
          <div className="course-creation-item">
            <span>{t('Result')}:</span>
            <div dangerouslySetInnerHTML={{ __html: this.state.results.join('<br/>') }}/>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  machines: Object.values(state.machines),
})

const mapDispatchToProps = dispatch => ({
  filterMachines: (query) => dispatch(filterMachines(query)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CommandUtil)))

import * as APIUtil from '../apis/ConfigurationApi'

export const RECEIVE_CONFIGURATION = 'RECEIVE_CONFIGURATION'

const receiveConfiguration = configuration => ({
  type: RECEIVE_CONFIGURATION,
  configuration
})

export const fetchConfiguration = () => dispatch => (
  APIUtil.fetchConfiguration()
    .then(response => dispatch(receiveConfiguration(response.data)))
)

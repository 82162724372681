import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './common.css'

class ClassPathIndicator extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const { t } = this.props
    return (
      <div className="path-indicator-box">
        <div>
          <Link to={`/institute/dashboard/${this.props.data.instituteUrl}`}>
            <span className="path-indicator-link">{t(`${this.props.data.instituteName}`)}</span>
          </Link>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ClassPathIndicator)

import { RECEIVE_ALL_VIDEOS, RECEIVE_VIDEO } from '../services/VideoService'

export const ListVideoReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)
  switch (action.type) {
    case RECEIVE_ALL_VIDEOS:
      if (action.videos[0]) {
        nextState[action.contentId] = action.videos
      }
      return nextState
    default:
      return state
  }
}

export const VideoReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)
  switch (action.type) {

    case RECEIVE_VIDEO:
      nextState[action.video.id] = action.video
      return nextState

    default:
      return state
  }
}

const TOKEN_KEY = 'session'
const RC_TOKEN_KEY = 'rcToken'

export const isSignIn = () => (
  localStorage.getItem(TOKEN_KEY)
)

export const getToken = () => (
  localStorage.getItem(TOKEN_KEY)
)

export const getRcToken = () => (
  localStorage.getItem(RC_TOKEN_KEY)
)

export const storeToken = (data) => {
  localStorage.setItem(TOKEN_KEY, data.token)
  localStorage.setItem(RC_TOKEN_KEY, data.rcToken)
}

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(RC_TOKEN_KEY)
}

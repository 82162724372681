import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {
  applyCrawling,
  applyMultipleCrawling,
  deleteAllCrawling,
  deleteCrawling,
  fetchAllCrawling,
  getRunningJob,
  triggerCrawlingJob
} from '../../../services/CrawlingService'
import './crawling.css'
import ReactTable from '../../../components/table/ReactTable'
import { fetchAllCategories } from '../../../services/CategoryService'
import { fetchAllTopics } from '../../../services/TopicService'
import PagingNumber from '../../../components/table/PagingNumber'
import * as CommonUtils from '../../../utils/CommonUtils'

class CrawlingJob extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      type: 'CHANNEL',
      dataType: 'ALL',
      keyword: '',
      job: '',
      data: [],
      categoryId: '',
      topicId: '',
      currentPage: 1,
      size: 15,
      totalPage: 1,
      totalRecord: 0,
      filter: '',
      selectedIds: [],
    }

    this.changeType = this.changeType.bind(this)
    this.changeDataType = this.changeDataType.bind(this)
  }

  componentDidMount () {
    this.getData()
  }

  changeKeyword (e) {
    this.setState({
      keyword: e.target.value
    })
  }

  changeType (e) {
    this.setState({
      type: e.currentTarget.value
    })
  }

  changeDataType (e) {
    this.setState({
      dataType: e.currentTarget.value
    })
  }

  changeCategory (e) {
    this.setState({
      categoryId: e.target.value
    })
    this.props.fetchAllTopics(e.target.value)
  }

  changeTopic (e) {
    this.setState({
      topicId: e.target.value
    })
  }

  triggerCrawlingJob () {
    const request = {
      type: this.state.type,
      dataType: this.state.dataType,
      keyword: this.state.keyword
    }
    this.props.triggerCrawlingJob(request)
  }

  handleClickPage (page) {
    this.setState({
      currentPage: page
    }, () => {
      this.getData()
    })
  }

  getData () {
    this.props.getRunningJob().then((response) => {
      this.setState({
        job: response.data ? 'Job Running' : 'Job Finish'
      })
    })

    const query = {
      value: this.state.filter,
      page: this.state.currentPage - 1,
      size: this.state.size
    }
    this.props.fetchAllCrawling(query).then((response) => {
      this.setState({
        data: response.data.data,
        totalPage: response.data.totalPage,
        totalRecord: response.data.totalRecord
      })
    })
  }

  deleteItem (id) {
    this.props.deleteCrawling(id).then(() => {
      this.getData()
    })
  }

  deleteAllItem () {
    this.props.deleteAllCrawling().then(() => {
      this.getData()
    })
  }

  applyItem (id) {
    if (this.state.categoryId === '' || this.state.topicId === '') {
      return
    }

    const data = {
      categoryId: this.state.categoryId,
      topicId: this.state.topicId
    }
    this.props.applyCrawling(id, data).then(() => {
      this.getData()
      this.setState({
        categoryId: '',
        topicId: '',
      })
    })
  }

  applyMultipleItem (type) {
    if (this.state.categoryId === '' || this.state.topicId === '') {
      return
    }

    const data = {
      categoryId: this.state.categoryId,
      topicId: this.state.topicId,
      selectedIds: this.state.selectedIds,
      type: type === '' ? null : type
    }
    this.props.applyMultipleCrawling(data).then(() => {
      this.getData()
      this.setState({
        categoryId: '',
        topicId: '',
      })
    })
  }

  changeFilter (e) {
    this.setState({
      filter: e.target.value
    }, () => this.getData())
  }

  changeSelectItem (selected) {
    let selectedIds = this.state.selectedIds
    if (selectedIds.includes(selected)) {
      selectedIds.splice(selectedIds.indexOf(selected), 1)
    } else {
      selectedIds.push(selected)
    }

    this.setState({
      selectedIds: selectedIds
    })
  }

  renderActionCell (row, value) {
    const { t } = this.props
    return (
      <div className="button-panel">
        <a className="list-action-button" onClick={() => { this.applyItem(value) }}>{t('APPLY')}</a>
        <a className="list-action-button" onClick={() => { this.deleteItem(value) }}>{t('DELETE')}</a>
      </div>
    )
  }

  render () {
    const { t } = this.props
    const categories = this.props.categories.map((category, i) => (
      <option key={i} value={category.id}>{t(`${category.name}`)}</option>
    ))
    const topics = this.props.topics.map((topic, i) => (
      <option key={i} value={topic.id}>{t(`${topic.name}`)}</option>
    ))
    return (
      <div>
        <div className="heading-creation-box">
          {t('management.crawling.title.job')}
        </div>
        <div className="crawling-selection">
          <div className="course-creation-input-label">{t('label.dataType')}:</div>
          <input className="crawling-type-item" type="radio" value="URL" name="dataType"
                 checked={this.state.dataType === 'URL'} onChange={this.changeDataType}/>Url
          <input className="crawling-type-item" type="radio" value="ALL" name="dataType"
                 checked={this.state.dataType === 'ALL'} onChange={this.changeDataType}/>All
        </div>
        <div className="crawling-selection">
          <div className="course-creation-input-label">{t('label.type')}:</div>
          <input className="crawling-type-item" type="radio" value="CHANNEL" name="type"
                 checked={this.state.type === 'CHANNEL'} onChange={this.changeType}/>Channel
          <input className="crawling-type-item" type="radio" value="SEARCH" name="type"
                 checked={this.state.type === 'SEARCH'} onChange={this.changeType}/>Search
        </div>
        <div className="crawling-selection">
          <div className="course-creation-input-label">{t('label.keyword')}:</div>
          <input className="crawling-input-text" type="text" value={this.state.keyword}
                 onChange={this.changeKeyword.bind(this)}/>
        </div>
        <div className="main-button-panel">
          <button className="crawling-button" onClick={this.triggerCrawlingJob.bind(this)}>
            {t('SUBMIT')}
          </button>
          <button onClick={this.getData.bind(this)} className="crawling-button">
            {t('GET DATA')}
          </button>
          <button className="crawling-button" onClick={() =>
            window.confirm('Are you want to delete all data?') && this.deleteAllItem()}>
            {t('DELETE')}
          </button>
        </div>
        <div>
          <div className="crawling-data-item">
            {this.state.job} {this.state.totalRecord > 0 ? `${this.state.totalRecord} Items` : ''}
          </div>
          <div className="crawling-selection">
            <div className="input-box">
              <div>{t('label.category')}:</div>
              <select className="crawling-input-select"
                      onChange={this.changeCategory.bind(this)} value={this.state.categoryId}>
                <option value="">{t('selection.default')}</option>
                {categories}
              </select>
            </div>
            <div className="input-box">
              <div>{t('label.topic')}:</div>
              <select className="crawling-input-select" value={this.state.topicId}
                      onChange={this.changeTopic.bind(this)}>
                <option value="">{t('selection.default')}</option>
                {topics}
              </select>
            </div>
            <div className="input-box">
              <div>{t('label.search')}:</div>
              <input className="crawling-filter-input-text" type="text" value={this.state.filter}
                     onChange={this.changeFilter.bind(this)}/>
            </div>
            <div>
              <button className="crawling-apply-button" onClick={this.applyMultipleItem.bind(this, '')}>
                {t('APPLY SELECTED')}
              </button>
              <button className="crawling-apply-button" onClick={this.applyMultipleItem.bind(this, 'CHANNEL_PLAYLIST')}>
                {t('APPLY PLAYLIST')}
              </button>
            </div>
          </div>
          <ReactTable
            data={this.state.data}
            columns={[
              {
                Header: t('management.crawling.column.select'),
                accessor: 'id',
                Cell: ({ row, value }) => (
                  <input
                    type="checkbox"
                    checked={this.state.selectedIds.includes(value)}
                    onChange={() => this.changeSelectItem(value)}
                  />
                ),
                sortable: false,
                filterable: false
              },
              {
                Header: t('management.crawling.column.channel'),
                accessor: 'channel',
                minWidth: 30,
              },
              {
                Header: t('management.crawling.column.url'),
                accessor: 'url',
                minWidth: 200,
                Cell: ({ row }) => <a href={row.original.url}>Link</a>
              },
              {
                Header: t('management.crawling.column.playlist'),
                accessor: 'playlist',
                minWidth: 200,
                Cell: ({ row }) => <a href={row.original.playlist}>{row.original.playlistId}</a>
              },
              {
                Header: t('management.crawling.column.title'),
                accessor: 'title',
                minWidth: 200,
              },
              {
                Header: t('management.crawling.column.playlistTitle'),
                accessor: 'playlistTitle',
                minWidth: 200,
              },
              {
                Header: t('management.crawling.column.itemCount'),
                accessor: 'itemCount',
                minWidth: 30,
              },
              {
                Header: t('management.crawling.column.duration'),
                accessor: 'duration',
                minWidth: 30,
                Cell: ({ row }) => <div>{CommonUtils.getDuration(row.original.duration)}</div>
              },
              {
                Header: t('management.crawling.column.view'),
                accessor: 'view',
                minWidth: 30,
              },
              {
                Header: t('management.crawling.column.like'),
                accessor: 'like',
                minWidth: 30,
              },
              {
                Header: t('management.crawling.column.comment'),
                accessor: 'comment',
                minWidth: 30,
              },
              {
                Header: t('management.crawling.column.favorite'),
                accessor: 'favorite',
                minWidth: 30,
              },
              {
                Header: t('management.crawling.column.dislike'),
                accessor: 'dislike',
                minWidth: 30,
              },
              {
                Header: t('Action'),
                id: 'button',
                accessor: 'id',
                minWidth: 30,
                Cell: ({ row, value }) => (this.renderActionCell(row, value))
              }
            ]}
          />
          <PagingNumber currentPage={this.state.currentPage} totalPage={this.state.totalPage}
                        onChangePage={this.handleClickPage.bind(this)}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  topics: Object.values(state.topics),
  categories: Object.values(state.categories),
})

const mapDispatchToProps = dispatch => ({
  fetchAllCrawling: (query) => dispatch(fetchAllCrawling(query)),
  triggerCrawlingJob: (data) => dispatch(triggerCrawlingJob(data)),
  getRunningJob: () => dispatch(getRunningJob()),
  deleteCrawling: (id) => dispatch(deleteCrawling(id)),
  deleteAllCrawling: () => dispatch(deleteAllCrawling()),
  applyCrawling: (id, data) => dispatch(applyCrawling(id, data)),
  applyMultipleCrawling: (data) => dispatch(applyMultipleCrawling(data)),
  fetchAllCategories: () => dispatch(fetchAllCategories()),
  fetchAllTopics: (categoryId) => dispatch(fetchAllTopics(categoryId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CrawlingJob)))

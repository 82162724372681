import React from 'react'
import moment from 'moment'
import * as Session from '../../utils/SessionUtil'
import { withTranslation } from 'react-i18next'
import * as CommonUtils from '../../utils/CommonUtils'

class ReviewItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      edit: false,
      review: {
        courseId: this.courseId,
        rating: 5,
        body: ''
      }
    }

    this.showButton = this.showButton.bind(this)
    this.editReview = this.editReview.bind(this)
    this.getBodyText = this.getBodyText.bind(this)
    this.editReviewSubmit = this.editReviewSubmit.bind(this)
    this.handleRatingChange = this.handleRatingChange.bind(this)
  }

  editReview () {
    this.setState({
      edit: !this.state.edit
    })
  }

  getBodyText (e) {
    const review = Object.assign({}, this.state.review, { body: e.target.value })
    this.setState({ review })
  }

  editReviewSubmit (id) {
    let that = this
    this.props.updateReview(this.state.review, id)
      .then(() => {
        that.setState({
          edit: !that.state.edit
        })
      })
  }

  showButton () {
    const { t } = this.props
    if (Session.isSignIn() && this.props.review.userId === this.props.userId) {
      return (
        <div className="button-box-review">
          <button className="review-form-button" onClick={this.editReview}>{t('EDIT')}</button>
          <button className="review-form-button" onClick={() => this.props.deleteReview(this.props.review.id)}>
            {t('DELETE')}
          </button>
        </div>
      )
    }
  }

  handleRatingChange (e) {
    const review = Object.assign({}, this.state.review, { rating: e.target.value * 1 })
    this.setState({ review })
  }

  render () {
    const nameArr = this.props.review.userName.split(' ')
    const initialName = []
    for (let i = 0; i < nameArr.length; i++) {
      initialName.push(nameArr[i][0])
    }
    if (!this.state.edit) {
      return (
        <div className="review-feed-container">
          <div className="review-user-info">
            <div className="initial-review">{initialName.join('').toUpperCase()}</div>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '15px' }}>
              <div style={{ color: '#686f7a' }}>
                {moment(this.props.review.createdDate).startOf('min').fromNow()}
              </div>
              <div style={{ color: '#29303b', fontSize: '15px' }}>{this.props.review.userName}</div>
            </div>
          </div>
          <div className="review-text">
            <div className="index-card-ratting-feed"><span
              className="index-rating-span">{CommonUtils.getRating(this.props.review.rating)}</span><span
              className="index-rating-span">{this.props.review.rating}</span></div>
            <div>{this.props.review.body}</div>
            {this.showButton(this.props.review.userId)}
          </div>
        </div>
      )
    } else {
      return (
        <div className="review-feed-container-">
          <div className="edit-form">
                        <textarea className="edit-form-textarea" onChange={this.getBodyText}
                                  defaultValue={this.props.review.body}></textarea>
            <div className="review-ratting-box">
              <input onChange={this.handleRatingChange} type="radio" name="rating" value="1"/>1
              <input onChange={this.handleRatingChange} type="radio" name="rating" value="2"/>2
              <input onChange={this.handleRatingChange} type="radio" name="rating" value="3"/>3
              <input onChange={this.handleRatingChange} type="radio" name="rating" value="4"/>4
              <input onChange={this.handleRatingChange} type="radio" name="rating" value="5"/>5
            </div>
            <button onClick={() => this.editReviewSubmit(this.props.review.id)}
                    className="edit-form-button">{t('SUBMIT')}
            </button>
          </div>
        </div>
      )
    }

  }
}

export default withTranslation()(ReviewItem)

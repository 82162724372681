import React from 'react'
import { Link } from 'react-router-dom'
import defaultImage from '../../assets/default-image.png'
import * as CommonUtils from '../../utils/CommonUtils'

class CourseItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      imgClassName: 'img-cover'
    }

    this.imgCoverShow = this.imgCoverShow.bind(this)
    this.imgCoverHidden = this.imgCoverHidden.bind(this)
  }

  imgCoverShow () {
    this.setState({
      imgClassName: 'img-cover-show'
    })
  }

  imgCoverHidden () {
    this.setState({
      imgClassName: 'img-cover'
    })
  }

  render () {
    const image = this.props.course.thumbnail ? this.props.course.thumbnail : defaultImage
    let title = this.props.course.title
    title = title && title.length > 60 ? title.substr(0, 60) + ' ...' : title
    return (
      <Link to={`/courses/${this.props.course.url}`} className="course-card" style={{ textDecoration: 'none' }}>
        <div>
          <img style={{ width: '100%' }} src={image} alt={'Course Thumbnail'}/>
          <div className={this.state.imgClassName}/>
        </div>
        <div className="index-card-text">
          <div className="index-card-title">{title}</div>
          {this.props.course.rating > 0 ? (
            <div className="index-card-ratting">
              <span className="index-rating-span">{CommonUtils.getRating(this.props.course.rating)}</span>
              <span className="index-rating-span">{this.props.course.rating}</span>
              <span className="index-rating-span" style={{ color: '#686f7a' }}>({this.props.course.ratingNumber})</span>
            </div>
          ) : null}
          <div className="index-card-price">{CommonUtils.getPrice(this.props.course.price)}</div>
        </div>
      </Link>
    )
  }
}

export default CourseItem

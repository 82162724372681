import * as API from './API'

export const uploadFile = (data, docType) => (
  API.upload(`files?docType=${docType}`, data)
)

export const uploadMultipleFile = (data, docType) => (
  API.upload(`files/multiple?docType=${docType}`, data)
)

export const getFile = (id) => (
  API.get(`files/${id}`)
)

export const downloadFile = (id) => (
  API.download(`files/${id}/download`)
)

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './index.css'
import { createTopic, deleteTopic, editTopic, fetchAllTopics } from '../../../services/TopicService'
import { fetchAllCategories } from '../../../services/CategoryService'
import { withTranslation } from 'react-i18next'
import ReactEditableTable from '../../../components/table/ReactEditableTable'
import { fetchAllMenus } from '../../../services/MenuService'
import { TABLE_DATA_TYPE } from '../../../utils/Contants'

class TopicManagement extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      category: '',
      categories: [],
    }

    this.changeName = this.changeName.bind(this)
    this.changeCategory = this.changeCategory.bind(this)
    this.saveItem = this.saveItem.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.createTopic = this.createTopic.bind(this)
  }

  componentDidMount () {
    this.props.fetchAllCategories()
    this.props.fetchAllTopics()
  }

  changeName (e) {
    this.setState({
      name: e.target.value
    })
  }

  changeCategory (e) {
    this.setState({
      category: e.target.value
    })
  }

  saveItem (topicId, topic) {
    topic['categoryId'] = topic['categoryName']
    this.props.editTopic(topicId, topic).then(() => {
      this.props.fetchAllMenus()
    })
  }

  deleteItem (topicId) {
    this.props.deleteTopic(topicId).then(() => {
      this.props.fetchAllMenus()
    })
  }

  createTopic () {
    let topic = {
      name: this.state.name,
      categoryId: this.state.category
    }
    this.props.createTopic(topic).then(() => {
      this.props.fetchAllMenus()
      this.setState({
        name: '',
        category: ''
      })
    })
  }

  render () {
    const { t } = this.props

    let categories = this.props.categories.map((category, i) => (
      <option key={i} value={category.id}>{t(`${category.name}`)}</option>
    ))

    return (
      <div>
        <div className="heading-creation-box">
          {t('management.topic.title')}
        </div>
        <div>
          <div className="category-creation-box">
            <div className="category-creation-item">
              <span className="label-item">{t('label.name')}:</span>
              <input className="category-creation-input-text" type="text" value={this.state.name}
                     onChange={this.changeName}/>
            </div>
            <div className="category-creation-item">
              <span className="label-item">{t('label.category')}:</span>
              <select className="course-creation-input-select"
                      onChange={this.changeCategory} value={this.state.category}>
                <option value="">{t('selection.default')}</option>
                {categories}
              </select>
            </div>
          </div>
          <button onClick={this.createTopic} className="create-button">
            {t('management.topic.create')}
          </button>
          <ReactEditableTable
            onSaveItem={this.saveItem}
            onDeleteItem={this.deleteItem}
            data={this.props.topics}
            columns={[
              {
                Header: t('management.topic.column.name'),
                accessor: 'name',
                minWidth: 50,
                type: TABLE_DATA_TYPE.TEXT_TYPE
              },
              {
                Header: t('management.topic.column.category'),
                accessor: 'categoryName',
                width: 200,
                type: TABLE_DATA_TYPE.DROPDOWN_TYPE,
                selections: categories
              }
            ]}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  categories: Object.values(state.categories),
  topics: Object.values(state.topics),
})

const mapDispatchToProps = dispatch => ({
  fetchAllCategories: () => dispatch(fetchAllCategories()),
  fetchAllTopics: () => dispatch(fetchAllTopics()),
  createTopic: (topic) => dispatch(createTopic(topic)),
  deleteTopic: (topicId) => dispatch(deleteTopic(topicId)),
  editTopic: (topicId, topic) => dispatch(editTopic(topicId, topic)),
  fetchAllMenus: () => dispatch(fetchAllMenus()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopicManagement)))

import {
  RECEIVE_BEST_COURSES,
  RECEIVE_COURSE,
  RECEIVE_COURSES,
  RECEIVE_MORE_COURSES,
  RECEIVE_SIMILAR_COURSES,
  RECEIVE_SUGGESTION_COURSES,
  REMOVE_COURSE
} from '../services/CourseService'

export const CourseReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_COURSE:
      nextState = action.course
      return nextState
    default:
      return state
  }
}

export const BestCourseReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_BEST_COURSES:
      nextState = action.courses
      return nextState
    case RECEIVE_MORE_COURSES:
      return state.concat(action.courses)
    default:
      return state
  }
}

export const SuggestionReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_SUGGESTION_COURSES:
      nextState = action.suggestions
      return nextState
    default:
      return state
  }
}

export const SimilarReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_SIMILAR_COURSES:
      nextState = action.similar
      return nextState
    default:
      return state
  }
}

export const ListCourseReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)
  switch (action.type) {
    case RECEIVE_COURSES:
      nextState.courses = action.courses
      return nextState
    case REMOVE_COURSE:
      delete nextState[action.courseId]
      return nextState
    default:
      return state
  }
}

import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import defaultImage from '../../assets/default-image.png'
import './search.css'
import * as CommonUtils from '../../utils/CommonUtils'

class SearchCourseItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      learnBox: 'search-learn-box'
    }

    this.openClassName = this.openClassName.bind(this)
    this.closeClassName = this.closeClassName.bind(this)
  }

  openClassName () {
    this.setState({
      learnBox: 'search-learn-box-open'
    })
  }

  closeClassName () {
    this.setState({
      learnBox: 'search-learn-box'
    })
  }

  render () {
    const { t } = this.props
    let description = this.props.course.audience
    description = description ? description : this.props.course.learningGoal
    description = description ? description : this.props.course.description
    description = description && description.length > 500 ? description.substr(0, 500) + ' ...' : description
    const image = this.props.course.image ? this.props.course.image : defaultImage
    return (
      <div>
        <Link to={`/courses/${this.props.course.url}`} style={{ textDecoration: 'none' }} className="search-feed">
          <div className="search-detail-content">
            <img src={image} className="search-img"/>
            <div className="search-box-detail">
              <div className="search-box-title">{this.props.course.title}</div>
              <div className="search-box-audience">{description}</div>
            </div>
          </div>
          <div className="search-rating-info">
            <div className="search-rating-span">{CommonUtils.getPrice(this.props.course.price)}</div>
            <div style={{ paddingTop: '10px' }}>
              <span className="search-rating-count">{CommonUtils.getRating(this.props.course.rating)}</span>
            </div>
            <div className="search-rating-count">({this.props.course.ratingNumber} {t('label.ratings')})</div>
          </div>
        </Link>
      </div>
    )
  }
}

export default withTranslation()(SearchCourseItem)

import React from 'react'
import { withTranslation } from 'react-i18next'
import './table.css'
import PagingNumber from './PagingNumber'

class PagingDataTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    if (!this.props.items) {
      return null
    }

    return (
      <div className="multiple-line-box">
        <div className="multiple-line-item">
          {this.props.items}
        </div>
        <PagingNumber currentPage={this.props.currentPage} totalPage={this.props.totalPage}
                      onChangePage={this.props.onChangePage}/>
      </div>
    )
  }
}

export default withTranslation()(PagingDataTable)

import * as APIUtil from '../apis/CategoryApi'

export const RECEIVE_ALL_CATEGORIES = 'RECEIVE_ALL_CATEGORIES'
export const RECEIVE_CATEGORY = 'RECEIVE_CATEGORY'
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY'

const receiveAllCategories = categories => ({
  type: RECEIVE_ALL_CATEGORIES,
  categories
})

const receiveCategory = category => ({
  type: RECEIVE_CATEGORY,
  category
})

const removeCategory = categoryId => ({
  type: REMOVE_CATEGORY,
  categoryId
})

export const fetchAllCategories = () => dispatch => (
  APIUtil.fetchAllCategories()
    .then(categories => dispatch(receiveAllCategories(categories.data)))
)

export const fetchTopCategories = () => dispatch => (
  APIUtil.fetchTopCategories()
    .then(categories => dispatch(receiveAllCategories(categories.data)))
)

export const fetchCategory = categoryId => dispatch => (
  APIUtil.fetchCategory(categoryId)
    .then(category => dispatch(receiveCategory(category.data)))
)

export const createCategory = category => dispatch => (
  APIUtil.createCategory(category)
    .then(response => dispatch(receiveCategory(response.data)))
)

export const editCategory = (categoryId, category) => dispatch => (
  APIUtil.editCategory(categoryId, category)
    .then(response => dispatch(receiveCategory(response.data)))
)

export const deleteCategory = categoryId => dispatch => (
  APIUtil.deleteCategory(categoryId)
    .then(() => dispatch(removeCategory(categoryId)))
)

import React from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Slideshow from '../slideshow/Slideshow'

class InstituteHeading extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    if (!this.props.institute) {
      return null
    }

    const banners = this.props.institute.banners.map((x, i) => {
      return { src: x, caption: '' }
    })
    return (
      <div className="heading_box">
        <Slideshow input={banners} height={`200`} mode={`automatic`} timeout={`5000`}/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  session: state.session
})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InstituteHeading)))

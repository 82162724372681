import React from 'react'
import { withTranslation } from 'react-i18next'
import './table.css'

class MultipleLineTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const { t } = this.props
    return (
      <div className="multiple-line-box">
        <div className="multiple-line-item">
          {this.props.items}
        </div>
        <div className="carousel-rel-wrapper">
          {this.props.more ?
            <button onClick={this.props.onSeeMore} className="submit-institute-button">
              {t('See More')}
            </button>
            : this.props.onExploreAll ?
              <button onClick={this.props.onExploreAll} className="submit-institute-button">
                {t('Explore All')}
              </button>
              : null}
        </div>
      </div>
    )
  }
}

export default withTranslation()(MultipleLineTable)

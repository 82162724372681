import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { TABLE_DATA_TYPE } from '../../../../utils/Contants'
import { deleteMachine, filterMachines } from '../../../../services/MachineService'
import ReactActionTable from '../../../../components/table/ReactActionTable'
import PagingNumber from '../../../../components/table/PagingNumber'

class MachineManagement extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      order: 0,
      size: 15,
      filter: ''
    }
  }

  componentDidMount () {
    this.search()
  }

  editItem (machineId) {
    this.props.history.push(`/admin/machines/update/${machineId}`)
  }

  deleteItem (machineId) {
    this.props.deleteMachine(machineId).then(() => {
      this.search()
    })
  }

  createMachine () {
    this.props.history.push(`/admin/machines`)
  }

  handleClickPage (page) {
    this.setState({
      currentPage: page
    }, () => {
      this.search()
    })
  }

  search () {
    const request = {
      value: this.state.filter,
      page: this.state.currentPage - 1,
      size: this.state.size
    }
    this.props.filterMachines(request)
  }

  render () {
    const { t } = this.props
    if (this.props.machines.length === 0) {
      return (
        <div className="heading-creation-box">
          {t('Machine Management')}
        </div>
      )
    }

    return (
      <div>
        <div className="heading-creation-box">
          {t('Machine Management')}
        </div>
        <div>
          <div>
            <button onClick={this.createMachine.bind(this)} className="create-button">
              {t('management.machine.title.create')}
            </button>
            <button onClick={this.search.bind(this)} className="create-button">
              {t('management.machine.title.refresh')}
            </button>
          </div>
          <ReactActionTable
            onEditItem={this.editItem.bind(this)}
            onDeleteItem={this.deleteItem.bind(this)}
            data={this.props.machines[0]?.data}
            columns={[
              {
                Header: t('management.machine.column.name'),
                accessor: 'name',
                minWidth: 50,
                type: TABLE_DATA_TYPE.TEXT_TYPE
              },
              {
                Header: t('management.machine.column.description'),
                accessor: 'description',
                minWidth: 50,
                type: TABLE_DATA_TYPE.TEXT_TYPE
              },
              {
                Header: t('management.machine.column.os'),
                accessor: 'os.type',
                minWidth: 50,
                type: TABLE_DATA_TYPE.TEXT_TYPE
              },
              {
                Header: t('management.machine.column.network'),
                accessor: 'network',
                minWidth: 50,
                Cell: ({ row }) => (`${row?.original?.network?.privateIp}/${row?.original?.network?.publicIp}`)
              },
              {
                Header: t('management.machine.column.container'),
                accessor: 'limit',
                minWidth: 50,
                Cell: ({ row }) => (`${row?.original?.limit?.current}/${row?.original?.limit?.max}`)
              }
            ]}
          />
          <PagingNumber currentPage={this.state.currentPage} totalPage={this.props.machines[0].totalPage}
                        onChangePage={this.handleClickPage.bind(this)}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  machines: Object.values(state.machines),
})

const mapDispatchToProps = dispatch => ({
  filterMachines: (query) => dispatch(filterMachines(query)),
  deleteMachine: (machineId) => dispatch(deleteMachine(machineId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MachineManagement)))

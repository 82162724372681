import React from 'react'
import { withTranslation } from 'react-i18next'
import defaultImage from '../../assets/default-image.png'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ClassPathIndicator from '../common/ClassPathIndicator'

class MyClassHeading extends React.Component {
  constructor (props) {
    super(props)
  }

  editClass () {
    this.props.history.push(`/institute/classes/update/${this.props.class.id}`)
  }

  renderAction () {
    const { t } = this.props
    if (this.props.class.ownerId === this.props.session.id) {
      return (
        <div>
          <button onClick={this.editClass.bind(this)} className="add-class-button">
            {t('EDIT')}
          </button>
        </div>
      )
    }
  }

  render () {
    if (!this.props.class) {
      return null
    }

    const image = this.props.class.banner ? this.props.class.banner : defaultImage
    const backImg = {
      backgroundImage: `url('${image}')`,
      backgroundPosition: 'top',
      backgroundRepeat: 'noRepeat',
      backgroundSize: 'cover',
    }
    return (
      <div className="heading_box" style={backImg}>
        <div>
          <ClassPathIndicator className="index-rating-span" data={this.props.class}/>
          <div className="class-name">{this.props.class.name}</div>
          <div className="class-description">{this.props.class.description}</div>
          {this.renderAction()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  session: state.session
})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyClassHeading)))

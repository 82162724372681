import * as API from './API'

export const fetchAllComments = (query) => (
  API.post(`comments/filter`, query)
)

export const createComment = (comment) => (
  API.post(`comments`, comment)
)

export const updateComment = (comment, id) => (
  API.put(`comments/${id}`, comment)
)

export const likeComment = (id) => (
  API.post(`comments/${id}/like`)
)

export const deleteComment = (id) => (
  API.remove(`comments/${id}`)
)

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import './institute.css'

class BecomeInstructor extends React.Component {
  constructor (props) {
    super(props)
  }

  submitHandle () {
    if (this.props.session.id === null) {
      this.props.history.push('/login')
    } else {
      this.props.history.push('/institute/introduction')
    }
  }

  render () {
    const { t } = this.props
    return (
      <div>
        <div className="heading-creation-box">
          {t('institute.title')}
        </div>
        <div className="institute-heading-box">
          <div className="main-container">
            <div>
              {t('institute.description')}
            </div>
            <div>
              <button onClick={this.submitHandle.bind(this)} className="submit-button">
                {t('Become an institute')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  session: state.session
})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BecomeInstructor)))

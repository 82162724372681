import React from 'react'
import banner from '../../assets/banner.jpeg'
import { withTranslation } from 'react-i18next'
import './search.css'

const backImgLinks = [
  banner
]
const backImg = {
  backgroundImage: `url('${backImgLinks[Math.floor(Math.random() * backImgLinks.length)]}')`,
  backgroundPosition: 'top',
  backgroundRepeat: 'noRepeat',
  backgroundsize: 'cover'
}

class SearchPanel extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      searchString: ''
    }
    this.getSearchInput = this.getSearchInput.bind(this)
    this.searchClickHandle = this.searchClickHandle.bind(this)

  }

  getSearchInput (e) {
    if (e.key === 'Enter') {
      this.searchClickHandle()
    } else {
      this.setState({
        searchString: e.target.value
      })
    }
  }

  searchClickHandle () {
    this.props.history.push(`/courses/search/${this.state.searchString}`)
  }

  render () {
    const { t } = this.props
    return (
      <div className="home-search-root" style={backImg}>
        <div className="home-search-box">
          <div className="carousel-fullscreen-sidebar">
            <div className="notice-streamer__content">
              <div className="notice-streamer__headline">
                {t('home.headLine')}
              </div>
              <div className="notice-streamer__text">
                {t('home.text')}
              </div>
            </div>
          </div>
        </div>
        <div className="home-search-footer">
          <div className="home-footer-section">
            <b className="home-footer-section-header">{t('home.section.header1')}</b>
            <div className="home-footer-section-text">
              {t('home.section.text1')}
            </div>
          </div>
          <div className="home-footer-section">
            <b className="home-footer-section-header">{t('home.section.header2')}</b>
            <div className="home-footer-section-text">
              {t('home.section.text2')}
            </div>
          </div>
          <div className="home-footer-section">
            <b className="home-footer-section-header">{t('home.section.header3')}</b>
            <div className="home-footer-section-text">
              {t('home.section.text3')}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(SearchPanel)

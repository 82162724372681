import * as API from './API'

export const fetchBestCourses = (page, size) => (
  API.get(`courses/best?page=${page}&size=${size}`)
)

export const fetchSimilarCourses = (courseId) => (
  API.get(`courses/similar?courseId=${courseId}`)
)

export const fetchSuggestionCourses = () => (
  API.get(`courses/suggestion`)
)

export const filterCourses = (query) => (
  API.post(`courses/filter`, query)
)

export const fetchCourses = (query, field, sort, order, page, size) => {
  let url = `courses?`
  url = query ? `${url}&value=${query}` : url
  url = field ? `${url}&field=${field}` : url
  url = sort ? `${url}&sort=${sort}` : url
  url = order ? `${url}&order=${order}` : url
  url = page ? `${url}&page=${page}` : url
  url = size ? `${url}&size=${size}` : url
  return API.get(url)
}

export const fetchOwnerCourses = (query, field, sort, order, page, size) => {
  let url = `courses/owner?`
  url = query ? `${url}&value=${query}` : url
  url = field ? `${url}&field=${field}` : url
  url = sort ? `${url}&sort=${sort}` : url
  url = order ? `${url}&order=${order}` : url
  url = page ? `${url}&page=${page}` : url
  url = size ? `${url}&size=${size}` : url
  return API.get(url)
}

export const fetchCourse = courseId => (
  API.get(`courses/${courseId}`)
)

export const fetchCourseByUrl = url => (
  API.get(`courses/path?url=${url}`)
)

export const createCourse = course => (
  API.post(`courses`, course)
)

export const updateCourse = (courseId, course) => (
  API.put(`courses/${courseId}`, course)
)

export const deleteCourse = courseId => (
  API.remove(`courses/${courseId}`)
)

export const viewCourse = courseId => (
  API.post(`courses/view/${courseId}`)
)

export const fetchReviewCourses = (query) => (
  API.post(`courses/review`, query)
)

export const reviewCourse = (courseId, data) => (
  API.post(`courses/review/${courseId}`, data)
)

import * as APIUtil from '../apis/FeedbackApi'

export const RECEIVE_ALL_FEEDBACKS = 'RECEIVE_ALL_FEEDBACKS'

const receiveAllFeedbacks = feedbacks => ({
  type: RECEIVE_ALL_FEEDBACKS,
  feedbacks
})

export const fetchAllFeedbacks = (query) => dispatch => (
  APIUtil.fetchAllFeedbacks(query)
    .then(comments => dispatch(receiveAllFeedbacks(comments.data)))
)

export const createFeedback = (data) => dispatch => (
  APIUtil.createFeedback(data)
)

import React from 'react'
import { withTranslation } from 'react-i18next'
import './classes.css'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { createMeeting } from '../../services/MeetingService'

class MyClassMeeting extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  createMeeting () {
    const meeting = {
      classId: this.props.class.id
    }

    this.props.createMeeting(meeting)
      .then(() => {
        this.props.history.push(`/learning/class/meeting/${this.props.class.url}`)
      })
  }

  joinMeeting () {
    this.props.history.push(`/learning/class/meeting/${this.props.class.url}`)
  }

  render () {
    const { t } = this.props
    return (
      <div className="class-box-container">
        <div className="class-item-header">{t('label.classMeeting')}</div>
        <div className="button-panel">
          {this.props.class.ownerId === this.props.session.id ? (
            <button className="class-meeting-button" onClick={this.createMeeting.bind(this)}>
              {t('CREATE MEETING')}
            </button>
          ) : (
            <button className="class-meeting-button" onClick={this.joinMeeting.bind(this)}>
              {t('JOIN MEETING')}
            </button>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  session: state.session
})

const mapDispatchToProps = dispatch => ({
  createMeeting: (meeting) => dispatch(createMeeting(meeting)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyClassMeeting)))

import * as API from './API'

export const fetchAllReviews = (courseId, offset = 0) => (
  API.get(`reviews?courseId=${courseId}&offset=${offset}`)
)

export const createReview = (review) => (
  API.post(`reviews`, review)
)

export const updateReview = (review, reviewId) => (
  API.put(`reviews/${reviewId}`, review)
)

export const deleteReview = (reviewId) => (
  API.remove(`reviews/${reviewId}`)
)

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import './tool.css'
import CodeDiffEditor from '../../components/ide/CodeDiffEditor'

class CodeDiffEditorTool extends React.Component {
  constructor () {
    super()
  }

  render () {
    return (
      <div>
       <CodeDiffEditor />
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CodeDiffEditorTool)))

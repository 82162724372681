import { RECEIVE_CLIENT, REMOVE_CLIENT } from '../services/ClientService'

export const ClientReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_CLIENT:
      nextState[action.data.id] = action.data
      return nextState
    case REMOVE_CLIENT:
      delete nextState[action.clientId]
      return nextState
    default:
      return state
  }
}

export default ClientReducer

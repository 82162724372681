import * as APIUtil from '../apis/ClassApi'

export const RECEIVE_OWNER_CLASSES = 'RECEIVE_OWNER_CLASSES'
export const RECEIVE_BEST_CLASSES = 'RECEIVE_BEST_CLASSES'
export const RECEIVE_MORE_BEST_CLASSES = 'RECEIVE_MORE_BEST_CLASSES'
export const RECEIVE_SIMILAR_CLASSES = 'RECEIVE_SIMILAR_CLASSES'
export const RECEIVE_REVIEW_CLASSES = 'RECEIVE_REVIEW_CLASSES'
export const RECEIVE_INSTRUCTOR_CLASSES = 'RECEIVE_INSTRUCTOR_CLASSES'
export const RECEIVE_MORE_INSTRUCTOR_CLASSES = 'RECEIVE_MORE_INSTRUCTOR_CLASSES'
export const RECEIVE_CLASS = 'RECEIVE_CLASS'
export const REMOVE_CLASS = 'REMOVE_CLASS'

const receiveBestClasses = classes => ({
  type: RECEIVE_BEST_CLASSES,
  classes
})

const receiveMoreBestClasses = classes => ({
  type: RECEIVE_MORE_BEST_CLASSES,
  classes
})

const receiveOwnerClasses = classes => ({
  type: RECEIVE_OWNER_CLASSES,
  classes
})

const receiveSimilarClass = similar => ({
  type: RECEIVE_SIMILAR_CLASSES,
  similar
})

const receiveReviewClass = classes => ({
  type: RECEIVE_REVIEW_CLASSES,
  classes
})

const receiveInstructorClasses = classes => ({
  type: RECEIVE_INSTRUCTOR_CLASSES,
  classes
})

const receiveMoreInstructorClasses = classes => ({
  type: RECEIVE_MORE_INSTRUCTOR_CLASSES,
  classes
})

const receiveClass = data => ({
  type: RECEIVE_CLASS,
  data
})

const removeClass = classId => ({
  type: REMOVE_CLASS,
  classId
})

export const fetchOwnerClasses = () => dispatch => (
  APIUtil.fetchOwnerClasses()
    .then(response => dispatch(receiveOwnerClasses(response.data)))
)

export const fetchClass = classId => dispatch => (
  APIUtil.fetchClass(classId)
    .then(response => dispatch(receiveClass(response.data)))
)

export const createClass = data => dispatch => (
  APIUtil.createClass(data)
    .then(response => dispatch(receiveClass(response.data)))
)

export const editClass = (classId, data) => dispatch => (
  APIUtil.editClass(classId, data)
    .then(response => dispatch(receiveClass(response.data)))
)

export const deleteClass = classId => dispatch => (
  APIUtil.deleteClass(classId)
    .then(() => dispatch(removeClass(classId)))
)

export const fetchBestClasses = (page, size) => dispatch => (
  APIUtil.fetchBestClasses(page, size)
    .then(response => dispatch(receiveBestClasses(response.data)))
)

export const fetchMoreBestClasses = (page, size) => dispatch => (
  APIUtil.fetchBestClasses(page, size)
    .then(response => dispatch(receiveMoreBestClasses(response.data)))
)

export const filterClasses = (query) => dispatch => (
  APIUtil.filterClasses(query)
    .then(response => dispatch(receiveClass(response.data)))
)

export const fetchSimilarClasses = (courseId) => dispatch => (
  APIUtil.fetchSimilarClasses(courseId)
    .then(similar => dispatch(receiveSimilarClass(similar.data)))
)

export const fetchInstructorClasses = (query) => dispatch => (
  APIUtil.filterClasses(query)
    .then(response => dispatch(receiveInstructorClasses(response.data.data)))
)

export const fetchMoreInstructorClasses = (query) => dispatch => (
  APIUtil.filterClasses(query)
    .then(response => dispatch(receiveMoreInstructorClasses(response.data.data)))
)

export const fetchClassesByUrl = url => dispatch => (
  APIUtil.fetchClassesByUrl(url)
    .then(response => dispatch(receiveClass(response.data)))
)

export const joinClass = classId => dispatch => (
  APIUtil.joinClass(classId)
    .then(response => dispatch(receiveClass(response.data)))
)

export const startClass = classId => dispatch => (
  APIUtil.startClass(classId)
    .then(response => dispatch(receiveClass(response.data)))
)

export const endClass = classId => dispatch => (
  APIUtil.endClass(classId)
    .then(response => dispatch(receiveClass(response.data)))
)

export const reviewClass = (classId, data) => dispatch => (
  APIUtil.reviewClass(classId, data)
    .then(response => dispatch(receiveClass(response.data)))
)

export const fetchReviewClasses = (query) => dispatch => (
  APIUtil.fetchReviewClasses(query)
    .then(response => dispatch(receiveReviewClass(response.data)))
)

export const addInstructor = (classId, data) => dispatch => (
  APIUtil.addInstructor(classId, data)
)

export const fetchClassesByInstructor = () => dispatch => (
  APIUtil.fetchInstructorClasses()
    .then(response => dispatch(receiveOwnerClasses(response.data)))
)

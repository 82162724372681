import React from 'react'
import './footer.css'
import { withTranslation } from 'react-i18next'
import LanguageSelector from '../../components/common/LanguageSelector'

class Footer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      language: 'en'
    }
  }

  changeLanguage (e) {
    const { i18n } = this.props
    i18n.changeLanguage(e.target.value)
    this.setState({
      language: e.target.value
    })
  }

  render () {
    const { t } = this.props
    return (
      <div className="footer-box">
        <div className="footer-copyright">
          {t('copyright')}
        </div>
        <div className="footer-language">
          <LanguageSelector value={this.state.language} onChange={this.changeLanguage.bind(this)}/>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Footer)

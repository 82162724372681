import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { createCourse, fetchCourse, updateCourse } from '../../../services/CourseService'
import { fetchAllCategories } from '../../../services/CategoryService'
import { fetchAllTopics } from '../../../services/TopicService'
import '../../institute/institute.css'
import FileUpload from '../../../components/file/FileUpload'
import { DOC_TYPE_IMAGE } from '../../../utils/Contants'
import { fetchContents } from '../../../services/ContentService'
import Editor from '../../../components/editor/Editor'
import ContentVideoItem from '../../../components/content/ContentVideoItem'
import { withTranslation } from 'react-i18next'
import LanguageSelector from '../../../components/common/LanguageSelector'
import { Autocomplete } from '@material-ui/lab'
import { fetchInstructorNames } from '../../../services/InstituteService'
import { TextField } from '@material-ui/core'

class CourseCreationAdmin extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      categoryId: '',
      topicId: '',
      instructor: '',
      title: '',
      description: '',
      image: '',
      learningGoal: '',
      audience: '',
      language: '',
      status: '',
      contents: [
        {
          videos: [{}]
        }
      ],
      edit: false,
    }

    this.selectCategoryHandle = this.selectCategoryHandle.bind(this)
    this.selectTopicHandle = this.selectTopicHandle.bind(this)
    this.changeInstructor = this.changeInstructor.bind(this)
    this.changeTitle = this.changeTitle.bind(this)
    this.changeDescription = this.changeDescription.bind(this)
    this.changeLearningGoal = this.changeLearningGoal.bind(this)

    this.updateImage = this.updateImage.bind(this)
    this.updateState = this.updateState.bind(this)

    this.updateContent = this.updateContent.bind(this)
  }

  componentDidMount () {
    this.props.fetchAllCategories()
    this.props.fetchAllTopics(this.state.categoryId)
    this.props.fetchInstructorNames()
    this.updateState()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.match.params.courseId !== prevProps.match.params.courseId) {
      this.updateState()
    }
  }

  updateState () {
    const courseId = this.props.match.params.courseId
    if (courseId) {
      this.setState({
        edit: true
      })
      this.props.fetchCourse(courseId).then(response => {
        this.setState({
          categoryId: response.course.categoryId,
          topicId: response.course.topicId,
          title: response.course.title,
          description: response.course.description,
          learningGoal: response.course.learningGoal,
          audience: response.course.audience,
          language: response.course.language,
          image: response.course.image,
          status: response.course.status,
          instructor: response.course.instructor,
        })
        this.props.fetchContents(courseId).then((res) => {
          this.setState({
            contents: res.contents
          })
        })
      })
    } else {
      this.setState({
        categoryId: '',
        topicId: '',
        title: '',
        description: '',
        image: '',
        learningGoal: '',
        audience: '',
        language: '',
        status: '',
        contents: [
          {
            videos: [{}]
          }
        ],
        edit: false,
      })
    }
  }

  changeInstructor (e, value) {
    this.setState({
      instructor: value
    })
  }

  changeTitle (e) {
    this.setState({
      title: e.target.value
    })
  }

  changeDescription (content, delta, source, editor) {
    this.setState({
      description: content
    })
  }

  changeLearningGoal (e) {
    this.setState({
      learningGoal: e.target.value
    })
  }

  changeAudience (e) {
    this.setState({
      audience: e.target.value
    })
  }

  selectCategoryHandle (e) {
    this.setState({
      categoryId: e.target.value
    })
    this.props.fetchAllTopics(e.target.value)
  }

  selectTopicHandle (e) {
    this.setState({
      topicId: e.target.value
    })
  }

  selectLanguageHandle (e) {
    this.setState({
      language: e.target.value
    })
  }

  updateImage (fileId) {
    this.setState({
      image: fileId
    })
  }

  updateContent (contents) {
    this.setState({
      contents: contents
    })
  }

  submitHandle (status) {
    const course = {
      id: this.props.match.params.courseId,
      categoryId: this.state.categoryId,
      topicId: this.state.topicId,
      instructor: this.state.instructor,
      title: this.state.title,
      image: this.state.image,
      description: this.state.description,
      learningGoal: this.state.learningGoal,
      audience: this.state.audience,
      language: this.state.language,
      subtitle: '',
      price: 0,
      contents: this.state.contents,
      status: status === '' ? null : status
    }

    const courseId = this.props.match.params.courseId
    if (courseId) {
      this.props.updateCourse(courseId, course).then(() => {
        this.props.history.push('/admin/course/list')
      })
    } else {
      this.props.createCourse(course).then(() => {
        this.props.history.push('/admin/course/list')
      })
    }
  }

  renderButton () {
    const { t } = this.props
    if (this.state.edit) {
      return (
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this, this.state.status)} className="submit-button">
            {t('SAVE')}
          </button>
          {this.state.status === 'DRAFT' ? (
            <button onClick={this.submitHandle.bind(this, 'SUBMITTED')} className="submit-button">
              {t('SUBMIT')}
            </button>
          ) : null
          }
        </div>
      )
    } else {
      return (
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this, 'DRAFT')} className="submit-button">
            {t('SAVE DRAFT')}
          </button>
          <button onClick={this.submitHandle.bind(this, 'SUBMITTED')} className="submit-button">
            {t('SUBMIT')}
          </button>
        </div>
      )
    }
  }

  render () {
    const { t } = this.props
    const categories = this.props.categories.map((category, i) => (
      <option key={i} value={category.id}>{t(`${category.name}`)}</option>
    ))
    const topics = this.props.topics.map((topic, i) => (
      <option key={i} value={topic.id}>{t(`${topic.name}`)}</option>
    ))
    return (
      <div>
        <div className="heading-creation-box">
          {t('management.course.creation')}
        </div>
        <div className="course-creation-item">
          <div className="input-box">
            <div className="course-creation-input-label">{t('label.category')}:</div>
            <select className="course-creation-input-select"
                    onChange={this.selectCategoryHandle} value={this.state.categoryId}>
              <option value="">{t('selection.default')}</option>
              {categories}
            </select>
          </div>
          <div className="input-box">
            <div className="course-creation-input-label">{t('label.topic')}:</div>
            <select className="course-creation-input-select" value={this.state.topicId}
                    onChange={this.selectTopicHandle}>
              <option value="">{t('selection.default')}</option>
              {topics}
            </select>
          </div>
          <div className="input-box">
            <div className="course-creation-input-label">{t('label.language')}:</div>
            <LanguageSelector value={this.state.language} onChange={this.selectLanguageHandle.bind(this)}/>
          </div>
        </div>
        <div className="course-creation-item">
          <span>{t('label.course.instructor')}:</span>
          <Autocomplete className="course-creation-input-text"
                        freeSolo
                        options={this.props.instructorName}
                        value={this.state.instructor || ''}
                        inputValue={this.state.instructor || ''}
                        onChange={this.changeInstructor}
                        onInputChange={this.changeInstructor}
                        renderInput={(params) => <TextField {...params}/>}
          />
        </div>
        <div className="course-creation-item">
          <span>{t('label.image')}:</span>
          <FileUpload docType={DOC_TYPE_IMAGE} onUpdateImage={this.updateImage}/>
        </div>
        <div className="course-creation-item">
          <span>{t('label.title')}:</span>
          <input className="course-creation-input-text" type="text" value={this.state.title}
                 onChange={this.changeTitle}/>
        </div>
        <div className="course-creation-item">
          <span>{t('label.course.goal')}:</span>
          <textarea className="learning-goals-textarea" value={this.state.learningGoal || ''}
                    onChange={this.changeLearningGoal}/>
        </div>
        <div className="course-creation-item">
          <div>{t('label.course.description')}:</div>
          <div>
            <Editor description={this.state.description} onChangeDescription={this.changeDescription}/>
          </div>
        </div>
        <div>
          <ContentVideoItem contents={this.state.contents} onUpdateContent={this.updateContent} isAdmin={true}/>
        </div>
        {this.renderButton()}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  topics: Object.values(state.topics),
  categories: Object.values(state.categories),
  course: state.course,
  instructorName: Object.values(state.instructorName)
})

const mapDispatchToProps = dispatch => ({
  createCourse: (course) => dispatch(createCourse(course)),
  fetchCourse: (courseId) => dispatch(fetchCourse(courseId)),
  updateCourse: (courseId, course) => dispatch(updateCourse(courseId, course)),
  fetchAllCategories: () => dispatch(fetchAllCategories()),
  fetchAllTopics: (categoryId) => dispatch(fetchAllTopics(categoryId)),
  fetchContents: (courseId) => dispatch(fetchContents(courseId)),
  fetchInstructorNames: () => dispatch(fetchInstructorNames()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CourseCreationAdmin)))

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { fetchAllTopics } from '../../../services/TopicService'
import { crawlingYoutube } from '../../../services/CrawlingService'
import './crawling.css'
import { fetchAllCategories } from '../../../services/CategoryService'

class CrawlingYoutube extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      categoryId: '',
      topicId: '',
      url: '',
      type: 'VIDEO'
    }

    this.changeType = this.changeType.bind(this)
  }

  componentDidMount () {
    this.props.fetchAllCategories()
    this.props.fetchAllTopics(this.state.categoryId)
  }

  changeUrl (e) {
    this.setState({
      url: e.target.value
    })
  }

  changeCategory (e) {
    this.setState({
      categoryId: e.target.value
    })
    this.props.fetchAllTopics(e.target.value)
  }

  changeTopic (e) {
    this.setState({
      topicId: e.target.value
    })
  }

  changeType (e) {
    this.setState({
      type: e.currentTarget.value
    })
  }

  submitHandle () {
    const request = {
      id: this.props.match.params.courseId,
      categoryId: this.state.categoryId,
      topicId: this.state.topicId,
      url: this.state.url,
      type: this.state.type
    }
    this.props.crawlingYoutube(request).then((response) => {
      this.setState({
        url: ''
      })
    })
  }

  render () {
    const { t } = this.props
    const categories = this.props.categories.map((category, i) => (
      <option key={i} value={category.id}>{t(`${category.name}`)}</option>
    ))
    const topics = this.props.topics.map((topic, i) => (
      <option key={i} value={topic.id}>{t(`${topic.name}`)}</option>
    ))
    return (
      <div>
        <div className="heading-creation-box">
          {t('management.crawling.title.youtube')}
        </div>
        <div className="course-creation-item">
          <div className="input-box">
            <div className="course-creation-input-label">{t('label.category')}:</div>
            <select className="course-creation-input-select"
                    onChange={this.changeCategory.bind(this)} value={this.state.categoryId}>
              <option value="">{t('selection.default')}</option>
              {categories}
            </select>
          </div>
          <div className="input-box">
            <div className="course-creation-input-label">{t('label.topic')}:</div>
            <select className="course-creation-input-select" value={this.state.topicId}
                    onChange={this.changeTopic.bind(this)}>
              <option value="">{t('selection.default')}</option>
              {topics}
            </select>
          </div>
          <div className="input-box">
            <div className="course-creation-input-label">{t('label.type')}:</div>
            <input className="crawling-type-item" type="radio" value="VIDEO" name="option"
                   checked={this.state.type === 'VIDEO'} onChange={this.changeType}/>Video
            <input className="crawling-type-item" type="radio" value="PLAYLIST" name="option"
                   checked={this.state.type === 'PLAYLIST'} onChange={this.changeType}/>Playlist
          </div>
        </div>
        <div className="course-creation-item">
          <span>{t('label.url')}:</span>
          <textarea className="url-textarea" value={this.state.url || ''} onChange={this.changeUrl.bind(this)}/>
        </div>
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this)} className="submit-button">
            {t('SUBMIT')}
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  topics: Object.values(state.topics),
  categories: Object.values(state.categories),
})

const mapDispatchToProps = dispatch => ({
  fetchAllCategories: () => dispatch(fetchAllCategories()),
  crawlingYoutube: (data) => dispatch(crawlingYoutube(data)),
  fetchAllTopics: (categoryId) => dispatch(fetchAllTopics(categoryId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CrawlingYoutube)))

import { RECEIVE_LABORATORY, RECEIVE_OWNER_LABORATORIES, REMOVE_LABORATORY } from '../services/LaboratoryService'

export const LaboratoryReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_OWNER_LABORATORIES:
      nextState.laboratories = action.laboratories
      return nextState
    case RECEIVE_LABORATORY:
      nextState[action.data.id] = action.data
      return nextState
    case REMOVE_LABORATORY:
      delete nextState[action.laboratoryId]
      return nextState
    default:
      return state
  }
}

export default LaboratoryReducer

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Languages from './languages.json'

class LanguageSelector extends Component {
  constructor (props) {
    super(props)
    this.state = {
      languages: [],
    }
  }

  componentDidMount () {
    let languages = []
    Object.keys(Languages).forEach((key, i) => (
      languages.push(<option key={i} value={key}>{Languages[key]}</option>)
    ))
    this.setState({
      languages: languages
    })
  }

  render () {
    return (
      <select className="course-creation-input-select"
              onChange={this.props.onChange} value={this.props.value}>
        {this.state.languages}
      </select>
    )
  }
}

export default withTranslation()(LanguageSelector)

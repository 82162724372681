import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import '../institute.css'
import { withTranslation } from 'react-i18next'
import PagingNumber from '../../../components/table/PagingNumber'
import ReactReviewTable from '../../../components/table/ReactReviewTable'
import { fetchReviewRegisters, reviewRegister } from '../../../services/RegisterService'

class ReviewRegister extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      order: 0,
      size: 15,
      status: 'SUBMITTED',
      filter: ''
    }
    this.search = this.search.bind(this)
  }

  componentDidMount () {
    this.search()
  }

  search () {
    const request = {
      value: this.state.filter,
      query: {
        status: this.state.status === '' ? null : this.state.status
      },
      page: this.state.currentPage - 1,
      size: this.state.size
    }
    this.props.fetchReviewRegisters(request)
  }

  approveItem (registerId) {
    const data = {
      'status': 'APPROVED'
    }
    this.props.reviewRegister(registerId, data).then(() => {
      this.search()
    })
  }

  rejectItem (registerId) {
    const data = {
      'status': 'REJECTED'
    }
    this.props.reviewRegister(registerId, data).then(() => {
      this.search()
    })
  }

  filterTable (filter) {
    this.setState({
      filter: filter
    }, () => this.search())
  }

  handleClickPage (page) {
    this.setState({
      currentPage: page
    }, () => {
      this.search()
    })
  }

  changeStatus (e) {
    this.setState({
      status: e.target.value
    }, () => this.search())
  }

  render () {
    const { t } = this.props
    const header = (
      <div className="heading-creation-box">
        {t('management.register.title.review')}
      </div>
    )
    if (this.props.registers.length === 0) {
      return header
    }

    this.props.registers[0].data.map((item, i) => {
      item['status'] = t(`${item['status']}`)
    })
    return (
      <div>
        {header}
        <div className="input-box">
          <div className="course-creation-input-label">{t('label.status')}:</div>
          <select className="course-creation-input-select" value={this.state.status}
                  onChange={this.changeStatus.bind(this)}>
            <option value="">{t('ALL')}</option>
            <option value="SUBMITTED">{t('SUBMIT')}</option>
            <option value="APPROVED">{t('APPROVE')}</option>
            <option value="REJECTED">{t('REJECT')}</option>
          </select>
        </div>
        <div>
          <ReactReviewTable
            onApproveItem={this.approveItem.bind(this)}
            onRejectItem={this.rejectItem.bind(this)}
            isFilter={true}
            onFilter={this.filterTable.bind(this)}
            data={this.props.registers[0].data}
            columns={[
              {
                Header: t('management.register.column.name'),
                accessor: 'userName',
                minWidth: 50,
              },
              {
                Header: t('management.register.column.phone'),
                accessor: 'phone',
                minWidth: 50
              },
              {
                Header: t('management.register.column.class'),
                accessor: 'className',
                minWidth: 50,
              },
              {
                Header: t('management.register.column.status'),
                accessor: 'status',
                minWidth: 50,
              },
              {
                Header: t('management.register.column.description'),
                accessor: 'description',
                minWidth: 300
              }
            ]}
          />
        </div>
        <PagingNumber currentPage={this.state.currentPage} totalPage={this.props.registers[0].totalPage}
                      onChangePage={this.handleClickPage.bind(this)}/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  registers: Object.values(state.registers),
})

const mapDispatchToProps = dispatch => ({
  fetchReviewRegisters: (query) => dispatch(fetchReviewRegisters(query)),
  reviewRegister: (id, data) => dispatch(reviewRegister(id, data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReviewRegister)))

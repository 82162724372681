import {
  RECEIVE_BEST_CLASSES,
  RECEIVE_CLASS,
  RECEIVE_INSTRUCTOR_CLASSES,
  RECEIVE_MORE_BEST_CLASSES,
  RECEIVE_MORE_INSTRUCTOR_CLASSES,
  RECEIVE_OWNER_CLASSES,
  RECEIVE_REVIEW_CLASSES,
  RECEIVE_SIMILAR_CLASSES,
  REMOVE_CLASS
} from '../services/ClassService'

export const ClassReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_OWNER_CLASSES:
      nextState = action.classes
      return nextState
    case RECEIVE_CLASS:
      nextState[action.data.id] = action.data
      return nextState
    case REMOVE_CLASS:
      delete nextState[action.classId]
      return nextState
    default:
      return state
  }
}

export const BestClassReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_BEST_CLASSES:
      nextState = action.classes
      return nextState
    case RECEIVE_MORE_BEST_CLASSES:
      return state.concat(action.classes)
    default:
      return state
  }
}

export const SimilarReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_SIMILAR_CLASSES:
      nextState = action.similar
      return nextState
    default:
      return state
  }
}

export const ReviewReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_REVIEW_CLASSES:
      nextState.classes = action.classes
      return nextState
    default:
      return state
  }
}

export const InstructorReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_INSTRUCTOR_CLASSES:
      nextState = action.classes
      return nextState
    case RECEIVE_MORE_INSTRUCTOR_CLASSES:
      return state.concat(action.classes)
    default:
      return state
  }
}

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import '../../institute/institute.css'
import { deleteCourse, fetchCourses } from '../../../services/CourseService'
import { withTranslation } from 'react-i18next'
import ReactActionTable from '../../../components/table/ReactActionTable'
import PagingNumber from '../../../components/table/PagingNumber'

class CourseListAdmin extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      order: 0,
      size: 20,
      filter: ''
    }
    this.editItem = this.editItem.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.handleClickPage = this.handleClickPage.bind(this)
  }

  componentDidMount () {
    this.search()
  }

  search () {
    this.props.fetchCourses(this.state.filter, '', '', this.state.order, this.state.currentPage - 1, this.state.size)
  }

  createNewCourse () {
    this.props.history.push('/admin/course/create')
  }

  editItem (courseId) {
    this.props.history.push(`/admin/course/update/${courseId}`)
  }

  deleteItem (courseId) {
    this.props.deleteCourse(courseId).then(() => {
      this.search()
    })
  }

  filterTable (filter) {
    this.setState({
      filter: filter
    }, () => this.search())
  }

  handleClickPage (page) {
    this.setState({
      currentPage: page
    }, () => {
      this.search()
    })
  }

  render () {
    const { t } = this.props
    const header = (
      <div className="heading-creation-box">
        {t('management.course.titleAll')}
      </div>
    )
    if (this.props.courses.length === 0) {
      return header
    }
    this.props.courses[0].data.map((item, i) => {
      item['categoryName'] = t(`${item['categoryName']}`)
      item['topicName'] = t(`${item['topicName']}`)
      item['status'] = t(`${item['status']}`)
    })
    return (
      <div>
        {header}
        <div>
          <button onClick={this.createNewCourse.bind(this)} className="create-button">
            {t('CREATE')}
          </button>
          <ReactActionTable
            onEditItem={this.editItem}
            onDeleteItem={this.deleteItem}
            isFilter={true}
            onFilter={this.filterTable.bind(this)}
            data={this.props.courses[0].data}
            columns={[
              {
                Header: t('management.course.column.category'),
                accessor: 'categoryName',
                minWidth: 50,
              },
              {
                Header: t('management.course.column.topic'),
                accessor: 'topicName',
                minWidth: 50
              },
              {
                Header: t('management.course.column.instructor'),
                accessor: 'instructor',
                minWidth: 50,
              },
              {
                Header: t('management.course.column.status'),
                accessor: 'status',
                minWidth: 50,
              },
              {
                Header: t('management.course.column.title'),
                accessor: 'title',
                minWidth: 300
              }
            ]}
          />
        </div>
        <PagingNumber currentPage={this.state.currentPage} totalPage={this.props.courses[0].totalPage}
                      onChangePage={this.handleClickPage}/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  courses: Object.values(state.courses),
})

const mapDispatchToProps = dispatch => ({
  fetchCourses: (query, field, sort, order, page, size) => dispatch(fetchCourses(query, field, sort, order, page, size)),
  deleteCourse: (courseId) => dispatch(deleteCourse(courseId))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CourseListAdmin)))

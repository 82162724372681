import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { TABLE_DATA_TYPE } from '../../../utils/Contants'
import { deleteLaboratory, filterLaboratories } from '../../../services/LaboratoryService'
import ReactActionTable from '../../../components/table/ReactActionTable'
import PagingNumber from '../../../components/table/PagingNumber'
import { coalesce } from '../../../utils/CommonUtils'

class LaboratoryManagement extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      order: 0,
      size: 15,
      status: 'SUBMITTED',
      filter: ''
    }
  }

  componentDidMount () {
    this.props.filterLaboratories({})
  }

  editItem (laboratoryId) {
    this.props.history.push(`/instructor/laboratories/update/${laboratoryId}`)
  }

  deleteItem (laboratoryId) {
    this.props.deleteLaboratory(laboratoryId).then(() => {
      this.props.filterLaboratories({})
    })
  }

  createLaboratory () {
    this.props.history.push(`/instructor/laboratories`)
  }

  previewItem (laboratoryId) {
    this.props.history.push(`/instructor/laboratories/preview/${laboratoryId}`)
  }

  handleClickPage (page) {
    this.setState({
      currentPage: page
    }, () => {
      this.search()
    })
  }

  search () {
    const request = {
      value: this.state.filter,
      query: {
        status: this.state.status === '' ? null : this.state.status
      },
      page: this.state.currentPage - 1,
      size: this.state.size
    }
    this.props.fetchOwnerLaboratories(request)
  }

  render () {
    const { t } = this.props
    if (this.props.laboratories.length === 0) {
      return (
        <div className="heading-creation-box">
          {t('Laboratory Management')}
        </div>
      )
    }

    return (
      <div>
        <div className="heading-creation-box">
          {t('Laboratory Management')}
        </div>
        <div>
          <div>
            <button onClick={this.createLaboratory.bind(this)} className="create-button">
              {t('management.laboratory.title.create')}
            </button>
            <button onClick={this.search.bind(this)} className="create-button">
              {t('management.laboratory.title.refresh')}
            </button>
          </div>
          <ReactActionTable
            onEditItem={this.editItem.bind(this)}
            onDeleteItem={this.deleteItem.bind(this)}
            onPreviewItem={this.previewItem.bind(this)}
            data={this.props.laboratories[0]?.data}
            columns={[
              {
                Header: t('management.laboratory.column.name'),
                accessor: 'name',
                minWidth: 50,
                type: TABLE_DATA_TYPE.TEXT_TYPE
              },
              {
                Header: t('management.laboratory.column.description'),
                accessor: 'description',
                minWidth: 50,
                type: TABLE_DATA_TYPE.TEXT_TYPE
              },
              {
                Header: t('management.laboratory.column.type'),
                minWidth: 50,
                Cell: ({ row }) => (`${coalesce(row?.original?.os?.distro, row?.original?.service?.type, row?.original?.language?.name)}`)
              },
              {
                Header: t('management.laboratory.column.connection'),
                accessor: 'connection',
                minWidth: 50,
                Cell: ({ row }) => (`${row?.original?.connection?.number}/${row?.original?.connection?.limit}`)
              }
            ]}
          />
          <PagingNumber currentPage={this.state.currentPage} totalPage={this.props.laboratories[0].totalPage}
                        onChangePage={this.handleClickPage.bind(this)}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  laboratories: Object.values(state.laboratories),
})

const mapDispatchToProps = dispatch => ({
  filterLaboratories: (query) => dispatch(filterLaboratories(query)),
  deleteLaboratory: (laboratoryId) => dispatch(deleteLaboratory(laboratoryId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LaboratoryManagement)))

import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import defaultImage from '../../assets/default-image.png'
import { isMobile } from '../../utils/ResponsiveUtil'

class CategoryItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      imgClassName: 'img-cover'
    }
    this.imgCoverShow = this.imgCoverShow.bind(this)
    this.imgCoverHidden = this.imgCoverHidden.bind(this)
  }

  imgCoverShow () {
    this.setState({
      imgClassName: 'img-cover-show'
    })
  }

  imgCoverHidden () {
    this.setState({
      imgClassName: 'img-cover'
    })
  }

  render () {
    const { t } = this.props
    const image = this.props.category.thumbnail ? this.props.category.thumbnail : defaultImage
    let content = (
      <Link to={`/courses/category/${this.props.category.url}`} className="category-card"
            style={{ textDecoration: 'none' }}>
        <div>
          <img className="category-image-card" src={image} alt={'Category Thumbnail'}/>
          <div className={this.state.imgClassName}/>
        </div>
        <div className="category-card-text">
          <div className="category-card-title">{t(`${this.props.category.name}`)}</div>
          <div className="category-card-course">{this.props.category.courseNumber} {t('label.item.course')}</div>
        </div>
      </Link>
    )
    if (isMobile()) {
      content = (
        <Link to={`/courses/category/${this.props.category.url}`}>
          <div className="category-card-title-block">{this.props.category.name}</div>
        </Link>
      )
    }
    return content
  }
}

export default withTranslation()(CategoryItem)

import React from 'react'
import ReactDOM from 'react-dom'
import Root from './pages/app/Root'
import configureStore from './store/ConfigureStore'
import './styles/application.css'
import './components/i18n/i18n'

document.addEventListener('DOMContentLoaded', () => {

  let store
  if (window.currentUser) {
    const preloadedState = {
      session: { id: window.currentUser.id },
      entities: {
        users: { [window.currentUser.id]: window.currentUser }
      }
    }
    store = configureStore(preloadedState)
    delete window.currentUser
  } else {
    store = configureStore()
  }

  const root = document.getElementById('root')
  ReactDOM.render(<Root store={store}/>, root)
})

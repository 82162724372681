import React from 'react'
import { withRouter } from 'react-router-dom'
import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fetchCategory } from '../../services/CategoryService'
import { fetchTopic } from '../../services/TopicService'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import PagingNumber from '../../components/table/PagingNumber'
import './classes.css'
import { isMobile } from '../../utils/ResponsiveUtil'
import { filterClasses } from '../../services/ClassService'
import SearchClassItem from '../../components/classes/SearchClassItem'
import ClassFilter from '../../components/filter/ClassFilter'

const SEARCH_BY_CATEGORY = 'categoryUrl'
const SEARCH_BY_TOPIC = 'topicUrl'

class SearchClass extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      size: 15,
      sort: 'rating',
      order: 0,
      query: {},
      showFilter: !isMobile(),
      field: this.props.location.pathname.startsWith('/classes/category/') ? SEARCH_BY_CATEGORY :
        this.props.location.pathname.startsWith('/classes/topic/') ? SEARCH_BY_TOPIC : ''
    }
    this.selectHandle = this.selectHandle.bind(this)
    this.handleClickPage = this.handleClickPage.bind(this)
    this.renderItem = this.renderItem.bind(this)
    this.filter = this.filter.bind(this)
  }

  componentDidMount () {
    this.search()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.match.params.searchString !== prevProps.match.params.searchString) {
      this.search()
    }
  }

  search () {
    let request = this.state.query
    request.sort = this.state.sort
    request.order = this.state.order
    request.page = this.state.currentPage - 1
    request.size = this.state.size
    this.props.filterClasses(request)
  }

  handleClickPage (page) {
    this.setState({
      currentPage: page
    }, () => {
      this.search()
    })
  }

  selectHandle (e) {
    let selected = e.target.value
    let sort = this.state.sort
    let order = this.state.order
    switch (selected) {
      case '1':
        sort = 'createdDate'
        order = 1
        break
      case '2':
        sort = 'rating'
        order = 1
        break
      case '3':
        sort = 'view'
        order = 1
        break
      case '4':
        sort = 'createdDate'
        order = 0
        break
      case '5':
        sort = 'rating'
        order = 0
        break
      case '6':
        sort = 'view'
        order = 0
        break
    }
    this.setState({
      sort: sort,
      order: order
    }, () => {
      this.search()
    })
  }

  filter (query) {
    this.setState({
      query: query
    }, () => this.search())
  }

  toggleFilter () {
    this.setState({
      showFilter: !this.state.showFilter
    })
  }

  renderItem () {
    return (
      <div className="search-box">
        {
          this.props.classes[0]?.data.map((data, i) => (
            <SearchClassItem class={data} key={i}/>
          ))
        }
      </div>
    )
  }

  render () {
    const { t } = this.props
    if (this.props.classes.length === 0) {
      return (
        <div className="search-error">
          <div className="search-error-text">{t('search.notFound')} {this.props.match.params.searchString}</div>
          <FontAwesomeIcon icon={faSearch} className="search-error-icon"/>
        </div>
      )
    }

    const query = this.props.match.params.searchString ? this.props.match.params.searchString : ''
    const searchTitle = `${this.props.classes[0].totalRecord} ${t('search.result')} ${query}`
    return (
      <div>
        <div className="search-header">
          <div className="search-header-inner">
            <div className="search-header-title">
              {searchTitle}
            </div>
            <div>
              <select onChange={this.selectHandle}>
                <option value="0">{t('selection.category.sort.select')}</option>
                <option value="1">{t('selection.category.sort.newest')}</option>
                <option value="2">{t('selection.category.sort.highestRatting')}</option>
                <option value="3">{t('selection.category.sort.highestView')}</option>
                <option value="4">{t('selection.category.sort.oldest')}</option>
                <option value="5">{t('selection.category.sort.lowestRating')}</option>
                <option value="6">{t('selection.category.sort.lowestView')}</option>
              </select>
            </div>
            <div>
              <button className="filter-button" onClick={this.toggleFilter.bind(this)}>
                {t('Filter')} <FontAwesomeIcon icon={faFilter}/>
              </button>
            </div>
          </div>
        </div>
        <div className="main-container">
          <div className="content-container">
            {this.state.showFilter ? <ClassFilter onFilter={this.filter}/> : null}
            <div>
              {this.renderItem()}
            </div>
          </div>
          <PagingNumber currentPage={this.state.currentPage} totalPage={this.props.classes[0].totalPage}
                        onChangePage={this.handleClickPage}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  classes: Object.values(state.classes),
  topics: Object.values(state.topics),
  categories: Object.values(state.categories),
})

const mapDisPatchToProps = dispatch => ({
  fetchCategory: (categoryId) => dispatch(fetchCategory(categoryId)),
  fetchTopic: (topicId) => dispatch(fetchTopic(topicId)),
  filterClasses: (query) => dispatch(filterClasses(query)),
})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(SearchClass)))

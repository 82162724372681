import * as APIUtil from '../apis/ContentApi'

export const RECEIVE_CONTENTS = 'RECEIVE_CONTENTS'

const receiveContents = contents => ({
  type: RECEIVE_CONTENTS,
  contents
})

export const fetchContents = courseId => dispatch => {
  return APIUtil.fetchContents(courseId)
    .then(contents => dispatch(receiveContents(contents.data)))
}

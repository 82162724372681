import * as APIUtil from '../apis/ClientApi'

export const RECEIVE_CLIENT = 'RECEIVE_CLIENT'
export const REMOVE_CLIENT = 'REMOVE_CLIENT'

const receiveClients = data => ({
  type: RECEIVE_CLIENT,
  data
})

const removeClient = clientId => ({
  type: REMOVE_CLIENT,
  clientId
})

export const fetchClient = clientId => dispatch => (
  APIUtil.fetchClient(clientId)
    .then(response => dispatch(receiveClients(response.data)))
)

export const createClient = data => dispatch => (
  APIUtil.createClient(data)
    .then(response => dispatch(receiveClients(response.data)))
)

export const editClient = (clientId, data) => dispatch => (
  APIUtil.editClient(clientId, data)
    .then(response => dispatch(receiveClients(response.data)))
)

export const deleteClient = clientId => dispatch => (
  APIUtil.deleteClient(clientId)
    .then(() => dispatch(removeClient(clientId)))
)

export const filterClients = (query) => dispatch => (
  APIUtil.filterClients(query)
    .then(response => dispatch(receiveClients(response.data)))
)

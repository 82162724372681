import React from 'react'
import ReactTable from './ReactTable'
import { withTranslation } from 'react-i18next'
import './table.css'

class ReactReviewTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      filter: ''
    }
  }

  changeFilter (e) {
    const filter = e.target.value
    this.setState({
      filter: e.target.value
    })
    this.props.onFilter(filter)
  }

  renderActionCell (row, value) {
    const { t } = this.props
    return (
      <div className="button-panel">
        {this.props.onApproveItem ?
          <a className="list-action-button"
             onClick={() => { this.props.onApproveItem(value) }}>{t('APPROVE')}</a> : null}
        {this.props.onRejectItem ?
          <a className="list-action-button" onClick={() => { this.props.onRejectItem(value) }}>{t('REJECT')}</a> : null}
        {this.props.onEditItem ?
          <a className="list-action-button" onClick={() => { this.props.onEditItem(value) }}>{t('EDIT')}</a> : null}
        {this.props.onDeleteItem ?
          <a className="list-action-button" onClick={() => { this.props.onDeleteItem(value) }}>{t('DELETE')}</a> : null}
      </div>
    )
  }

  render () {
    const { t } = this.props
    let columns = this.props.columns
    const actionColumn = {
      Header: t('Action'),
      id: 'button',
      accessor: 'id',
      minWidth: 50,
      Cell: ({ row, value }) => (this.renderActionCell(row, value))
    }
    columns.push(actionColumn)
    return (
      <div>
        {this.props.isFilter ? (
          <div className="table-filter-item">
            <span className="table-filter-label">{t('label.search')}:</span>
            <input className="table-filter-input-text" type="text" value={this.state.filter}
                   onChange={this.changeFilter.bind(this)}/>
          </div>
        ) : null
        }
        <ReactTable
          data={this.props.data}
          columns={columns}
        />
      </div>
    )
  }
}

export default withTranslation()(ReactReviewTable)

import React from 'react'
import defaultImage from '../../assets/user.png'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import './comment.css'
import { likeComment } from '../../services/CommentService'
import CommentBox from './CommentBox'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

class CommentItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      reply: false
    }
  }

  changeContent (e) {
    this.setState({
      content: e.target.value
    })
  }

  likeComment (commentId) {
    this.props.likeComment(commentId)
  }

  replyComment () {
    const reply = !this.state.reply
    this.setState({
      reply: reply
    })
  }

  render () {
    const { t } = this.props
    const avatar = this.props.comment.avatar ? this.props.comment.avatar : defaultImage
    const replies = this.props.comment.replies.map((item, index) => {
      const replyAvatar = item.avatar ? item.avatar : defaultImage
      return (
        <div key={item.id} className="comment-box-container">
          <div>
            <img className="comment-image" src={replyAvatar} alt=""/>
          </div>
          <div className="comment-content">
            <div className="comment-user-name">
              {item.userName}
              <div className="comment-item-time">
                {moment(item.createdDate).startOf('min').fromNow()}
              </div>
            </div>
            <div className="comment-content-detail">{item.content}</div>
            <div className="comment-action-container">
              <a className="comment-link-button" onClick={this.likeComment.bind(this, item.id)}>{t('Like')}</a>
              {item.like > 0 ? (
                <div className="comment-like-button">
                  <FontAwesomeIcon style={{ color: '#4267b2' }} icon={faThumbsUp}/> {item.like}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )
    })
    return (
      <div className="comment-box-container">
        <div>
          <img className="comment-image" src={avatar} alt=""/>
        </div>
        <div className="comment-content">
          <div className="comment-user-name">
            {this.props.comment.userName}
            <div className="comment-item-time">
              {moment(this.props.comment.createdDate).startOf('min').fromNow()}
            </div>
          </div>
          <div className="comment-content-detail">{this.props.comment.content}</div>
          <div className="comment-action-container">
            <a className="comment-link-button"
               onClick={this.likeComment.bind(this, this.props.comment.id)}>{t('Like')}</a>
            <a className="comment-link-button" onClick={this.replyComment.bind(this)}>{t('Reply')}</a>
            {this.props.comment.like > 0 ? (
              <div className="comment-like-button">
                <FontAwesomeIcon style={{ color: '#4267b2' }} icon={faThumbsUp}/> {this.props.comment.like}
              </div>
            ) : null}
          </div>
          {this.state.reply ? <CommentBox replyId={this.props.comment.id}
                                          onReply={this.replyComment.bind(this)}/> : null}
          {replies ? replies : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  session: state.session
})

const mapDispatchToProps = dispatch => ({
  likeComment: (commentId) => dispatch(likeComment(commentId))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CommentItem)))

import { RECEIVE_ALL_CATEGORIES, RECEIVE_CATEGORY, REMOVE_CATEGORY } from '../services/CategoryService'

const CategoryReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_ALL_CATEGORIES:
      nextState = action.categories
      return nextState
    case RECEIVE_CATEGORY:
      nextState[action.category.id] = action.category
      return nextState
    case REMOVE_CATEGORY:
      delete nextState[action.categoryId]
      return nextState
    default:
      return state
  }
}

export default CategoryReducer

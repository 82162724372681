import React from 'react'
import ReactTable from './ReactTable'
import { withTranslation } from 'react-i18next'
import FileUpload from '../file/FileUpload'
import { DOC_TYPE_IMAGE, TABLE_DATA_TYPE } from '../../utils/Contants'
import './table.css'

class ReactEditableTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      editRowId: null,
      editRowData: {},
    }

    this.editItem = this.editItem.bind(this)
    this.saveItem = this.saveItem.bind(this)
    this.cancelItem = this.cancelItem.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
  }

  editItem (column, row) {
    this.setState({
      editRowId: row.id,
      editRowData: row.values
    })
  }

  cancelItem (rowId) {
    this.setState({
      editRowId: null
    })
  }

  saveItem () {
    this.props.onSaveItem(this.state.editRowData.button, this.state.editRowData)
    this.setState({
      editRowId: null,
      editRowData: {}
    })
  }

  deleteItem (id) {
    this.props.onDeleteItem(id)
    this.setState({
      editRowId: null,
      editRowData: {}
    })
  }

  changeText (column, e) {
    let editRowData = this.state.editRowData
    editRowData[column.column.id] = e.target.value
    this.setState({
      editRowData: editRowData
    })
  }

  changeImage (fileId) {
    let editRowData = this.state.editRowData
    editRowData['image'] = fileId
    this.setState({
      editRowData: editRowData
    })
  }

  changeSelection (column, e) {
    let editRowData = this.state.editRowData
    editRowData[column.column.id] = e.target.value
    this.setState({
      editRowData: editRowData
    })
  }

  renderActionCell (column, row, value) {
    const { t } = this.props
    return (
      row.id === this.state.editRowId ? (
        <div className="button-panel">
          <a className="list-action-button" onClick={() => { this.saveItem(value) }}>{t('SAVE')}</a>
          <a className="list-action-button" onClick={() => { this.cancelItem() }}>{t('CANCEL')}</a>
        </div>
      ) : (
        <div className="button-panel">
          <a className="list-action-button" onClick={() => { this.editItem(column, row) }}>{t('EDIT')}</a>
          <a className="list-action-button" onClick={() => { this.deleteItem(value) }}>{t('DELETE')}</a>
        </div>
      )
    )
  }

  renderInputCell (cellInfo) {
    if (cellInfo.row.id !== this.state.editRowId) {
      return cellInfo.value
    }

    return (
      <input
        placeholder="type here"
        name="input"
        type="text"
        onChange={this.changeText.bind(this, cellInfo)}
        value={this.state.editRowData[cellInfo.column.id]}
      />
    )
  }

  renderDropdownCell (cellInfo) {
    const { t } = this.props
    if (cellInfo.row.id !== this.state.editRowId) {
      return cellInfo.value
    }

    let header = cellInfo.headers.find(h => h.id === cellInfo.column.id)
    let selectValues = header.selections.map((item, i) => (
      <option key={i} value={item.props.value}>{item.props.children}</option>
    ))
    return (
      <select className="course-creation-input-select"
              onChange={this.changeSelection.bind(this, cellInfo)} value={this.state.editRowData[cellInfo.column.id]}>
        <option value="">{t('selection.default')}</option>
        {selectValues}
      </select>
    )
  }

  renderUploadCell (cellInfo) {
    if (cellInfo.row.id !== this.state.editRowId) {
      return <img src={cellInfo.value} className="image-content-item" alt=""/>
    }

    return (
      <FileUpload docType={DOC_TYPE_IMAGE} onUpdateImage={this.changeImage.bind(this)}/>
    )
  }

  render () {
    const { t } = this.props
    let columns = this.props.columns
    columns.map((column, index) => {
      switch (column.type) {
        case TABLE_DATA_TYPE.TEXT_TYPE:
          column['Cell'] = this.renderInputCell.bind(this)
          break
        case TABLE_DATA_TYPE.DROPDOWN_TYPE:
          column['Cell'] = this.renderDropdownCell.bind(this)
          break
        case TABLE_DATA_TYPE.IMAGE_TYPE:
          column['Cell'] = this.renderUploadCell.bind(this)
          break
      }
    })
    const actionColumn = {
      Header: t('Action'),
      id: 'button',
      accessor: 'id',
      minWidth: 50,
      Cell: ({ column, row, value }) => (this.renderActionCell(column, row, value))
    }
    columns.push(actionColumn)
    return (
      <ReactTable
        data={this.props.data}
        columns={columns}
      />
    )
  }
}

export default withTranslation()(ReactEditableTable)

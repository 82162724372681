import React from 'react'
import { withTranslation } from 'react-i18next'

class ClassInfo extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    if (!this.props.class) {
      return null
    }

    return (
      <div
        className="ck ck-editor__main ck-blurred ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline"
        dangerouslySetInnerHTML={{ __html: this.props.class.content }}>
      </div>
    )
  }
}

export default withTranslation()(ClassInfo)

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import '../instructor/index.css'
import { withTranslation } from 'react-i18next'
import { addInstructor, deleteClass, fetchOwnerClasses } from '../../../services/ClassService'
import ClassManagementTable from '../../../components/table/ClassManagementTable'
import { fetchAllInstructors } from '../../../services/InstructorService'
import { TABLE_DATA_TYPE } from '../../../utils/Contants'

class ClassManagement extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    this.props.fetchOwnerClasses()
    this.props.fetchAllInstructors()
  }

  saveItem (classId, data) {
    this.props.addInstructor(classId, data)
  }

  deleteItem (id) {
    this.props.deleteClass(id).then(() => {
      this.props.fetchOwnerClasses()
    })
  }

  editItem (id) {
    this.props.history.push(`/institute/classes/update/${id}`)
  }

  createNewClass () {
    this.props.history.push('/institute/classes/create')
  }

  render () {
    const { t } = this.props
    const instructors = this.props.instructors.map((item, index) => {
      return {
        label: item.userName,
        value: item.userId
      }
    })
    return (
      <div>
        <div className="heading-creation-box">
          {t('Class Management')}
        </div>
        <div>
          <div>
            <button onClick={this.createNewClass.bind(this)} className="create-button">
              {t('CREATE')}
            </button>
          </div>
          <ClassManagementTable
            onSaveItem={this.saveItem.bind(this)}
            onEditItem={this.editItem.bind(this)}
            onDeleteItem={this.deleteItem.bind(this)}
            data={this.props.classes}
            selections={instructors}
            columns={[
              {
                Header: t('management.classes.column.name'),
                accessor: 'name',
                minWidth: 50,
              },
              {
                Header: t('management.classes.column.member'),
                accessor: 'memberNumber',
                minWidth: 50,
                type: TABLE_DATA_TYPE.TEXT_TOOLTIP,
              },
              {
                Header: t('management.classes.column.instructor'),
                accessor: 'instructors',
                minWidth: 50,
              }
            ]}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  classes: Object.values(state.classes),
  instructors: Object.values(state.instructors),
})

const mapDispatchToProps = dispatch => ({
  fetchOwnerClasses: () => dispatch(fetchOwnerClasses()),
  fetchAllInstructors: () => dispatch(fetchAllInstructors()),
  addInstructor: (classId, data) => dispatch(addInstructor(classId, data)),
  deleteClass: (classId) => dispatch(deleteClass(classId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClassManagement)))

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './process-file.css'
import { withTranslation } from 'react-i18next'
import FileUpload from './FileUpload'

class FileUploadOption extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      file: this.props.file,
      type: '0',
    }
    this.changeSelection = this.changeSelection.bind(this)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.file !== prevProps.file) {
      this.setState({
        file: this.props.file
      })
    }
  }

  changeSelection (e) {
    this.setState({
      type: e.currentTarget.value
    })
    this.updateResult(e.currentTarget.value)
  }

  changeFileUrl (e) {
    this.setState({
      file: e.currentTarget.value
    })
    this.updateResult(e.currentTarget.value)
  }

  updateResult (fileId) {
    this.props.onChangeFile(this.props.i, this.props.j, fileId)
  }

  updateVideo (i, j, fileId) {
    this.props.onChangeFile(i, j, fileId)
  }

  render () {
    return (
      <div>
        <div className="upload-option-box">
          <input className="upload-option-item" type="radio" value="0" name="option"
                 checked={this.state.type === '0'} onChange={this.changeSelection}/>URL
          <input className="upload-option-item" type="radio" value="1" name="option"
                 checked={this.state.type === '1'} onChange={this.changeSelection}/>Upload
        </div>
        <div>
          {this.state.type === '0' ?
            <input className="course-creation-input-text" type="text" value={this.state.file}
                   onChange={this.changeFileUrl.bind(this)}/>
            : <FileUpload async={true} docType={this.props.docType} i={this.props.i} j={this.props.j}
                          onUpdateVideo={this.updateVideo.bind(this)}/>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FileUploadOption)))

import React from 'react'
import { connect } from 'react-redux'
import { fetchClassesByUrl } from '../../services/ClassService'
import ClassHeading from '../../components/classes/ClassHeading'
import ClassInfo from '../../components/classes/ClassInfo'
import SimilarClass from './SimilarClass'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import CommentList from '../../components/comment/CommentList'

class ClassDetail extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      class: {}
    }
  }

  componentDidMount () {
    this.fetchData(this.props.match.params.classUrl)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.match.params.classUrl !== prevProps.match.params.classUrl) {
      this.fetchData(this.props.match.params.classUrl)
    }
  }

  fetchData (classUrl) {
    this.props.fetchClassesByUrl(classUrl).then((response) => {
      this.setState({
        class: response.data
      })
    })
  }

  render () {
    if (!this.state.class.id) {
      return null
    }

    return (
      <div className="app-container">
        <ClassHeading class={this.state.class}/>
        <div className="main-container">
          <ClassInfo class={this.state.class}/>
          <CommentList classId={this.state.class.id}/>
          <SimilarClass classId={this.state.class.id}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  classes: Object.values(state.classes),
})

const mapDisPatchToProps = dispatch => ({
  fetchClassesByUrl: (url) => dispatch(fetchClassesByUrl(url)),
})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(ClassDetail)))

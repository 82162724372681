import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './common.css'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class CoursePathIndicator extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const { t } = this.props
    return (
      <div className="path-indicator-box">
        <Link to={`/courses/category/${this.props.data.categoryUrl}`}>
          <span className="path-indicator-link">{t(`${this.props.data.categoryName}`)}</span>
        </Link>
        <span> <FontAwesomeIcon style={{ color: '#fff' }} icon={faAngleRight}/> </span>
        <Link to={`/courses/topic/${this.props.data.topicUrl}`}>
          <span className="path-indicator-link">{t(`${this.props.data.topicName}`)}</span>
        </Link>
      </div>
    )
  }
}

export default withTranslation()(CoursePathIndicator)

import React from 'react'
import ContentItem from './ContentItem'
import { withTranslation } from 'react-i18next'

class ContentIndex extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    const { t } = this.props
    if (!this.props.contents) {
      return null
    }
    const contentsDisplay = this.props.contents.map((item, i) => (
      <ContentItem content={item} key={i}/>
    ))
    return (
      <div>
        <div className="title-course">{t('label.item.contentIndex')}</div>
        <div className="course-contents-index-box">{contentsDisplay}</div>
      </div>
    )

  }
}

export default withTranslation()(ContentIndex)

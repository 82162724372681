import * as APIUtil from '../apis/CrawlingApi'

export const crawlingYoutube = (data) => dispatch => (
  APIUtil.crawlingYoutube(data)
)

export const fetchAllCrawling = (query) => dispatch => (
  APIUtil.fetchAllCrawling(query)
)

export const deleteCrawling = (id) => dispatch => (
  APIUtil.deleteCrawling(id)
)

export const deleteAllCrawling = () => dispatch => (
  APIUtil.deleteAllCrawling()
)

export const applyCrawling = (id, data) => dispatch => (
  APIUtil.applyCrawling(id, data)
)

export const applyMultipleCrawling = (data) => dispatch => (
  APIUtil.applyMultipleCrawling(data)
)

export const triggerCrawlingJob = (data) => dispatch => (
  APIUtil.triggerCrawlingJob(data)
)

export const getRunningJob = () => dispatch => (
  APIUtil.getRunningJob()
)

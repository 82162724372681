import * as APIUtil from '../apis/CommentApi'

export const RECEIVE_ALL_COMMENTS = 'RECEIVE_ALL_COMMENTS'
export const RECEIVE_COMMENT = 'RECEIVE_COMMENT'
export const REMOVE_COMMENT = 'REMOVE_COMMENT'

const receiveAllComments = comments => ({
  type: RECEIVE_ALL_COMMENTS,
  comments
})

const receiveComment = comment => ({
  type: RECEIVE_COMMENT,
  comment
})

const removeComment = commentId => ({
  type: REMOVE_COMMENT,
  commentId
})

export const fetchAllComments = (query) => dispatch => (
  APIUtil.fetchAllComments(query)
    .then(comments => dispatch(receiveAllComments(comments.data)))
)

export const createComment = (comment) => dispatch => (
  APIUtil.createComment(comment)
    .then(response => dispatch(receiveComment(response.data)))
)

export const updateComment = (comment, commentId) => dispatch => (
  APIUtil.updateComment(comment, commentId)
    .then(response => dispatch(receiveComment(response.data)))
)

export const likeComment = (commentId) => dispatch => (
  APIUtil.likeComment(commentId)
    .then(response => dispatch(receiveComment(response.data)))
)

export const deleteComment = (commentId) => dispatch => (
  APIUtil.deleteComment(commentId)
    .then(() => dispatch(removeComment(commentId)))
)

import * as API from './API'

export const fetchClient = clientId => (
  API.get(`labs/clients/${clientId}`)
)

export const createClient = (data) => (
  API.post(`labs/clients`, data)
)

export const editClient = (clientId, data) => (
  API.put(`labs/clients/${clientId}`, data)
)

export const deleteClient = (clientId) => (
  API.remove(`labs/clients/${clientId}`)
)

export const filterClients = (query) => (
  API.post(`labs/clients/filter`, query)
)

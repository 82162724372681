import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { addNotification } from '../../utils/CommonUtils'
import { createLaboratory, editLaboratory, fetchLaboratory } from '../../services/LaboratoryService'
import { fetchConfiguration } from '../../services/ConfigurationService'
import MultipleFileUpload from '../../components/file/MultipleFileUpload'
import { DOC_TYPE_WORKSPACE } from '../../utils/Contants'

class LaboratoryCreation extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      script: '',
      connection: {},
      os: {},
      service: {},
      language: {},
      port: {},
      client: {},
      project: {},
      status: '',
      edit: false,
    }
  }

  componentDidMount () {
    this.props.fetchConfiguration()
    this.updateState()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.match.params.laboratoryId !== prevProps.match.params.laboratoryId) {
      this.updateState()
    }
  }

  updateState () {
    const laboratoryId = this.props.match.params.laboratoryId
    if (laboratoryId) {
      this.setState({
        edit: true
      })
      this.props.fetchLaboratory(laboratoryId).then(response => {
        this.setState({
          name: response.data.name,
          script: response.data.script,
          description: response.data.description,
          connection: response.data.connection,
          os: response.data.os,
          service: response.data.service,
          language: response.data.language,
          client: response.data.client,
          port: response.data.port,
          project: response.data.project,
          status: response.data.status,
        })
      })
    } else {
      this.setState({
        name: '',
        script: '',
        description: '',
        connection: {},
        os: {},
        service: {},
        language: {},
        port: {},
        client: {},
        project: {},
        status: '',
        edit: false,
      })
    }
  }

  changeName (e) {
    this.setState({
      name: e.target.value
    })
  }

  changeConnection (e) {
    const connection = {
      limit: e.target.value
    }
    this.setState({
      connection: connection
    })
  }

  changeDescription (e) {
    this.setState({
      description: e.target.value
    })
  }

  changeScript (e) {
    this.setState({
      script: e.target.value
    })
  }

  changeServiceHandle (e) {
    const service = {
      type: e.target.value
    }
    this.setState({
      service: service,
      language: {},
      os: {}
    })
  }
  changeLanguageHandle (e) {
    const language = {
      name: e.target.value
    }
    this.setState({
      service: {},
      language: language,
      os: {}
    })
  }

  changeOsHandle (e) {
    const os = {
      distro: e.target.value,
    }
    this.setState({
      service: {},
      language: {},
      os: os
    })
  }

  changeClientHandle (e) {
    const client = {
      name: e.target.value
    }
    this.setState({
      client: client,
    })
  }

  submitHandle (status) {
    const { t } = this.props
    const data = {
      name: this.state.name,
      description: this.state.description,
      script: this.state.script,
      connection: this.state.connection,
      os: this.state.os,
      service: this.state.service,
      language: this.state.language,
      port: this.state.port,
      client: this.state.client,
      project: this.state.project,
      status: status === '' ? null : status,
    }
    const laboratoryId = this.props.match.params.laboratoryId
    if (laboratoryId) {
      this.props.editLaboratory(laboratoryId, data)
    } else {
      this.props.createLaboratory(data)
    }
    addNotification(t('notification.class.create'))
    this.props.history.push('/instructor/laboratories/management')
  }
  updateProject (fileId) {
    this.setState({
      project: {
        base: fileId[0]
      }
    })
  }

  renderButton () {
    const { t } = this.props
    if (this.state.edit) {
      return (
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this, '')} className="submit-button">
            {t('SAVE')}
          </button>
          {this.state.status === 'DRAFT' ? (
            <button onClick={this.submitHandle.bind(this, 'SUBMITTED')} className="submit-button">
              {t('SUBMIT')}
            </button>
          ) : null
          }
        </div>
      )
    } else {
      return (
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this, 'DRAFT')} className="submit-button">
            {t('SAVE DRAFT')}
          </button>
          <button onClick={this.submitHandle.bind(this, 'SUBMITTED')} className="submit-button">
            {t('SUBMIT')}
          </button>
        </div>
      )
    }
  }

  render () {
    const { t } = this.props
    const services = this.props.configuration?.services?.map((item, i) => (
      <option key={i} value={item}>{t(`${item}`)}</option>
    ))
    const languages = this.props.configuration?.languages?.map((item, i) => (
      <option key={i} value={item}>{t(`${item}`)}</option>
    ))
    const distros = this.props.configuration?.distros?.map((item, i) => (
      <option key={i} value={item}>{t(`${item}`)}</option>
    ))
    const clients = this.props.configuration?.clients?.map((item, i) => (
      <option key={i} value={item}>{t(`${item}`)}</option>
    ))

    return (
      <div>
        <div className="heading-creation-box">
          {t('management.laboratory.title.create')}
        </div>
        <div className="course-creation-item">
          <span>{t('management.laboratory.name')}:</span>
          <input className="course-creation-input-text" type="text" value={this.state.name}
                 onChange={this.changeName.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.laboratory.connection')}:</span>
          <input className="course-creation-input-text" type="text" value={this.state.connection.limit}
                 onChange={this.changeConnection.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.laboratory.description')}:</span>
          <textarea className="learning-goals-textarea" value={this.state.description || ''}
                    onChange={this.changeDescription.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.laboratory.script')}:</span>
          <textarea className="learning-goals-textarea" value={this.state.script || ''}
                    onChange={this.changeScript.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <div className="input-box">
            <div className="laboratory-creation-input-div">
              <div className="laboratory-creation-input-label">{t('management.laboratory.service')}:</div>
              <select className="course-creation-input-select"
                      onChange={this.changeServiceHandle.bind(this)} value={this.state.service.type}>
                <option value="">{t('selection.default')}</option>
                {services}
              </select>
            </div>
            <div className="laboratory-creation-input-div">
              <div className="laboratory-creation-input-label">{t('management.laboratory.language')}:</div>
              <select className="course-creation-input-select"
                      onChange={this.changeLanguageHandle.bind(this)} value={this.state.language?.name}>
                <option value="">{t('selection.default')}</option>
                {languages}
              </select>
            </div>
            <div className="laboratory-creation-input-div">
              <div className="laboratory-creation-input-label">{t('management.laboratory.client')}:</div>
              <select className="course-creation-input-select" value={this.state.client.name}
                      onChange={this.changeClientHandle.bind(this)}>
                <option value="">{t('selection.default')}</option>
                {clients}
              </select>
            </div>
          </div>
          <div className="input-box">
            <div className="laboratory-creation-input-label">{t('management.laboratory.os')}:</div>
            <select className="course-creation-input-select" value={this.state.os.distro}
                    onChange={this.changeOsHandle.bind(this)}>
              <option value="">{t('selection.default')}</option>
              {distros}
            </select>
          </div>
        </div>
        <div className="course-creation-item">
          <span>{t('management.laboratory.project')}:</span>
          <MultipleFileUpload docType={DOC_TYPE_WORKSPACE} directory={true} fileIds={this.state.project.base}
                              onUpdateMultipleFile={this.updateProject.bind(this)}/>
        </div>
        {this.renderButton()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  laboratories: Object.values(state.laboratories),
  configuration: state.configuration
})

const mapDispatchToProps = dispatch => ({
  createLaboratory: (data) => dispatch(createLaboratory(data)),
  editLaboratory: (laboratoryId, data) => dispatch(editLaboratory(laboratoryId, data)),
  fetchLaboratory: (laboratoryId) => dispatch(fetchLaboratory(laboratoryId)),
  fetchConfiguration: () => dispatch(fetchConfiguration()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LaboratoryCreation)))

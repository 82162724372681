import * as APIUtil from '../apis/ImageApi'

export const RECEIVE_IMAGE = 'RECEIVE_IMAGE'
export const REMOVE_IMAGE = 'REMOVE_IMAGE'

const receiveImages = data => ({
  type: RECEIVE_IMAGE,
  data
})

const removeImage = imageId => ({
  type: REMOVE_IMAGE,
  imageId
})

export const fetchImage = imageId => dispatch => (
  APIUtil.fetchImage(imageId)
    .then(response => dispatch(receiveImages(response.data)))
)

export const createImage = data => dispatch => (
  APIUtil.createImage(data)
    .then(response => dispatch(receiveImages(response.data)))
)

export const editImage = (imageId, data) => dispatch => (
  APIUtil.editImage(imageId, data)
    .then(response => dispatch(receiveImages(response.data)))
)

export const deleteImage = imageId => dispatch => (
  APIUtil.deleteImage(imageId)
    .then(() => dispatch(removeImage(imageId)))
)

export const filterImages = (query) => dispatch => (
  APIUtil.filterImages(query)
    .then(response => dispatch(receiveImages(response.data)))
)

export const buildImage = (imageId, data) => dispatch => (
  APIUtil.buildImage(imageId, data)
    .then(response => dispatch(receiveImages(response.data)))
)

import * as API from './API'

export const addCourse = (data) => (
  API.post('learnings/course', data)
)

export const fetchMyCourses = (query) => (
  API.post('learnings/course/filter', query)
)

export const fetchMyClasses = (query) => (
  API.post('learnings/class/filter', query)
)

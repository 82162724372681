import * as APIUtil from '../apis/LearningApi'

export const RECEIVE_MY_COURSES = 'RECEIVE_MY_COURSES'
export const RECEIVE_MY_CLASSES = 'RECEIVE_MY_CLASSES'

const receiveMyCourses = courses => ({
  type: RECEIVE_MY_COURSES,
  courses
})

const receiveMyClasses = classes => ({
  type: RECEIVE_MY_CLASSES,
  classes
})

export const addCourse = data => dispatch => (
  APIUtil.addCourse(data)
)

export const fetchMyCourses = (query) => dispatch => (
  APIUtil.fetchMyCourses(query)
    .then(response => dispatch(receiveMyCourses(response.data)))
)

export const fetchMyClasses = (query) => dispatch => (
  APIUtil.fetchMyClasses(query)
    .then(response => dispatch(receiveMyClasses(response.data)))
)

import * as APIUtil from '../apis/ConnectionApi'

export const RECEIVE_CONNECTION = 'RECEIVE_CONNECTION'
export const RECEIVE_CONNECTIONS = 'RECEIVE_CONNECTIONS'
export const REMOVE_CONNECTION = 'REMOVE_CONNECTION'

const receiveConnection = data => ({
  type: RECEIVE_CONNECTION,
  data
})

const receiveConnections = data => ({
  type: RECEIVE_CONNECTIONS,
  data
})

const removeConnection = connectionId => ({
  type: REMOVE_CONNECTION,
  connectionId
})

export const connectConnection = (request) => dispatch => (
  APIUtil.connectConnection(request)
    .then(response => dispatch(receiveConnection(response.data)))
)

export const disconnectConnection = (connectionId) => dispatch => (
    APIUtil.disconnectConnection(connectionId)
        .then(response => dispatch(receiveConnection(response.data)))
)

export const fetchConnection = (request) => dispatch => (
  APIUtil.fetchConnection(request)
    .then(response => dispatch(receiveConnection(response.data)))
)

export const filterConnections = (query) => dispatch => (
  APIUtil.filterConnections(query)
    .then(response => dispatch(receiveConnections(response.data)))
)

export const detailConnection = (connectionId) => dispatch => (
  APIUtil.detailConnection(connectionId)
    .then(response => dispatch(receiveConnections(response.data)))
)

export const releaseConnection = (connectionId) => dispatch => (
  APIUtil.releaseConnection(connectionId)
    .then(response => dispatch(removeConnection(response.data)))
)

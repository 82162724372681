import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { TABLE_DATA_TYPE } from '../../../../utils/Contants'
import { buildImage, deleteImage, filterImages } from '../../../../services/ImageService'
import ReactActionTable from '../../../../components/table/ReactActionTable'
import PagingNumber from '../../../../components/table/PagingNumber'
import { coalesce } from '../../../../utils/CommonUtils'

class ImageManagement extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      order: 0,
      size: 15,
      filter: ''
    }
  }

  componentDidMount () {
    this.search()
  }

  editItem (imageId) {
    this.props.history.push(`/admin/images/update/${imageId}`)
  }

  deleteItem (imageId) {
    this.props.deleteImage(imageId).then(() => {
      this.search()
    })
  }

  buildItem (imageId) {
    this.props.buildImage(imageId, {})
  }

  createImage () {
    this.props.history.push(`/admin/images`)
  }

  handleClickPage (page) {
    this.setState({
      currentPage: page
    }, () => {
      this.search()
    })
  }

  search () {
    const request = {
      value: this.state.filter,
      page: this.state.currentPage - 1,
      size: this.state.size
    }
    this.props.filterImages(request)
  }

  render () {
    const { t } = this.props
    if (this.props.images.length === 0) {
      return (
        <div className="heading-creation-box">
          {t('Image Management')}
        </div>
      )
    }

    return (
      <div>
        <div className="heading-creation-box">
          {t('Image Management')}
        </div>
        <div>
          <div>
            <button onClick={this.createImage.bind(this)} className="create-button">
              {t('management.image.title.create')}
            </button>
            <button onClick={this.search.bind(this)} className="create-button">
              {t('management.image.title.refresh')}
            </button>
          </div>
          <ReactActionTable
            onEditItem={this.editItem.bind(this)}
            onDeleteItem={this.deleteItem.bind(this)}
            onBuildItem={this.buildItem.bind(this)}
            data={this.props.images[0]?.data}
            columns={[
              {
                Header: t('management.image.column.name'),
                accessor: 'name',
                minWidth: 50,
                type: TABLE_DATA_TYPE.TEXT_TYPE
              },
              {
                Header: t('management.image.column.description'),
                accessor: 'description',
                minWidth: 50,
                type: TABLE_DATA_TYPE.TEXT_TYPE
              },
              {
                Header: t('management.image.column.type'),
                minWidth: 50,
                Cell: ({ row }) => (`${coalesce(row?.original?.os?.distro, row?.original?.service?.type, row?.original?.language?.name, row?.original?.client?.name)}`)
              },
              {
                Header: t('management.image.column.status'),
                accessor: 'status',
                minWidth: 50,
                type: TABLE_DATA_TYPE.TEXT_TYPE
              },
              {
                Header: t('management.image.column.port'),
                accessor: 'availablePorts',
                minWidth: 50,
                Cell: ({ row }) => (`${row?.original?.availablePorts.join(', ')}`)
              },
            ]}
          />
          <PagingNumber currentPage={this.state.currentPage} totalPage={this.props.images[0].totalPage}
                        onChangePage={this.handleClickPage.bind(this)}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  images: Object.values(state.images),
})

const mapDispatchToProps = dispatch => ({
  filterImages: (query) => dispatch(filterImages(query)),
  deleteImage: (imageId) => dispatch(deleteImage(imageId)),
  buildImage: (imageId, data) => dispatch(buildImage(imageId, data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ImageManagement)))

import React from 'react'
import { withTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { CALENDAR_WEEK } from '../../utils/Contants'
import './classes.css'

class ClassCalendar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      startDate: this.props.data?.startDate,
      endDate: this.props.data?.endDate,
      time: this.props.data?.time,
      days: this.props.data?.days ? this.props.data.days : []
    }
  }

  componentWillReceiveProps (nextProps) {
    this.setState({
      startDate: nextProps.data?.startDate,
      endDate: nextProps.data?.endDate,
      time: nextProps.data?.time,
      days: nextProps.data?.days ? nextProps.data.days : []
    })
  }

  changeStartDate (date) {
    this.setState({
      startDate: date
    }, () => this.onChange())
  }

  changeEndDate (date) {
    this.setState({
      endDate: date
    }, () => this.onChange())
  }

  changeTime (e) {
    this.setState({
      time: e.target.value
    }, () => this.onChange())
  }

  changeCalendar (selection) {
    let days = this.state.days
    if (days.includes(selection)) {
      days.splice(days.indexOf(selection), 1)
    } else {
      days.push(selection)
    }

    this.setState({
      days: days
    }, () => this.onChange())
  }

  onChange () {
    const data = {
      startDate: this.state.startDate?.getTime(),
      endDate: this.state.endDate?.getTime(),
      time: this.state.time,
      days: this.state.days,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    this.props.onChange(data)
  }

  render () {
    const { t } = this.props

    return (
      <div>
        <div className="class-calendar-item">
          <div>
            <div>{t('label.startDate')}:</div>
            <DatePicker className="course-creation-input-text" selected={this.state.startDate}
                        onChange={this.changeStartDate.bind(this)}/>
          </div>
          <div>
            <div>{t('label.endDate')}:</div>
            <DatePicker className="course-creation-input-text" selected={this.state.endDate}
                        onChange={this.changeEndDate.bind(this)}/>
          </div>
          <div>
            <div>{t('label.time')}</div>
            <input className="course-creation-input-text" type="text" value={this.state.time}
                   onChange={this.changeTime.bind(this)}/>
          </div>
        </div>
        <div className="class-calendar-day-box">
          <input type="checkbox" className="class-calendar-day-item"
                 checked={this.state.days.includes(CALENDAR_WEEK.MONDAY)}
                 onChange={this.changeCalendar.bind(this, CALENDAR_WEEK.MONDAY)}/> {t('Monday')}
          <input type="checkbox" className="class-calendar-day-item"
                 checked={this.state.days.includes(CALENDAR_WEEK.TUESDAY)}
                 onChange={this.changeCalendar.bind(this, CALENDAR_WEEK.TUESDAY)}/> {t('Tuesday')}
          <input type="checkbox" className="class-calendar-day-item"
                 checked={this.state.days.includes(CALENDAR_WEEK.WEDNESDAY)}
                 onChange={this.changeCalendar.bind(this, CALENDAR_WEEK.WEDNESDAY)}/> {t('Wednesday')}
          <input type="checkbox" className="class-calendar-day-item"
                 checked={this.state.days.includes(CALENDAR_WEEK.THURSDAY)}
                 onChange={this.changeCalendar.bind(this, CALENDAR_WEEK.THURSDAY)}/> {t('Thursday')}
          <input type="checkbox" className="class-calendar-day-item"
                 checked={this.state.days.includes(CALENDAR_WEEK.FRIDAY)}
                 onChange={this.changeCalendar.bind(this, CALENDAR_WEEK.FRIDAY)}/> {t('Friday')}
          <input type="checkbox" className="class-calendar-day-item"
                 checked={this.state.days.includes(CALENDAR_WEEK.SATURDAY)}
                 onChange={this.changeCalendar.bind(this, CALENDAR_WEEK.SATURDAY)}/> {t('Saturday')}
          <input type="checkbox" className="class-calendar-day-item"
                 checked={this.state.days.includes(CALENDAR_WEEK.SUNDAY)}
                 onChange={this.changeCalendar.bind(this, CALENDAR_WEEK.SUNDAY)}/> {t('Sunday')}
        </div>
      </div>
    )
  }
}

export default withTranslation()(ClassCalendar)

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { DOC_TYPE_DOCUMENT } from '../../utils/Contants'
import { createDocument } from '../../services/DocumentService'
import './document.css'
import MultipleFileUpload from '../file/MultipleFileUpload'

class DocumentBox extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      description: '',
      fileId: ''
    }
  }

  updateFile (fileId) {
    this.setState({
      fileId: fileId
    })
  }

  changeDescription (e) {
    this.setState({
      description: e.target.value
    })
  }

  submitDocument () {
    let document = {
      classId: this.props.classId,
      description: this.state.description,
      fileId: this.state.fileId,
    }
    this.props.createDocument(document)
  }

  render () {
    const { t } = this.props
    return (
      <div className="document-box-container">
        <div className="document-creation-item">
          <span>{t('label.document')}:</span>
          <MultipleFileUpload docType={DOC_TYPE_DOCUMENT} onUpdateFile={this.updateFile.bind(this)}/>
        </div>
        <div className="document-creation-item">
          <span>{t('label.description')}:</span>
          <textarea value={this.state.description || ''} onChange={this.changeDescription.bind(this)}/>
        </div>
        <div className="document-creation-item">
          <button className="comment-post-button" onClick={this.submitDocument.bind(this)}>{t('Post')}</button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  session: state.session
})

const mapDispatchToProps = dispatch => ({
  createDocument: (document) => dispatch(createDocument(document))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentBox)))

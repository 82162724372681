import * as APIUtil from '../apis/DocumentApi'

export const RECEIVE_ALL_DOCUMENTS = 'RECEIVE_ALL_DOCUMENTS'
export const RECEIVE_DOCUMENT = 'RECEIVE_DOCUMENT'

const receiveAllDocuments = documents => ({
  type: RECEIVE_ALL_DOCUMENTS,
  documents
})

const receiveDocument = document => ({
  type: RECEIVE_DOCUMENT,
  document
})

export const fetchAllDocuments = (classId) => dispatch => (
  APIUtil.fetchAllDocuments(classId)
    .then(response => dispatch(receiveAllDocuments(response.data)))
)

export const createDocument = (document) => dispatch => (
  APIUtil.createDocument(document)
    .then(response => dispatch(receiveDocument(response.data)))
)

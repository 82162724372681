import { RECEIVE_CONFIGURATION } from '../services/ConfigurationService'

export const ConfigurationReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_CONFIGURATION:
      nextState = action.configuration
      return nextState
    default:
      return state
  }
}

export default ConfigurationReducer

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import DocumentBox from './DocumentBox'
import DocumentItem from './DocumentItem'
import { fetchAllDocuments } from '../../services/DocumentService'
import './document.css'

class DocumentList extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    this.props.fetchAllDocuments(this.props.classId)
  }

  render () {
    if (!this.props.documents) {
      return null
    }

    const { t } = this.props
    const documents = this.props.documents.map((document, i) => (
      <DocumentItem document={document} key={i}/>
    ))
    return (
      <div className="document-box-container">
        <div className="document-item-header">{t('label.documents')}</div>
        <div className="document-box-item">
          <div className="review">{documents}</div>
          <DocumentBox classId={this.props.classId}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  documents: Object.values(state.documents),
})

const mapDispatchToProps = dispatch => ({
  fetchAllDocuments: (classId) => dispatch(fetchAllDocuments(classId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentList)))

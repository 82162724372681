import { RECEIVE_REGISTER, RECEIVE_REVIEW_REGISTERS } from '../services/RegisterService'

export const RegisterReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_REVIEW_REGISTERS:
      nextState.registers = action.registers
      return nextState
    case RECEIVE_REGISTER:
      nextState[action.data.id] = action.data
      return nextState
    default:
      return state
  }
}

export default RegisterReducer

import * as API from './API'

export const fetchImage = imageId => (
  API.get(`labs/images/${imageId}`)
)

export const createImage = (data) => (
  API.post(`labs/images`, data)
)

export const editImage = (imageId, data) => (
  API.put(`labs/images/${imageId}`, data)
)

export const deleteImage = (imageId) => (
  API.remove(`labs/images/${imageId}`)
)

export const filterImages = (query) => (
  API.post(`labs/images/filter`, query)
)

export const buildImage = (imageId, data) => (
  API.post(`labs/images/${imageId}/build`, data)
)

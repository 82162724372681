export const OAUTH2_REDIRECT_URI = window.location.origin
export const GOOGLE_AUTH_URL = process.env.BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI
export const FACEBOOK_AUTH_URL = process.env.BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI
export const GITHUB_AUTH_URL = process.env.BASE_URL + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI

export const DOC_TYPE_IMAGE = 'IMAGE'
export const DOC_TYPE_VIDEO = 'VIDEO'
export const DOC_TYPE_DOCUMENT = 'DOCUMENT'
export const DOC_TYPE_WORKSPACE = 'WORKSPACE'

export const ROLES = {
  DEFAULT: 'DEFAULT',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  INSTITUTE_ADMIN: 'INSTITUTE_ADMIN',
  INSTRUCTOR: 'INSTRUCTOR'
}

export const TABLE_DATA_TYPE = {
  TEXT_TYPE: 'TEXT_TYPE',
  DROPDOWN_TYPE: 'DROPDOWN_TYPE',
  IMAGE_TYPE: 'IMAGE_TYPE',
  TEXT_TOOLTIP: 'TEXT_TOOLTIP'
}

export const CALENDAR_WEEK = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
}

import { RECEIVE_INSTITUTE, RECEIVE_NAMES, RECEIVE_OWNER_INSTITUTE } from '../services/InstituteService'

export const InstituteReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_OWNER_INSTITUTE:
      nextState = action.institute
      return nextState
    case RECEIVE_INSTITUTE:
      nextState[action.data.id] = action.data
      return nextState
    default:
      return state
  }
}

export const InstructorNameReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_NAMES:
      nextState = action.names
      return nextState
    default:
      return state
  }
}

import React from 'react'
import { isSignIn } from '../../utils/SessionUtil'
import MultilevelSidebar from 'react-multilevel-sidebar'
import 'react-multilevel-sidebar/src/Sidebar.css'
import { withRouter } from 'react-router'
import { fetchAllMenus } from '../../services/MenuService'
import { connect } from 'react-redux'
import { logout } from '../../services/SessionService'
import { withTranslation } from 'react-i18next'
import './menu.css'
import { ROLES } from '../../utils/Contants'

const MENU_TYPE_CATEGORY = 'CATEGORY'

class SideMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleSidebarToggle = this.handleSidebarToggle.bind(this)
  }

  componentDidMount () {
    this.props.fetchAllMenus()
  }

  handleSidebarToggle () {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleClick (item) {
    if (item.link === '/logout') {
      this.handleSidebarToggle()
      this.props.logout()
      this.props.history.push("/")
    } else if (item.link === '/tool/remote-desktop') {
      this.handleSidebarToggle()
      const win = window.open(`https://remotedesktop.google.com/support/`, '_blank')
      if (win != null) {
        win.focus()
      }
    } else if (item.link) {
      this.handleSidebarToggle()
      this.props.history.push(item.link)
    }
  }

  render () {
    const { t } = this.props
    let menuItems = isSignIn() ? [{
        content: [
          { id: 11, name: t('Log Out'), link: '/logout' },
          {
            id: 12,
            name: t('My Learning'),
            icon: <i className="fa fa-chrome"></i>,
            children: [
              {
                content: [
                  { id: 121, name: t('My Courses'), link: '/learning/course' },
                  { id: 122, name: t('My Classes'), link: '/learning/class' }
                ]
              }
            ]
          },
          {
            id: 13,
            name: t('Utilities'),
            icon: <i className="fa fa-chrome"></i>,
            children: [
              {
                content: [
                  { id: 131, name: t('Chat Tool'), link: '/tool/chat' },
                  { id: 132, name: t('Meeting Tool'), link: '/tool/meeting' },
                  { id: 133, name: t('Remote Desktop'), link: '/tool/remote-desktop' },
                  { id: 134, name: t('Code Editor'), link: '/tool/code-editor' },
                  { id: 135, name: t('Code Diff Editor'), link: '/tool/code-diff-editor' },
                  { id: 1310, name: t('Feedback'), link: '/tool/feedback' },
                ]
              }
            ]
          }
        ]
      }] :
      [{
        content: [
          { id: 11, name: t('Sign In'), link: '/login' },
          { id: 12, name: t('Sign Up'), link: '/signup' }
        ]
      }]

    if (this.props.session.roles && this.props.session.roles.includes(ROLES.SYSTEM_ADMIN)) {
      const admins = {
        title: t('ADMIN'),
        titleIcon: <i className="fa fa-paragraph"></i>,
        content: [
          {
            id: 21,
            name: t('Manage Category'),
            icon: <i className="fa fa-chrome"></i>,
            children: [
              {
                content: [
                  { id: 211, name: t('Category Management'), link: '/admin/category' },
                  { id: 212, name: t('Topic Management'), link: '/admin/topic' }
                ]
              }
            ]
          },
          {
            id: 22,
            name: t('Manage Course'),
            icon: <i className="fa fa-chrome"></i>,
            children: [
              {
                content: [
                  { id: 221, name: t('Create Course'), link: '/admin/course/create' },
                  { id: 222, name: t('List Course'), link: '/admin/course/list' },
                  { id: 223, name: t('Review Course'), link: '/admin/course/review' }
                ]
              }
            ]
          },
          {
            id: 23,
            name: t('Manage Class'),
            icon: <i className="fa fa-chrome"></i>,
            children: [
              {
                content: [
                  { id: 231, name: t('Review Class'), link: '/admin/classes/review' },
                ]
              }
            ]
          },
          {
            id: 24,
            name: t('Crawling'),
            icon: <i className="fa fa-chrome"></i>,
            children: [
              {
                content: [
                  { id: 241, name: t('Crawling Youtube'), link: '/admin/crawling/youtube' },
                  { id: 242, name: t('Crawling Job'), link: '/admin/crawling/job' },
                ]
              }
            ]
          },
          {
            id: 25,
            name: t('Manage Feedback'),
            icon: <i className="fa fa-chrome"></i>,
            children: [
              {
                content: [
                  { id: 251, name: t('Review Feedback'), link: '/admin/feedback/review' },
                ]
              }
            ]
          },
          {
            id: 26,
            name: t('Manage Laboratory'),
            icon: <i className="fa fa-chrome"></i>,
            children: [
              {
                content: [
                  {
                    id: 261,
                    name: t('Setting Laboratory'),
                    icon: <i className="fa fa-chrome"></i>,
                    children: [
                      {
                        content: [
                          { id: 2611, name: t('Machine Management'), link: '/admin/machines/management' },
                          { id: 2612, name: t('Image Management'), link: '/admin/images/management' },
                          { id: 2613, name: t('Client Management'), link: '/admin/clients/management' },
                          { id: 2614, name: t('Connection Management'), link: '/admin/connections/management' },
                          { id: 2615, name: t('Container Management'), link: '/admin/containers/management' },
                        ]
                      }
                    ]
                  },

                  { id: 262, name: t('Laboratory Management'), link: '/admin/laboratory/list' },
                  { id: 2610, name: t('Command Util'), link: '/admin/commands/util' },
                ]
              },
            ]
          }
        ]
      }
      menuItems.push(admins)
    }

    if (this.props.session.roles && this.props.session.roles.includes(ROLES.INSTITUTE_ADMIN)) {
      const institutes = {
        title: t('INSTITUTE'),
        titleIcon: <i className="fa fa-paragraph"></i>,
        content: [
          {
            id: 31,
            name: t('Manage Course'),
            icon: <i className="fa fa-chrome"></i>,
            children: [
              {
                content: [
                  { id: 311, name: t('Create Course'), link: '/institute/course/create' },
                  { id: 312, name: t('List Course'), link: '/institute/course/list' }
                ]
              }
            ]
          },
          {
            id: 32,
            name: t('Manage Institute'),
            icon: <i className="fa fa-chrome"></i>,
            children: [
              {
                content: [
                  { id: 321, name: t('Institute Page'), link: `/institute/introduction/page` },
                  { id: 324, name: t('Manage Instructor'), link: '/institute/instructor/management' },
                  { id: 323, name: t('Review Register'), link: '/institute/register/review' },
                  { id: 324, name: t('Manage Class'), link: '/institute/classes/management' },
                  { id: 322, name: t('Class Dashboard'), link: '/institute/classes/dashboard' },
                ]
              }
            ]
          },
          {
            id: 33,
            name: t('Manage Laboratory'),
            icon: <i className="fa fa-chrome"></i>,
            children: [
              {
                content: [
                  { id: 331, name: t('Create Laboratory'), link: '/instructor/laboratories' },
                  { id: 332, name: t('Management Laboratory'), link: '/instructor/laboratories/management' },
                ]
              }
            ]
          }
        ]
      }
      menuItems.push(institutes)
    }

    if (this.props.session.roles && this.props.session.roles.includes(ROLES.INSTRUCTOR)) {
      const instructors = {
        title: t('INSTRUCTOR'),
        titleIcon: <i className="fa fa-paragraph"></i>,
        content: [
          {
            id: 41,
            name: t('Manage Class'),
            icon: <i className="fa fa-chrome"></i>,
            children: [
              {
                content: [
                  { id: 412, name: t('Manage Class'), link: '/institute/instructor/classes' },
                ]
              }
            ]
          }
        ]
      }
      menuItems.push(instructors)
    }

    let contents = []
    this.props.menus.filter(m => m.type === MENU_TYPE_CATEGORY).map((menu) => {
      let subContents = []
      menu.subMenus.map((sub) => {
        let subContent = sub
        subContent.name = t(`${sub.name}`)
        subContent.link = `/courses/topic/${sub.topicUrl}`
        subContents.push(subContent)
      })
      let subMenu = []
      subMenu.push({ content: subContents })
      let item = {
        id: menu.id,
        name: t(`${menu.name}`),
        icon: <i className="fa fa-chrome"></i>,
        children: subMenu
      }
      contents.push(item)
    })
    let categories = {
      title: t('CATEGORIES'),
      titleIcon: <i className="fa fa-paragraph"></i>,
      content: contents
    }
    menuItems.push(categories)

    return (
      <div>
        <MultilevelSidebar
          open={this.state.isOpen}
          onToggle={this.handleSidebarToggle}
          options={menuItems}
          header=""
          onItemClick={this.handleClick}/>
        <button className="hamburger" type="button" onClick={() => this.handleSidebarToggle()}>
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
        </button>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  menus: Object.values(state.menus),
  session: state.session
})

const mapDisPatchToProps = dispatch => ({
  fetchAllMenus: () => dispatch(fetchAllMenus()),
  logout: () => dispatch(logout())
})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(SideMenu)))

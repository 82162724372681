import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import SingleLineTable from '../../components/table/SingleLineTable'
import { fetchSimilarClasses } from '../../services/ClassService'
import ClassItem from '../../components/classes/ClassItem'

class SimilarClass extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    this.props.fetchSimilarClasses(this.props.classId)
  }

  render () {
    const { t } = this.props
    if (this.props.similarClasses.length === 0) {
      return null
    }
    const items = this.props.similarClasses.map((data, i) =>
      <div className="course-tile" key={i}>
        <ClassItem key={i} class={data}/>
      </div>
    )
    return (
      <div className="outer-course-likes">
        <div className="like-course-title">{t('home.similar.label')}</div>
        <SingleLineTable items={items}/>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  similarClasses: Object.values(state.similarClasses)
})

const mapDisPatchToProps = dispatch => ({
  fetchSimilarClasses: (classId) => dispatch(fetchSimilarClasses(classId))
})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(SimilarClass)))

import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import * as Session from '../../utils/SessionUtil'
import {getUrlParameter} from "../../utils/CommonUtils";

class OAuth2RedirectHandler extends Component {
  render () {
    const token = {
      token: getUrlParameter(this.props.location.search, 'token'),
      rcToken: getUrlParameter(this.props.location.search, 'rcToken')
    }
    const error = getUrlParameter(this.props.location.search, 'error')

    if (token) {
      Session.storeToken(token)
      return <Redirect to={{
        pathname: '/',
        state: { from: this.props.location }
      }}/>
    } else {
      return <Redirect to={{
        pathname: '/login',
        state: {
          from: this.props.location,
          error: error
        }
      }}/>
    }
  }
}

export default OAuth2RedirectHandler

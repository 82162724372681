export const jitsi = {
  interfaceConfig: {
    LANG_DETECTION: false,
    lang: 'en',
    APP_NAME: 'UeCADEMY',
    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
    HIDE_INVITE_MORE_HEADER: true,
    MOBILE_APP_PROMO: false,
    SHOW_CHROME_EXTENSION_BANNER: false,
    TOOLBAR_BUTTONS: [
      'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
      'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
      'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
      'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
      'tileview', 'videobackgroundblur', 'download', 'mute-everyone', 'security'
    ]
  },
  config: {
    defaultLanguage: 'en',
    prejoinPageEnabled: false
  },
  domain: process.env.MEETING_DOMAIN,
}

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { addNotification } from '../../../../utils/CommonUtils'
import { createClient, editClient, fetchClient } from '../../../../services/ClientService'
import '../laboratory.css'

class ClientCreation extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      script: '',
      description: '',
      url: '',
      status: '',
      edit: false,
    }
  }

  componentDidMount () {
    this.updateState()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.match.params.clientId !== prevProps.match.params.clientId) {
      this.updateState()
    }
  }

  updateState () {
    const clientId = this.props.match.params.clientId
    if (clientId) {
      this.setState({
        edit: true
      })
      this.props.fetchClient(clientId).then(response => {
        this.setState({
          name: response.data.name,
          script: response.data.script,
          description: response.data.description,
          url: response.data.url,
          params: response.data.params,
          status: response.data.status,
        })
      })
    } else {
      this.setState({
        name: '',
        script: '',
        description: '',
        url: '',
        status: '',
        edit: false,
      })
    }
  }

  changeName (e) {
    this.setState({
      name: e.target.value
    })
  }

  changeDescription (e) {
    this.setState({
      description: e.target.value
    })
  }

  changeScript (e) {
    this.setState({
      script: e.target.value
    })
  }

  changeUrl (e) {
    this.setState({
      url: e.target.value
    })
  }

  submitHandle (status) {
    const { t } = this.props
    const data = {
      name: this.state.name,
      description: this.state.description,
      script: this.state.script,
      url: this.state.url,
      status: status === '' ? null : status,
    }
    const clientId = this.props.match.params.clientId
    if (clientId) {
      this.props.editClient(clientId, data)
    } else {
      this.props.createClient(data)
    }
    addNotification(t('notification.class.create'))
    this.props.history.push('/admin/clients/management')
  }

  renderButton () {
    const { t } = this.props
    if (this.state.edit) {
      return (
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this, '')} className="submit-button">
            {t('SAVE')}
          </button>
          {this.state.status === 'DRAFT' ? (
            <button onClick={this.submitHandle.bind(this, 'SUBMITTED')} className="submit-button">
              {t('SUBMIT')}
            </button>
          ) : null
          }
        </div>
      )
    } else {
      return (
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this, 'DRAFT')} className="submit-button">
            {t('SAVE DRAFT')}
          </button>
          <button onClick={this.submitHandle.bind(this, 'SUBMITTED')} className="submit-button">
            {t('SUBMIT')}
          </button>
        </div>
      )
    }
  }

  render () {
    const { t } = this.props
    return (
      <div>
        <div className="heading-creation-box">
          {t('management.client.title.create')}
        </div>
        <div className="course-creation-item">
          <span>{t('management.client.name')}:</span>
          <input className="course-creation-input-text" type="text" value={this.state.name}
                 onChange={this.changeName.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.client.description')}:</span>
          <textarea className="learning-goals-textarea" value={this.state.description || ''}
                    onChange={this.changeDescription.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.client.url')}:</span>
          <input className="course-creation-input-text" type="text" value={this.state.url}
                 onChange={this.changeUrl.bind(this)}/>
        </div>

        {this.renderButton()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clients: Object.values(state.clients),
})

const mapDispatchToProps = dispatch => ({
  createClient: (data) => dispatch(createClient(data)),
  editClient: (clientId, data) => dispatch(editClient(clientId, data)),
  fetchClient: (clientId) => dispatch(fetchClient(clientId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientCreation)))

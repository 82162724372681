import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import './tool.css'

class RemoteDesktop extends React.Component {
  constructor () {
    super()
  }

  openExternalLink () {

  }

  render () {
    return (
      <div>
        <iframe id="remoteDesktop" className="chat-tool-container"
                sandbox="allow-same-origin allow-scripts allow-forms allow-top-navigation allow-popups allow-popups-to-escape-sandbox"
                src={`https://remotedesktop.google.com/support/`}/>
        <div onClick={this.openExternalLink.bind(this)}>Link</div>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RemoteDesktop)))

import * as APIUtil from '../apis/VideoApi'

export const RECEIVE_ALL_VIDEOS = 'RECEIVE_ALL_VIDEOS'
export const RECEIVE_VIDEO = 'RECEIVE_VIDEO'
export const SHOW_PLAYER = 'SHOW_PLAYER'
export const HIDE_PLAYER = 'HIDE_PLAYER'

const receiveAllVideos = (contentId, videos) => ({
  type: RECEIVE_ALL_VIDEOS,
  contentId,
  videos
})

export const showPlayer = (index, contentId) => ({
  type: SHOW_PLAYER,
  index,
  contentId
})

export const hidePlayer = (index, contentId) => ({
  type: HIDE_PLAYER,
  index,
  contentId
})

export const fetchAllVideos = (courseId, contentId) => dispatch => (
  APIUtil.fetchAllVideos(courseId, contentId)
    .then(videos => dispatch(receiveAllVideos(contentId, videos.data)))
)

import { RECEIVE_CONTENTS } from '../services/ContentService'

const ContentReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_CONTENTS:
      nextState = action.contents
      return nextState
    default:
      return state
  }
}

export default ContentReducer

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import './document.css'
import { downloadFile } from '../../services/FileService'
import FileViewer from 'react-file-viewer'
import {getUrlParameter} from "../../utils/CommonUtils";

class DocumentViewer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      document: null
    }
  }

  componentDidMount () {
    this.props.downloadFile(this.props.match.params.documentId).then(response => {
      const fileType = getUrlParameter(this.props.location.search, 'fileType');
      const url = window.URL.createObjectURL(new Blob([response.data]))
      this.setState({
        document: {
          fileType: fileType,
          file: url
        }
      })
    })
  }

  render () {
    return (
      <div className='document-viewer'>
        {this.state.document ?
          (<FileViewer fileType={this.state.document.fileType} filePath={this.state.document.file}/>)
          : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => ({
  downloadFile: (id) => dispatch(downloadFile(id))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentViewer)))

import { RECEIVE_ALL_INSTRUCTORS, RECEIVE_INSTRUCTOR } from '../services/InstructorService'

export const InstructorReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_ALL_INSTRUCTORS:
      nextState = action.data
      return nextState
    case RECEIVE_INSTRUCTOR:
      nextState[action.data.id] = action.data
      return nextState
    default:
      return state
  }
}

export default InstructorReducer

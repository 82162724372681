import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { logout, userInfo } from '../../services/SessionService'
import { connect } from 'react-redux'
import * as SessionUtil from '../../utils/SessionUtil'
import { withTranslation } from 'react-i18next'
import { fetchAllMenus } from '../../services/MenuService'

class UserInfo extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      dropdown: 'initial-dropdown'
    }
    this.dropdownToggle = this.dropdownToggle.bind(this)
    this.logout = this.logout.bind(this)
  }

  componentDidMount () {
    if (!this.props.currentUser && SessionUtil.isSignIn()) {
      this.props.userInfo()
    }
  }

  sessionLinks () {
    const { t } = this.props
    return (
      <nav className="login-signup">
        <Link tabIndex="-1" to="/login">
          <button className="loginHeaderButton">{t('Sign In')}</button>
        </Link>
        <Link tabIndex="-1" to="/signup">
          <button className="signupHeaderButton">{t('Sign Up')}</button>
        </Link>
      </nav>
    )
  }

  dropdownToggle () {
    const dropdown = this.state.dropdown === 'initial-dropdown' ? 'initial-dropdown-open' : 'initial-dropdown'
    this.setState({
      dropdown: dropdown
    })
  }

  logout () {
    this.dropdownToggle()
    this.props.logout().then(() => {
      this.props.fetchAllMenus()
      this.props.history.push("/")
    })
  }

  personalGreeting () {
    const { t } = this.props
    const nameArr = this.props.currentUser.userName.split(' ')
    const initialName = []
    for (let i = 0; i < nameArr.length; i++) {
      initialName.push(nameArr[i][0])
    }
    return (
      <div className="initial-box">
        <div className="initial" onClick={this.dropdownToggle}>{initialName.join('').toUpperCase()}</div>
        <div className={this.state.dropdown}>
          <div className="triangle-up-border">
            <div className="triangle-up-center"/>
          </div>
          <div className="initial-user-info-box">
            <div className="initial-user-info">
              <div className="initial-dropdown-circle">{initialName.join('').toUpperCase()}</div>
              <div className="initial-name">
                <div>{this.props.currentUser.userName}</div>
                <div style={{
                  color: 'grey',
                  fontSize: '13px',
                  paddingTop: '3px'
                }}>{this.props.currentUser.email}</div>
              </div>
            </div>
          </div>
          <button className="header-button" onClick={this.logout}>{t('Sign Out')}</button>
        </div>
      </div>
    )
  }

  render () {
    return this.props.currentUser ? this.personalGreeting() : this.sessionLinks()
  }
}

const mapStateToProps = ({ session, entities: { users } }, state) => ({
  currentUser: users[session.id],
})

const mapDispatchToProps = dispatch => ({
  userInfo: () => dispatch(userInfo()),
  logout: () => dispatch(logout()),
  fetchAllMenus: () => dispatch(fetchAllMenus()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserInfo)))

import React from 'react'
import { Link } from 'react-router-dom'
import defaultImage from '../../assets/default-image.png'
import * as CommonUtils from '../../utils/CommonUtils'
import { withTranslation } from 'react-i18next'

class MyClassItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const image = this.props.class.thumbnail ? this.props.class.thumbnail : defaultImage
    let name = this.props.class.name
    name = name && name.length > 60 ? name.substr(0, 60) + ' ...' : name
    return (
      <Link to={`/learning/class/${this.props.class.url}`} className="course-card" style={{ textDecoration: 'none' }}>
        <div>
          <img style={{ width: '100%' }} src={image} alt={'Class Thumbnail'}/>
        </div>
        <div className="index-card-text">
          <div className="index-card-title">{name}</div>
          <div className="index-card-price">{CommonUtils.getPrice(this.props.class.price)}</div>
        </div>
      </Link>
    )
  }
}

export default withTranslation()(MyClassItem)

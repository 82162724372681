import { RECEIVE_IMAGE, REMOVE_IMAGE } from '../services/ImageService'

export const ImageReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_IMAGE:
      nextState[action.data.id] = action.data
      return nextState
    case REMOVE_IMAGE:
      delete nextState[action.imageId]
      return nextState
    default:
      return state
  }
}

export default ImageReducer

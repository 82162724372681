import React from 'react'
import { withRouter } from 'react-router-dom'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createReview } from '../../services/ReviewService'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

class ReviewCreation extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      rating: 5,
      body: ''
    }
    this.submitHandle = this.submitHandle.bind(this)
    this.getBody = this.getBody.bind(this)
    this.handleRatingChange = this.handleRatingChange.bind(this)
  }

  submitHandle () {
    if (this.props.session.id === null) {
      this.props.history.push('/login')
    } else {
      const data = this.state
      data.courseId = this.props.courseId
      this.props.createReview(data).then(() => {
        this.setState({
          body: ''
        })
      })
    }
  }

  getBody (e) {
    this.setState({
      body: e.target.value
    })
  }

  handleRatingChange (rating) {
    this.setState({
      rating: rating,
    })
  }

  render () {
    const { t } = this.props
    return (
      <div className="review-form-box">
        <div className="make-review-title">{t('label.addReview')}</div>
        <textarea className="review-textarea" value={this.state.body} onChange={this.getBody}></textarea>
        <div className="review-ratting-box">
          {[1, 2, 3, 4, 5].map(num => (
            <FontAwesomeIcon
              style={{ padding: '3px', color: this.state.rating >= num ? '#f4c150' : 'dedfe0' }}
              icon={faStar}
              key={num}
              onMouseEnter={() => this.handleRatingChange(num)}
            />
          ))}
        </div>
        <button onClick={this.submitHandle} className="review-form"
                style={{ fontSize: '15px', padding: ' 12px 54px' }}>{t('label.makeReview')}
        </button>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  session: state.session
})

const mapDispatchToProps = dispatch => ({
  createReview: (review) => dispatch(createReview(review))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReviewCreation)))

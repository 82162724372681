import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import defaultImage from '../../assets/default-image.png'
import './classes.css'
import * as CommonUtils from '../../utils/CommonUtils'

class SearchClassItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const { t } = this.props
    let description = this.props.class.description
    description = description && description.length > 500 ? description.substr(0, 500) + ' ...' : description
    const image = this.props.class.image ? this.props.class.image : defaultImage
    return (
      <div>
        <Link to={`/courses/${this.props.class.url}`} style={{ textDecoration: 'none' }} className="search-feed">
          <div className="search-detail-content">
            <img src={image} className="search-img"/>
            <div className="search-box-detail">
              <div className="search-box-title">{this.props.class.name}</div>
              <div className="search-box-audience">{description}</div>
            </div>
          </div>
          <div className="search-rating-info">
            <div className="search-rating-span">{CommonUtils.getPrice()}</div>
            <div className="search-rating-count">({this.props.class.memberNumber} {t('label.members')})</div>
          </div>
        </Link>
      </div>
    )
  }
}

export default withTranslation()(SearchClassItem)

import * as API from './API'

export const fetchOwnerLaboratories = (query) => (
  API.get('labs/laboratories/owner')
)

export const fetchLaboratory = laboratoryId => (
  API.get(`labs/laboratories/${laboratoryId}`)
)

export const createLaboratory = (data) => (
  API.post(`labs/laboratories`, data)
)

export const editLaboratory = (laboratoryId, data) => (
  API.put(`labs/laboratories/${laboratoryId}`, data)
)

export const deleteLaboratory = (laboratoryId) => (
  API.remove(`labs/laboratories/${laboratoryId}`)
)

export const filterLaboratories = (query) => (
  API.post(`labs/laboratories/filter`, query)
)

import { connect } from 'react-redux'
import React from 'react'
import { Link } from 'react-router-dom'
import { login, removeErrors } from '../../services/SessionService'
import { withTranslation } from 'react-i18next'
import SessionForm from '../../components/session/SessionForm'
import { fetchAllMenus } from '../../services/MenuService'

class SignInForm extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    const { t } = this.props
    const navLink = <Link to="/signup" className="login-link">{t('Sign Up')}</Link>
    const header = t('session.header.signIn')
    return (
      <SessionForm formType="Log In" navLink={navLink} header={header} errors={this.props.errors}
                   processForm={this.props.processForm}
                   removeErrors={this.props.removeErrors}
                   fetchAllMenus={this.props.fetchAllMenus}/>
    )
  }
}

const mapStateToProps = ({ errors }) => ({
  errors: errors.session,
})
const mapDispatchToProps = dispatch => ({
  processForm: (user) => dispatch(login(user)),
  removeErrors: () => dispatch(removeErrors()),
  fetchAllMenus: () => dispatch(fetchAllMenus()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SignInForm))

import * as APIUtil from '../apis/InstructorApi'

export const RECEIVE_ALL_INSTRUCTORS = 'RECEIVE_ALL_INSTRUCTORS'
export const RECEIVE_INSTRUCTOR = 'RECEIVE_INSTRUCTOR'

const receiveInstructors = data => ({
  type: RECEIVE_ALL_INSTRUCTORS,
  data
})

const receiveInstructor = data => ({
  type: RECEIVE_INSTRUCTOR,
  data
})

export const createInstructor = data => dispatch => (
  APIUtil.createInstructor(data)
    .then(response => dispatch(receiveInstructor(response.data)))
)

export const updateInstructor = (instructorId, data) => dispatch => (
  APIUtil.updateInstructor(instructorId, data)
    .then(response => dispatch(receiveInstructor(response.data)))
)

export const fetchAllInstructors = () => dispatch => (
  APIUtil.fetchAllInstructors()
    .then(response => dispatch(receiveInstructors(response.data)))
)

export const fetchInstructor = instructorId => dispatch => (
  APIUtil.fetchInstructor(instructorId)
    .then(response => dispatch(receiveInstructor(response.data)))
)

export const deleteInstructor = (instructorId) => dispatch => (
  APIUtil.deleteInstructor(instructorId)
)




import * as APIUtil from '../apis/FileApi'

export const RECEIVE_FILE = 'RECEIVE_FILE'

const receiveFile = file => ({
  type: RECEIVE_FILE,
  file
})

export const uploadFile = (data, docType) => dispatch => (
  APIUtil.uploadFile(data, docType)
    .then(response => dispatch(receiveFile(response.data)))
)

export const uploadMultipleFile = (data, docType) => dispatch => (
  APIUtil.uploadMultipleFile(data, docType)
    .then(response => dispatch(receiveFile(response.data)))
)

export const getFile = (id) => dispatch => (
  APIUtil.getFile(id)
    .then(response => dispatch(receiveFile(response.data)))
)

export const downloadFile = (id) => dispatch => (
  APIUtil.downloadFile(id)
)

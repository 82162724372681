import * as API from './API'

export const fetchOwnerClasses = () => (
  API.get('classes/owner')
)

export const fetchClass = classId => (
  API.get(`classes/${classId}`)
)

export const createClass = (data) => (
  API.post(`classes`, data)
)

export const editClass = (classId, data) => (
  API.put(`classes/${classId}`, data)
)

export const deleteClass = (classId) => (
  API.remove(`classes/${classId}`)
)

export const fetchBestClasses = (page, size) => (
  API.get(`classes/best?page=${page}&size=${size}`)
)

export const filterClasses = (query) => (
  API.post(`classes/filter`, query)
)

export const fetchSimilarClasses = (classId) => (
  API.get(`classes/similar?classId=${classId}`)
)

export const fetchClassesByUrl = url => (
  API.get(`classes/path?url=${url}`)
)

export const joinClass = (classId) => (
  API.post(`classes/${classId}/join`)
)

export const startClass = (classId) => (
  API.post(`classes/${classId}/start`)
)

export const endClass = (classId) => (
  API.post(`classes/${classId}/end`)
)

export const reviewClass = (classId, data) => (
  API.post(`classes/${classId}/review`, data)
)

export const fetchReviewClasses = (query) => (
  API.post(`classes/review`, query)
)

export const addInstructor = (classId, data) => (
  API.post(`classes/${classId}/instructor`, data)
)

export const fetchInstructorClasses = () => (
  API.get('classes/instructor')
)

import { RECEIVE_ALL_DOCUMENTS, RECEIVE_DOCUMENT } from '../services/DocumentService'

const DocumentReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_ALL_DOCUMENTS:
      let documents = action.documents.reduce(function (map, obj) {
        map[obj.id] = obj
        return map
      }, {})
      nextState = documents
      return nextState
    case RECEIVE_DOCUMENT:
      nextState[action.document.id] = action.document
      return nextState
    default:
      return state
  }
}

export default DocumentReducer

import React from 'react'
import FileUpload from '../file/FileUpload'
import { DOC_TYPE_VIDEO } from '../../utils/Contants'
import '../../pages/institute/institute.css'
import { withTranslation } from 'react-i18next'
import FileUploadOption from '../file/FileUploadOption'

class ContentVideoItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      contents: props.contents
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.contents !== prevProps.contents) {
      this.setState({
        contents: this.props.contents
      })
    }
  }

  addVideoMore (i) {
    let video = {}
    let contents = this.state.contents
    contents[i].videos.push(video)
    this.updateState(contents)
  }

  removeVideo (i, j) {
    let contents = this.state.contents
    contents[i].videos.splice(j, 1)
    this.updateState(contents)
  }

  addMoreContent () {
    let contents = this.state.contents
    contents.push({
      videos: [{}]
    })
    this.updateState(contents)
  }

  removeContent (i) {
    let contents = this.state.contents
    contents.splice(i, 1)
    this.updateState(contents)
  }

  updateVideo (i, j, fileId) {
    let contents = this.state.contents
    contents[i].videos[j].url = fileId
    this.updateState(contents)
  }

  changeContentTitle (i, e) {
    let contents = this.state.contents
    contents[i].title = e.target.value
    this.updateState(contents)
  }

  changeVideoTitle (i, j, e) {
    let contents = this.state.contents
    contents[i].videos[j].title = e.target.value
    this.updateState(contents)
  }

  updateState (contents) {
    this.props.onUpdateContent(contents)
  }

  createContent () {
    const { t } = this.props
    return this.state.contents.map((content, i) =>
      <div key={i} className="course-content-box">
        <div className="course-creation-item">
          <span>{t('label.item.contentTitle')} {i + 1}:</span>
          <input className="course-creation-input-text" type="text"
                 value={content.title || ''} onChange={this.changeContentTitle.bind(this, i)}/>
        </div>
        <div className="course-video-item">
          <div>{t('label.videos')}:</div>
          <div>
            {this.createVideo(i)}
          </div>
        </div>
        <div className="button-panel">
          <button className="submit-sub-button" onClick={this.addVideoMore.bind(this, i)}>
            {t('label.moreVideo')}
          </button>
          <button className="submit-sub-button" onClick={this.removeContent.bind(this, i)}>
            {t('label.removeContent')}
          </button>
        </div>
      </div>
    )
  }

  createVideo (i) {
    const { t } = this.props
    return this.state.contents[i].videos.map((video, j) => (
      <div key={j} className="course-video-box">
        <div className="course-creation-item">
          <span>{t('label.item.videoTitle')} {j + 1}:</span>
          <input className="course-creation-input-text" type="text"
                 value={video.title || ''} onChange={this.changeVideoTitle.bind(this, i, j)}/>
        </div>
        <div className="course-creation-item">
          {this.props.isAdmin ?
            <FileUploadOption docType={DOC_TYPE_VIDEO} i={i} j={j} onChangeFile={this.updateVideo.bind(this)}
                              file={this.state.contents[i].videos[j].url}/>
            : <FileUpload docType={DOC_TYPE_VIDEO} i={i} j={j} onUpdateVideo={this.updateVideo.bind(this)}/>}
        </div>
        <div className="button-panel">
          <button className="submit-sub-button" onClick={this.removeVideo.bind(this, i, j)}>
            {t('label.removeVideo')}
          </button>
        </div>
      </div>
    ))
  }

  render () {
    const { t } = this.props
    return (
      <div className="course-creation-item">
        <div>{t('label.contents')}:</div>
        <div className="course-content-item">
          {this.createContent()}
        </div>
        <div className="button-panel">
          <button className="submit-sub-button" onClick={this.addMoreContent.bind(this)}>
            {t('label.moreContent')}
          </button>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ContentVideoItem)

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Swipeable from 'react-swipeable'

const RIGHT = 1
const LEFT = -1

class SingleLineTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentTransformation: 0
    }
  }

  getDisplayNumber () {
    const width = window.innerWidth
    if (width < 564) {
      return 1
    } else if (width < 796) {
      return 2
    } else if (width < 1028) {
      return 3
    } else if (width < 1260) {
      return 4
    } else {
      return 5
    }
  }

  showLeft () {
    if (!this.state.currentTransformation > 0) {
      return null
    }

    return (
      <div className="left arrow" onClick={() => this.leftClick()}>
        <FontAwesomeIcon icon={faChevronLeft}/>
      </div>
    )
  }

  leftClick () {
    this.setState({
      currentTransformation: Math.max(0, this.state.currentTransformation - this.getDisplayNumber())
    })
  }

  rightClick () {
    this.setState({
      currentTransformation: Math.min(
        this.props.items.length - 1,
        this.state.currentTransformation + this.getDisplayNumber()
      )
    })
  }

  showRight () {
    let max = this.props.items.length - 1
    let currentValue = this.state.currentTransformation

    if (max >= currentValue + this.getDisplayNumber()) {
      return (
        <div className="right arrow" onClick={() => this.rightClick()}>
          < FontAwesomeIcon icon={faChevronRight}/>
        </div>
      )
    }
    return null
  }

  onSwiped (direction) {
    if (direction === RIGHT) {
      this.leftClick()
    } else {
      this.rightClick()
    }
  }

  render () {
    return (
      <Swipeable trackMouse
                 preventDefaultTouchmoveEvent
                 onSwipedLeft={() => this.onSwiped(LEFT)}
                 onSwipedRight={() => this.onSwiped(RIGHT)}>
        <div className="courses-box">
          <div className="carousel-rel-wrapper">
            <div id="carousel-inner"
                 style={{ transform: `translateX(${-1 * this.state.currentTransformation * 220}px)` }}>
              {this.props.items}
            </div>
          </div>
          {this.showLeft()}
          {this.showRight()}
        </div>
      </Swipeable>
    )
  }
}

export default SingleLineTable

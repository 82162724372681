import * as API from './API'

export const fetchOwnerInstitute = () => (
  API.get('institutes/owner')
)

export const fetchInstitute = instituteId => (
  API.get(`institutes/${instituteId}`)
)

export const fetchInstituteByUrl = url => (
  API.get(`institutes/path?url=${url}`)
)

export const createInstitute = (data) => (
  API.post(`institutes`, data)
)

export const editOwnerInstitute = (data) => (
  API.put(`institutes/owner`, data)
)

export const fetchInstructorNames = () => (
  API.get(`institutes/name`)
)

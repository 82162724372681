import * as APIUtil from '../apis/ContainerApi'

export const RECEIVE_CONTAINER = 'RECEIVE_CONTAINER'
export const REMOVE_CONTAINER = 'REMOVE_CONTAINER'

const receiveContainer = data => ({
  type: RECEIVE_CONTAINER,
  data
})

const removeContainer = containerId => ({
  type: REMOVE_CONTAINER,
  containerId
})

export const fetchContainer = (containerId) => dispatch => (
  APIUtil.fetchContainer(containerId)
    .then(response => dispatch(receiveContainer(response.data)))
)

export const filterContainers = (query) => dispatch => (
  APIUtil.filterContainers(query)
    .then(response => dispatch(receiveContainer(response.data)))
)

export const releaseContainer = (containerId) => dispatch => (
  APIUtil.releaseContainer(containerId)
    .then(response => dispatch(removeContainer(response.data)))
)

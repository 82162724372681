import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export const MODULES = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image'],
    ['clean']
  ],
}
export const FORMATS = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
]

class Editor extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <ReactQuill theme="snow" modules={MODULES} formats={FORMATS}
                  value={this.props.description} onChange={this.props.onChangeDescription}/>
    )
  }
}

export default Editor

import { RECEIVE_CONNECTION, RECEIVE_CONNECTIONS, REMOVE_CONNECTION } from '../services/ConnectionService'

export const ConnectionReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_CONNECTION:
      nextState = action.data
      return nextState
    default:
      return state
  }
}

export const ConnectionsReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_CONNECTIONS:
      nextState[action.data.id] = action.data
      return nextState
    case REMOVE_CONNECTION:
      delete nextState[action.connectionId]
      return nextState
    default:
      return state
  }
}

import * as APIUtil from '../apis/LaboratoryApi'

export const RECEIVE_OWNER_LABORATORIES = 'RECEIVE_OWNER_LABORATORIES'
export const RECEIVE_LABORATORY = 'RECEIVE_LABORATORY'
export const REMOVE_LABORATORY = 'REMOVE_LABORATORY'

const receiveOwnerLaboratories = laboratories => ({
  type: RECEIVE_OWNER_LABORATORIES,
  laboratories
})

const receiveLaboratories = data => ({
  type: RECEIVE_LABORATORY,
  data
})

const removeLaboratory = laboratoryId => ({
  type: REMOVE_LABORATORY,
  laboratoryId
})

export const fetchOwnerLaboratories = (query) => dispatch => (
  APIUtil.fetchOwnerLaboratories(query)
    .then(response => dispatch(receiveOwnerLaboratories(response.data)))
)

export const fetchLaboratory = laboratoryId => dispatch => (
  APIUtil.fetchLaboratory(laboratoryId)
    .then(response => dispatch(receiveLaboratories(response.data)))
)

export const createLaboratory = data => dispatch => (
  APIUtil.createLaboratory(data)
    .then(response => dispatch(receiveLaboratories(response.data)))
)

export const editLaboratory = (laboratoryId, data) => dispatch => (
  APIUtil.editLaboratory(laboratoryId, data)
    .then(response => dispatch(receiveLaboratories(response.data)))
)

export const deleteLaboratory = laboratoryId => dispatch => (
  APIUtil.deleteLaboratory(laboratoryId)
    .then(() => dispatch(removeLaboratory(laboratoryId)))
)

export const filterLaboratories = (query) => dispatch => (
  APIUtil.filterLaboratories(query)
    .then(response => dispatch(receiveLaboratories(response.data)))
)

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './process-file.css'
import { getFile, uploadFile } from '../../services/FileService'
import { withTranslation } from 'react-i18next'
import { DOC_TYPE_IMAGE, DOC_TYPE_VIDEO } from '../../utils/Contants'

class FileUpload extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      file: '',
      progress: 0,
      submitted: false,
      fileId: null,
      previewUrl: ''
    }
    this.timer = 0
    this.updateProgress = this.updateProgress.bind(this)
  }

  updateProgress () {
    this.props.getFile(this.state.fileId).then(response => {
      this.setState({
        progress: response.file.progress
      })
      if (response.file.progress === 100) {
        clearInterval(this.timer)
        this.setState({
          submitted: false
        })
      }
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    const formData = new FormData()
    formData.append('file', this.state.file)
    formData.append('async', this.props.async)
    this.props.uploadFile(formData, this.props.docType).then(response => {
      if (response.file.type === DOC_TYPE_IMAGE) {
        this.props.onUpdateImage(response.file.id)
      } else if (response.file.type === DOC_TYPE_VIDEO) {
        this.props.onUpdateVideo(this.props.i, this.props.j, response.file.id)
      } else {
        this.props.onUpdateFile(response.file.id)
      }
      this.setState({
        fileId: response.file.id,
        submitted: true
      }, () => {
        if (this.timer === 0) {
          this.timer = setInterval(this.updateProgress, 5000)
        }
      })
    })

  }

  handleFileChange (e) {
    e.preventDefault()

    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onloadend = () => {
      this.setState({
        file: file,
        previewUrl: reader.result
      })
    }
    reader.readAsDataURL(file)

    if (!this.props.async) {
      const formData = new FormData()
      formData.append('file', file)
      this.props.uploadFile(formData, this.props.docType).then(response => {
        if (response.file.type === DOC_TYPE_IMAGE) {
          this.props.onUpdateImage(response.file.id)
        } else if (response.file.type === DOC_TYPE_VIDEO) {
          this.props.onUpdateVideo(this.props.i, this.props.j, response.file.id)
        } else {
          this.props.onUpdateFile(response.file.id)
        }
        this.setState({
          fileId: response.file.id
        })
      })
    }
  }

  render () {
    return (
      <div>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <input className="file-input" type="file"
                 onChange={(e) => this.handleFileChange(e)}/>
          {this.props.async ?
            <button type="submit" className="upload-button"
                    onClick={(e) => this.handleSubmit(e)}>Upload</button>
            : null}
        </form>
        {this.state.submitted ?
          <div className="progress">
            <div className="progress-bar progress-bar-info progress-bar-striped"
                 role="progressbar"
                 aria-valuenow={this.state.progress}
                 aria-valuemin="0"
                 aria-valuemax="100"
                 style={{ width: this.state.progress + '%' }}>
              {this.state.progress}%
            </div>
          </div>
          : null
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  file: Object.values(state.file),
})

const mapDispatchToProps = dispatch => ({
  uploadFile: (data, docType) => dispatch(uploadFile(data, docType)),
  getFile: (id) => dispatch(getFile(id)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FileUpload)))

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import CommentList from '../../components/comment/CommentList'
import { fetchInstituteByUrl } from '../../services/InstituteService'
import InstructorHeading from '../../components/institute/InstituteHeading'
import InstructorInfo from '../../components/institute/InstituteInfo'

class InstituteDashboard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      institute: {},
    }
  }

  componentDidMount () {
    this.fetchData(this.props.match.params.instituteUrl)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.match.params.instituteUrl !== prevProps.match.params.instituteUrl) {
      this.fetchData(this.props.match.params.instituteUrl)
    }
  }

  fetchData (instituteUrl) {
    this.props.fetchInstructorByUrl(instituteUrl).then((response) => {
      this.setState({
        institute: response.data
      })
    })
  }

  render () {
    if (!this.state.institute.id) {
      return null
    }

    return (
      <div className="app-container">
        <InstructorHeading institute={this.state.institute}/>
        <div className="main-container">
          <InstructorInfo institute={this.state.institute}/>
          <CommentList instituteId={this.state.institute.id}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDisPatchToProps = dispatch => ({
  fetchInstructorByUrl: (url) => dispatch(fetchInstituteByUrl(url)),
})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(InstituteDashboard)))

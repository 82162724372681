import React from 'react'
import CourseItem from '../../components/course/CourseItem'
import { fetchSimilarCourses } from '../../services/CourseService'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import SingleLineTable from '../../components/table/SingleLineTable'

class SimilarCourse extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    this.props.fetchSimilarCourses(this.props.courseId)
  }

  render () {
    const { t } = this.props
    if (this.props.similar.length === 0) {
      return null
    }
    const items = this.props.similar.map((course, i) =>
      <div className="course-tile" key={i}>
        <CourseItem key={i} course={course}/>
      </div>
    )
    return (
      <div className="outer-course-likes">
        <div className="like-course-title">{t('home.similar.label')}</div>
        <SingleLineTable items={items}/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    similar: Object.values(state.similar)
  }
}

const mapDisPatchToProps = dispatch => ({
  fetchSimilarCourses: (courseId) => dispatch(fetchSimilarCourses(courseId))
})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(SimilarCourse)))

import * as API from './API'

export const filterContainers = (query) => (
  API.post(`labs/containers/filter`, query)
)

export const fetchContainer = (containerId) => (
  API.get(`labs/containers/${containerId}`)
)

export const releaseContainer = (containerId) => (
  API.post(`labs/containers/${containerId}/release`)
)

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { fetchAllComments } from '../../services/CommentService'
import CommentItem from './CommentItem'
import CommentBox from './CommentBox'

class CommentList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      order: 0,
      size: 20,
    }
    this.fetchMore = this.fetchMore.bind(this)
  }

  componentDidMount () {
    this.search()
  }

  fetchMore () {
    const currentPage = this.state.currentPage + 1
    this.setState({
      currentPage: currentPage
    }, () => {
      this.search()
    })
  }

  search () {
    const request = {
      query: {
        classId: this.props.classId ? this.props.classId : null,
        instituteId: this.props.instituteId ? this.props.instituteId : null,
      },
      page: this.state.currentPage - 1,
      size: this.state.size
    }
    this.props.fetchAllComments(request)
  }

  render () {
    const { t } = this.props
    if (!this.props.comments) {
      return null
    }

    const comments = this.props.comments.map((comment, i) => (
      <CommentItem comment={comment} key={i}/>
    ))
    return (
      <div className="comment-list-container">
        <CommentBox classId={this.props.classId} instituteId={this.props.instituteId}/>
        <div className="review">{comments}</div>
        <div className="see-more-b-box">
          {this.props.comments.length !== 0 ?
            <div onClick={this.fetchMore}
                 className={this.state.more ? 'see-more-review-button' : 'no-more-review-button'}>{t('label.moreReview')}
            </div>
            : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  comments: Object.values(state.comments),
})

const mapDispatchToProps = dispatch => ({
  fetchAllComments: (query) => dispatch(fetchAllComments(query)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CommentList)))

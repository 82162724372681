import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { DOC_TYPE_IMAGE } from '../../utils/Contants'
import PageEditor from '../../components/editor/PageEditor'
import { fetchAllMenus } from '../../services/MenuService'
import LanguageSelector from '../../components/common/LanguageSelector'
import { createInstitute, editOwnerInstitute, fetchOwnerInstitute } from '../../services/InstituteService'
import MultipleFileUpload from '../../components/file/MultipleFileUpload'

class Introduction extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      language: '',
      banners: [],
      name: '',
      introduction: '',
      status: '',
      edit: false,
    }
  }

  componentDidMount () {
    this.updateState()
  }

  updateState () {
    const path = this.props.match.path
    if (path === '/institute/introduction/page') {
      this.setState({
        edit: true
      })
      this.props.fetchOwnerInstructor().then(response => {
        this.setState({
          banners: response.institute.banners ? response.institute.banners : [],
          name: response.institute.name,
          introduction: response.institute.introduction,
          status: response.institute.status
        })
      })
    } else {
      this.setState({
        banners: [],
        name: '',
        introduction: '',
        status: '',
        edit: false,
      })
    }
  }

  selectLanguageHandle (e) {
    this.setState({
      language: e.target.value
    })
  }

  updateBanner (fileId) {
    let banners = this.state.banners
    banners.push(fileId)
    this.setState({
      banners: banners
    })
  }

  changeName (e) {
    this.setState({
      name: e.target.value
    })
  }

  changeIntroduction (introduction) {
    this.setState({
      introduction: introduction
    })
  }

  submitHandle (status) {
    const data = {
      banners: this.state.banners,
      name: this.state.name,
      introduction: this.state.introduction,
      status: status === '' ? null : status
    }
    if (this.state.edit) {
      this.props.editOwnerInstitute(data)
    } else {
      this.props.createInstitute(data)
    }
    this.props.fetchAllMenus()
    this.props.history.push('/institute/classes/dashboard')
  }

  renderButton () {
    const { t } = this.props
    if (this.state.edit) {
      return (
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this, '')} className="submit-button">
            {t('SAVE')}
          </button>
          {this.state.status === 'DRAFT' ? (
            <button onClick={this.submitHandle.bind(this, 'SUBMITTED')} className="submit-button">
              {t('SUBMIT')}
            </button>
          ) : null
          }
        </div>
      )
    } else {
      return (
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this, 'DRAFT')} className="submit-button">
            {t('SAVE DRAFT')}
          </button>
          <button onClick={this.submitHandle.bind(this, 'SUBMITTED')} className="submit-button">
            {t('SUBMIT')}
          </button>
        </div>
      )
    }
  }

  render () {
    const { t } = this.props
    return (
      <div>
        <div className="heading-creation-box">
          {t('management.institute.title')}
        </div>
        <div className="course-creation-item">
          <div className="input-box">
            <div className="course-creation-input-label">{t('label.language')}:</div>
            <LanguageSelector value={this.state.language} onChange={this.selectLanguageHandle.bind(this)}/>
          </div>
        </div>
        <div className="course-creation-item">
          <span>{t('label.banner')}:</span>
          <MultipleFileUpload docType={DOC_TYPE_IMAGE} onUpdateImage={this.updateBanner.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.institute.name')}:</span>
          <input className="course-creation-input-text" type="text" value={this.state.name}
                 onChange={this.changeName.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.institute.introduction')}:</span>
          <PageEditor data={this.state.introduction} onChange={this.changeIntroduction.bind(this)}/>
        </div>
        {this.renderButton()}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = dispatch => ({
  createInstitute: (data) => dispatch(createInstitute(data)),
  editOwnerInstitute: (instituteId, data) => dispatch(editOwnerInstitute(instituteId, data)),
  fetchOwnerInstructor: () => dispatch(fetchOwnerInstitute()),
  fetchAllMenus: () => dispatch(fetchAllMenus()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Introduction)))

import * as APIUtil from '../apis/InstituteApi'

export const RECEIVE_OWNER_INSTITUTE = 'RECEIVE_OWNER_INSTITUTE'
export const RECEIVE_INSTITUTE = 'RECEIVE_INSTITUTE'
export const RECEIVE_NAMES = 'RECEIVE_NAMES'

const receiveOwnerInstructor = institute => ({
  type: RECEIVE_OWNER_INSTITUTE,
  institute
})

const receiveInstitute = data => ({
  type: RECEIVE_INSTITUTE,
  data
})

const receiveNames = names => ({
  type: RECEIVE_NAMES,
  names
})

export const fetchOwnerInstitute = () => dispatch => (
  APIUtil.fetchOwnerInstitute()
    .then(response => dispatch(receiveOwnerInstructor(response.data)))
)

export const fetchInstitute = instituteId => dispatch => (
  APIUtil.fetchInstitute(instituteId)
    .then(response => dispatch(receiveInstitute(response.data)))
)

export const fetchInstituteByUrl = url => dispatch => (
  APIUtil.fetchInstituteByUrl(url)
    .then(response => dispatch(receiveInstitute(response.data)))
)

export const createInstitute = data => dispatch => (
  APIUtil.createInstitute(data)
    .then(response => dispatch(receiveInstitute(response.data)))
)

export const editOwnerInstitute = (instituteId, data) => dispatch => (
  APIUtil.editOwnerInstitute(instituteId, data)
    .then(response => dispatch(receiveInstitute(response.data)))
)

export const fetchInstructorNames = () => dispatch => (
  APIUtil.fetchInstructorNames()
    .then(response => dispatch(receiveNames(response.data)))
)

import { RECEIVE_CONTAINER, REMOVE_CONTAINER } from '../services/ContainerService'

export const ContainerReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_CONTAINER:
      nextState[action.data.id] = action.data
      return nextState
    case REMOVE_CONTAINER:
      delete nextState[action.connectionId]
      return nextState
    default:
      return state
  }
}

export default ContainerReducer

import React from 'react'
import CourseItem from '../../components/course/CourseItem'
import { withRouter } from 'react-router-dom'
import { fetchBestCourses, fetchMoreBestCourses } from '../../services/CourseService'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import MultipleLineTable from '../../components/table/MultipleLineTable'

class BestCourse extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      page: 0,
      size: 10,
      more: true
    }
  }

  componentDidMount () {
    this.props.fetchBestCourses(0, this.state.size)
  }

  seeMore () {
    const page = this.state.page + 1
    this.props.fetchMoreBestCourses(page, this.state.size).then((response) => {
      const more = response.courses.length > 0
      this.setState({
        more: more,
        page: page
      })
    })
  }

  exploreAll () {
    this.props.history.push('/courses/search/')
  }

  render () {
    const { t } = this.props
    if (this.props.courses.length === 0) {
      return null
    }

    const items = this.props.courses.map((x, i) =>
      <div key={i} className="course-tile">
        <CourseItem key={i} course={x}/>
      </div>
    )
    return (
      <div className="index">
        <div className="index-header-container">
          <div className="index-header">{t('home.title.course')}</div>
        </div>
        <MultipleLineTable items={items} more={this.state.more}
                           onSeeMore={this.seeMore.bind(this)} onExploreAll={this.exploreAll.bind(this)}/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  courses: Object.values(state.best)
})

const mapDisPatchToProps = dispatch => ({
  fetchBestCourses: (page, size) => dispatch(fetchBestCourses(page, size)),
  fetchMoreBestCourses: (page, size) => dispatch(fetchMoreBestCourses(page, size))
})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(BestCourse)))

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { createClass, editClass, fetchClass } from '../../../services/ClassService'
import FileUpload from '../../../components/file/FileUpload'
import { DOC_TYPE_IMAGE } from '../../../utils/Contants'
import { fetchAllCategories } from '../../../services/CategoryService'
import { fetchAllTopics } from '../../../services/TopicService'
import PageEditor from '../../../components/editor/PageEditor'
import { addNotification } from '../../../utils/CommonUtils'
import ClassCalendar from '../../../components/classes/ClassCalendar'
import { fetchOwnerLaboratories } from '../../../services/LaboratoryService'

class ClassCreation extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      categoryId: '',
      topicId: '',
      laboratoryId: '',
      image: '',
      banner: '',
      name: '',
      description: '',
      content: '',
      status: '',
      edit: false,
      calendar: {},
    }
  }

  componentDidMount () {
    this.props.fetchAllCategories()
    this.props.fetchAllTopics(this.state.categoryId)
    this.props.fetchOwnerLaboratories({})
    this.updateState()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.match.params.classId !== prevProps.match.params.classId) {
      this.updateState()
    }
  }

  updateState () {
    const classId = this.props.match.params.classId
    if (classId) {
      this.setState({
        edit: true
      })
      this.props.fetchClass(classId).then(response => {
        this.setState({
          categoryId: response.data.categoryId,
          topicId: response.data.topicId,
          laboratoryId: response.data.laboratoryId,
          image: response.data.image,
          banner: response.data.banner,
          name: response.data.name,
          description: response.data.description,
          content: response.data.content,
          status: response.data.status,
          calendar: response.data.calendar,
        })
      })
    } else {
      this.setState({
        categoryId: '',
        topicId: '',
        laboratoryId: '',
        image: '',
        banner: '',
        name: '',
        description: '',
        content: '',
        status: '',
        edit: false,
        calendar: {},
      })
    }
  }

  changeCategoryHandle (e) {
    this.setState({
      categoryId: e.target.value
    })
    this.props.fetchAllTopics(e.target.value)
  }

  changeTopicHandle (e) {
    this.setState({
      topicId: e.target.value
    })
  }
  changeLaboratoryHandle (e) {
    this.setState({
      laboratoryId: e.target.value
    })
  }

  updateImage (fileId) {
    this.setState({
      image: fileId
    })
  }

  updateBanner (fileId) {
    this.setState({
      banner: fileId
    })
  }

  changeName (e) {
    this.setState({
      name: e.target.value
    })
  }

  changeDescription (e) {
    this.setState({
      description: e.target.value
    })
  }

  changeCalendar (data) {
    this.setState({
      calendar: data
    })
  }

  changeContent (content) {
    this.setState({
      content: content
    })
  }

  submitHandle (status) {
    const { t } = this.props
    const data = {
      categoryId: this.state.categoryId,
      topicId: this.state.topicId,
      laboratoryId: this.state.laboratoryId,
      image: this.state.image,
      banner: this.state.banner,
      name: this.state.name,
      description: this.state.description,
      content: this.state.content,
      status: status === '' ? null : status,
      calendar: this.state.calendar,
    }
    const classId = this.props.match.params.classId
    if (classId) {
      this.props.editClass(classId, data)
    } else {
      this.props.createClass(data)
    }
    addNotification(t('notification.class.create'))
    this.props.history.push('/institute/classes/dashboard')
  }

  renderButton () {
    const { t } = this.props
    if (this.state.edit) {
      return (
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this, '')} className="submit-button">
            {t('SAVE')}
          </button>
          {this.state.status === 'DRAFT' ? (
            <button onClick={this.submitHandle.bind(this, 'SUBMITTED')} className="submit-button">
              {t('SUBMIT')}
            </button>
          ) : null
          }
        </div>
      )
    } else {
      return (
        <div className="main-button-panel">
          <button onClick={this.submitHandle.bind(this, 'DRAFT')} className="submit-button">
            {t('SAVE DRAFT')}
          </button>
          <button onClick={this.submitHandle.bind(this, 'SUBMITTED')} className="submit-button">
            {t('SUBMIT')}
          </button>
        </div>
      )
    }
  }

  render () {
    const { t } = this.props
    const categories = this.props.categories.map((category, i) => (
      <option key={i} value={category.id}>{t(`${category.name}`)}</option>
    ))
    const topics = this.props.topics.map((topic, i) => (
      <option key={i} value={topic.id}>{t(`${topic.name}`)}</option>
    ))
    const laboratories = this.props.laboratories[0]?.data.map((laboratory, i) => (
      <option key={i} value={laboratory.id}>{t(`${laboratory.name}`)}</option>
    ))
    return (
      <div>
        <div className="heading-creation-box">
          {t('management.classes.title.create')}
        </div>
        <div className="course-creation-item">
          <div className="input-box">
            <div className="course-creation-input-label">{t('label.category')}:</div>
            <select className="course-creation-input-select"
                    onChange={this.changeCategoryHandle.bind(this)} value={this.state.categoryId}>
              <option value="">{t('selection.default')}</option>
              {categories}
            </select>
          </div>
          <div className="input-box">
            <div className="course-creation-input-label">{t('label.topic')}:</div>
            <select className="course-creation-input-select" value={this.state.topicId}
                    onChange={this.changeTopicHandle.bind(this)}>
              <option value="">{t('selection.default')}</option>
              {topics}
            </select>
          </div>
        </div>
        <div className="course-creation-item">
          <span>{t('label.image')}:</span>
          <FileUpload docType={DOC_TYPE_IMAGE} onUpdateImage={this.updateImage.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('label.banner')}:</span>
          <FileUpload docType={DOC_TYPE_IMAGE} onUpdateImage={this.updateBanner.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.classes.name')}:</span>
          <input className="course-creation-input-text" type="text" value={this.state.name}
                 onChange={this.changeName.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.classes.description')}:</span>
          <textarea className="learning-goals-textarea" value={this.state.description || ''}
                    onChange={this.changeDescription.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.classes.calendar')}:</span>
          <ClassCalendar data={this.state.calendar} onChange={this.changeCalendar.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.classes.content')}:</span>
          <PageEditor data={this.state.content} onChange={this.changeContent.bind(this)}/>
        </div>
        <div className="course-creation-item">
          <span>{t('management.classes.laboratory')}:</span>
          <select className="course-creation-input-select" value={this.state.laboratoryId}
                  onChange={this.changeLaboratoryHandle.bind(this)}>
            <option value="">{t('selection.default')}</option>
            {laboratories}
          </select>
        </div>
        {this.renderButton()}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  topics: Object.values(state.topics),
  categories: Object.values(state.categories),
  laboratories: Object.values(state.laboratories),
})

const mapDispatchToProps = dispatch => ({
  createClass: (data) => dispatch(createClass(data)),
  editClass: (classId, data) => dispatch(editClass(classId, data)),
  fetchClass: (classId) => dispatch(fetchClass(classId)),
  fetchAllCategories: () => dispatch(fetchAllCategories()),
  fetchAllTopics: (categoryId) => dispatch(fetchAllTopics(categoryId)),
  fetchOwnerLaboratories: (query) => dispatch(fetchOwnerLaboratories(query)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClassCreation)))

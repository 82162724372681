import React from 'react'

import HomeSearchPanel from '../../components/search/SearchPanel'
import BestCourse from '../course/BestCourse'
import CategoryList from '../category/CategoryList'
import TopicList from '../topic/TopicList'
import SuggestionList from '../course/SuggestionCourse'
import BecomeInstructor from '../../components/institute/BecomeInstitute'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import BestClass from '../classes/BestClass'

class Home extends React.Component {
  constructor (props) {
    super(props)

  }

  render () {
    return (
      <div>
        <HomeSearchPanel/>
        <BestClass/>
        <BestCourse/>
        <SuggestionList/>
        <CategoryList/>
        <TopicList/>
        <BecomeInstructor/>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})
const mapDisPatchToProps = dispatch => ({})
export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(Home)))

import * as API from './API'

export const signup = user => (
  API.post(`auth/signup`, user)
)
export const login = user => (
  API.post(`auth/login`, user)
)
export const userInfo = () => (
  API.get(`/auth/me`)
)

export const logout = () => (
  API.post(`auth/logout`)
)

export const init = () => {
  API.post(`auth/init`)
}

import { RECEIVE_ALL_MENUS } from '../services/MenuService'

const MenuReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_ALL_MENUS:
      nextState = action.menus
      return nextState
    default:
      return state
  }
}

export default MenuReducer

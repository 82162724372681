import * as API from './API'

export const fetchAllTopics = (categoryId) => (
  categoryId ? API.get(`topics?categoryId=${categoryId}`) : API.get(`topics`)
)

export const fetchTopic = topicId => (
  API.get(`topics/${topicId}`)
)

export const fetchTopTopics = () => (
  API.get('topics/top')
)

export const createTopic = (topic) => (
  API.post(`topics`, topic)
)

export const editTopic = (topicId, topic) => (
  API.put(`topics/${topicId}`, topic)
)

export const deleteTopic = (topicId) => (
  API.remove(`topics/${topicId}`)
)


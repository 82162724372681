import { combineReducers } from 'redux'

import entities from './EntityReducer'
import session from './AuthenticationReducer'
import errors from './ErrorReducer'
import {
  BestCourseReducer as best,
  CourseReducer as course,
  ListCourseReducer as courses,
  SimilarReducer as similar,
  SuggestionReducer as suggestions
} from './CourseReducer'
import contents from './ContentReducer'
import reviews from './ReviewReducer'
import { ListVideoReducer as videos, VideoReducer as video } from './VideoReducer'
import player from './PlayerReducer'
import categories from './CategoryReducer'
import { TopicReducer as topics, TopTopicReducer as topTopics } from './TopicReducer'
import menus from './MenuReducer'
import file from './FileReducer'
import { InstituteReducer as institute, InstructorNameReducer as instructorName } from './InstituteReducer'
import {
  BestClassReducer as bestClasses,
  ClassReducer as classes,
  InstructorReducer as instructorClasses,
  ReviewReducer as reviewClasses,
  SimilarReducer as similarClasses,
} from './ClassReducer'
import { MyClassReducer as myClasses, MyCourseReducer as myCourses } from './LearningReducer'
import comments from './CommentReducer'
import documents from './DocumentReducer'
import registers from './RegisterReducer'
import feedbacks from './FeedbackReducer'
import instructors from './InstructorReducer'
import laboratories from './LaboratoryReducer'
import configuration from './ConfigurationReducer'
import { ConnectionReducer as connection, ConnectionsReducer as connections } from './ConnectionReducer'
import machines from './MachineReducer'
import images from './ImageReducer'
import clients from './ClientReducer'
import containers from './ContainerReducer'

const rootReducer = combineReducers({
  entities,
  session,
  errors,
  course, best, suggestions, similar, courses, myCourses,
  contents,
  reviews,
  videos, video,
  player,
  categories,
  topics, topTopics,
  menus,
  file,
  instructorName, institute, instructors,
  classes, bestClasses, similarClasses, reviewClasses, instructorClasses, myClasses,
  comments,
  documents,
  registers,
  feedbacks,
  laboratories, connection, connections, configuration, machines, images, clients, containers
})

export default rootReducer

import * as Session from '../utils/SessionUtil'
import axios from 'axios'
import { addNotification } from '../utils/CommonUtils'
import i18next from 'i18next'

const BASE_URL = process.env.BASE_URL

const API = axios.create({
  baseURL: BASE_URL,
  crossdomain: true,
})

const getConfig = () => (
  {
    headers: {
      'Authorization': Session.getToken() ? `Bearer ${Session.getToken()}` : ''
    }
  }
)

const getUploadConfig = () => (
  {
    headers: {
      'Authorization': Session.getToken() ? `Bearer ${Session.getToken()}` : '',
      'Content-Type': 'multipart/form-data'
    }
  }
)

const getDownloadConfig = () => (
  {
    headers: {
      'Authorization': Session.getToken() ? `Bearer ${Session.getToken()}` : '',
    },
    responseType: 'blob'
  }
)

export const get = (url) => (
  API.get(url, getConfig())
).catch(error => errorHandler(error))

export const post = (url, data) => (
  API.post(url, data, getConfig())
).catch(error => errorNotificationHandler(error))

export const put = (url, data) => (
  API.put(url, data, getConfig())
).catch(error => errorNotificationHandler(error))

export const remove = (url) => (
  API.delete(url, getConfig())
).catch(error => errorNotificationHandler(error))

export const upload = (url, data) => (
  API.post(url, data, getUploadConfig())
).catch(error => errorNotificationHandler(error))

export const download = (url) => (
  API.get(url, getDownloadConfig())
).catch(error => errorNotificationHandler(error))

const errorHandler = (error) => {
  if (error.response.status === 401) {
    Session.removeToken()
  }
}

const errorNotificationHandler = (error) => {
  if (error.response.status === 401) {
    Session.removeToken()
  }

  addNotification(i18next.t('notification.error.default'), 'danger')
}

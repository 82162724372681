import React from 'react'
import { withTranslation } from 'react-i18next'
import './classes.css'
import CalendarComponent from '../calendar/CalendarComponent'

class MyClassInfo extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    if (!this.props.class) {
      return null
    }

    const { t } = this.props
    return (
      <div className="class-box-container">
        <div className="class-item-header">{t('label.classInfo')}</div>
        <div className="class-box-item">
          <span>{t('label.members')}</span>
          <span className="index-rating-span">({this.props.class.memberNumber} {t('members')}):</span>
          <span className="index-rating-span">{this.props.class.members.join(', ')}</span>
        </div>
        <div className="class-box-item">
          <span>{t('management.classes.calendar')}</span>
          <CalendarComponent calendar={this.props.class.calendar}/>
        </div>
      </div>
    )
  }
}

export default withTranslation()(MyClassInfo)

import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

class MyClassLaboratory extends React.Component {
  constructor (props) {
    super(props)
  }

  joinLaboratory () {
    this.props.history.push(`/instructor/laboratories/preview/${this.props.class.laboratoryId}`)
  }

  render () {
    if (!this.props.class) {
      return null
    }

    const { t } = this.props
    return (
      <div className="class-box-container">
        <div className="class-item-header">{t('label.classLaboratory')}</div>
        <div className="button-panel">
          {this.props.class.laboratoryId ? (
            <button className="class-meeting-button" onClick={this.joinLaboratory.bind(this)}>
              {t('JOIN LABORATORY')}
            </button>
          ) : null}
        </div>
      </div>
    )
  }
}

export default withRouter(withTranslation()(MyClassLaboratory))

import { LOGOUT_CURRENT_USER, RECEIVE_CURRENT_USER, } from '../services/SessionService'

const _nullUser = Object.freeze({
  id: null
})

const AuthenticationReducer = (state = _nullUser, action) => {
  Object.freeze(state)
  switch (action.type) {
    case RECEIVE_CURRENT_USER:
      return {
        id: action.currentUser.id,
        roles: action.currentUser.roles,
        avatar: action.currentUser.avatar
      }
    case LOGOUT_CURRENT_USER:
      return _nullUser
    default:
      return state
  }
}

export default AuthenticationReducer

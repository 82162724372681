import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import '../laboratory.css'
import { detailConnection, releaseConnection } from '../../../../services/ConnectionService'

class ConnectionDetail extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    const connectionId = this.props.match.params.connectionId
    this.props.detailConnection(connectionId)
  }

  back (e) {
    this.props.history.push('/admin/connections/management')
  }

  release (e) {
    const connectionId = this.props.match.params.connectionId
    this.props.releaseConnection(connectionId)
    this.props.history.push('/admin/connections/management')
  }

  render () {
    const { t } = this.props
    const connectionId = this.props.match.params.connectionId
    const connections = this.props.connections.length > 0 ? this.props.connections[0]?.data.filter(function (item) {return item.id === connectionId}) : []
    const connection = connections.length > 0 ? connections[0] : {}

    return (
      <div>
        <div className="heading-creation-box">
          {t('management.connection.title.detail')}
        </div>
        <div>
          <button onClick={this.back.bind(this)} className="submit-button">
            {t('BACK')}
          </button>
          <button onClick={this.release.bind(this)} className="submit-button">
            {t('RELEASE')}
          </button>
        </div>
        <div className="item-detail">
          <span className="item-detail-label">{t('management.connection.user')}:</span>
          <span>{connection.service?.credential?.userName}</span>
        </div>
        <div className="item-detail">
          <span className="item-detail-label">{t('management.connection.createdDate')}:</span>
          <span>{connection.createdDate}</span>
        </div>
        <div className="item-detail">
          <span className="item-detail-label">{t('management.connection.createdDate')}:</span>
          <span>{connection.lastModifiedDate}</span>
        </div>
        <div className="item-detail">
          <span className="item-detail-label">{t('management.connection.laboratory')}:</span>
          <span>{connection.laboratoryName}</span>
        </div>
        <div className="item-detail">
          <span className="item-detail-label">{t('management.connection.container')}:</span>
          <span>{connection.containerName}</span>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  connections: Object.values(state.connections),
})

const mapDispatchToProps = dispatch => ({
  detailConnection: (connectionId) => dispatch(detailConnection(connectionId)),
  releaseConnection: (connectionId) => dispatch(releaseConnection(connectionId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConnectionDetail)))

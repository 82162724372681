import React from 'react'
import CourseItem from '../../components/course/CourseItem'
import { withRouter } from 'react-router-dom'
import { fetchSuggestionCourses } from '../../services/CourseService'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import SingleLineTable from '../../components/table/SingleLineTable'

class SuggestionCourse extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    this.props.fetchAllSuggestions()
  }

  render () {
    const { t } = this.props
    if (this.props.suggestions.length === 0) {
      return null
    }
    const items = this.props.suggestions.map((x, i) =>
      <div key={i} className="course-tile">
        <CourseItem key={i} course={x}/>
      </div>
    )
    return (
      <div className="index">
        <div className="index-header-container">
          <div className="index-header">{t('home.suggestion.label')}</div>
        </div>
        <SingleLineTable items={items}/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  suggestions: Object.values(state.suggestions)
})

const mapDisPatchToProps = dispatch => ({
  fetchAllSuggestions: () => dispatch(fetchSuggestionCourses())
})

export default withRouter(connect(mapStateToProps, mapDisPatchToProps)(withTranslation()(SuggestionCourse)))

import { HIDE_PLAYER, SHOW_PLAYER } from '../services/VideoService'

const defaultPlayer = {
  contentId: null,
  index: null,
  show: false
}

const playerReducer = (state = defaultPlayer, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case SHOW_PLAYER:
      nextState.show = true
      nextState.index = action.index
      nextState.contentId = action.contentId
      return nextState
    case HIDE_PLAYER:
      nextState = defaultPlayer
      return nextState
    default:
      return state
  }
}

export default playerReducer

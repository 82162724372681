import React from 'react'
import { withRouter } from 'react-router-dom'
import ReviewItem from '../../components/review/ReviewItem'
import ReviewCreation from './ReviewCreation'
import { deleteReview, fetchAllReviews, updateReview } from '../../services/ReviewService'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

class ReviewList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      offset: 0,
      more: true
    }
    this.fetchMore = this.fetchMore.bind(this)
  }

  componentDidMount () {
    this.props.fetchAllReviews(this.props.courseId, this.state.offset)
  }

  fetchMore () {
    let that = this
    this.setState({
      offset: this.state.offset + 4
    }, () => {
      this.props.fetchAllReviews(this.props.courseId, this.state.offset)
        .then(() => {
          if (that.props.reviews % 4 !== 0) {
            that.setState({
              more: false
            })
          }
        })
    })
  }

  render () {
    const { t } = this.props
    if (this.props.reviews) {
      const reviewDisplay = this.props.reviews.map((review, i) => (
        <ReviewItem review={review} key={i} userId={this.props.userId} courseId={review.courseId}
                    deleteReview={this.props.deleteReview}
                    updateReview={this.props.updateReview}/>
      ))
      return (
        <div>
          {this.props.reviews.length !== 0 ? <div className="review-header">{t('label.review')}</div> : null}
          <div className="review">{reviewDisplay}</div>
          <div className="see-more-b-box">
            {this.props.reviews.length !== 0 ?
              <div onClick={this.fetchMore}
                   className={this.state.more ? 'see-more-review-button' : 'no-more-review-button'}>{t('label.moreReview')}
              </div>
              : null}
          </div>
          <ReviewCreation courseId={this.props.courseId}/>
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  reviews: Object.values(state.reviews),
  userId: state.session.id
})

const mapDispatchToProps = dispatch => ({
  fetchAllReviews: (courseId, offset) => dispatch(fetchAllReviews(courseId, offset)),
  deleteReview: (reviewId) => dispatch(deleteReview(reviewId)),
  updateReview: (review, reviewId) => dispatch(updateReview(review, reviewId))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReviewList)))

import React from 'react'
import { withRouter } from 'react-router-dom'
import { faMinus, faPlayCircle, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fetchAllVideos, showPlayer } from '../../services/VideoService'
import { connect } from 'react-redux'
import * as CommonUtils from '../../utils/CommonUtils'

class ContentItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      dropdown: false
    }
    this.showOrHidden = this.showOrHidden.bind(this)
    this.titleClickHandle = this.titleClickHandle.bind(this)
  }

  componentDidMount () {
    this.props.fetchAllVideos(this.props.match.params.courseId, this.props.content.id)
  }

  componentDidUpdate (prevProps) {
    if (this.props.content.id !== prevProps.content.id) {
      this.props.fetchAllVideos(this.props.match.params.courseId, this.props.content.id)
    }
  }

  showOrHidden () {
    if (this.state.dropdown) {
      return 'content-show'
    } else {
      return 'content-hidden'
    }
  }

  titleClickHandle () {
    this.setState({
      dropdown: !this.state.dropdown
    })
  }

  render () {
    if (this.props.content) {
      return (
        <div onClick={this.titleClickHandle}>
          <div className="content-box">
            {this.state.dropdown ? <FontAwesomeIcon icon={faMinus} className="plus-minus"/>
              : <FontAwesomeIcon className="plus-minus" icon={faPlus}/>}
            <div className="content-title">{this.props.content.title}</div>
          </div>
          {this.props.content.videos
            ? Object.values(this.props.content.videos).map((v, i) => (
              <div key={i} className={this.showOrHidden()}>
                <div className="content-video-list"
                     onClick={() => this.props.showPlayer(v.index, this.props.content.id)}>
                  <div>
                    <FontAwesomeIcon icon={faPlayCircle}/>
                    <span style={{ marginLeft: '11px' }}>{v.title}</span>
                  </div>
                  <div>{CommonUtils.getDuration(v.duration)}</div>
                </div>
              </div>
            ))
            : null
          }
        </div>
      )
    } else {
      return null
    }

  }
}

const mapStateToProps = state => ({
  videos: state.videos
})

const mapDispatchToProps = dispatch => ({
  fetchAllVideos: (courseId, contentId) => dispatch(fetchAllVideos(courseId, contentId)),
  showPlayer: (index, contentId) => dispatch(showPlayer(index, contentId))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentItem))

import * as APIUtil from '../apis/MachineApi'

export const RECEIVE_MACHINE = 'RECEIVE_MACHINE'
export const REMOVE_MACHINE = 'REMOVE_MACHINE'

const receiveMachines = data => ({
  type: RECEIVE_MACHINE,
  data
})

const removeMachine = machineId => ({
  type: REMOVE_MACHINE,
  machineId
})

export const fetchMachine = machineId => dispatch => (
  APIUtil.fetchMachine(machineId)
    .then(response => dispatch(receiveMachines(response.data)))
)

export const createMachine = data => dispatch => (
  APIUtil.createMachine(data)
    .then(response => dispatch(receiveMachines(response.data)))
)

export const editMachine = (machineId, data) => dispatch => (
  APIUtil.editMachine(machineId, data)
    .then(response => dispatch(receiveMachines(response.data)))
)

export const deleteMachine = machineId => dispatch => (
  APIUtil.deleteMachine(machineId)
    .then(() => dispatch(removeMachine(machineId)))
)

export const filterMachines = (query) => dispatch => (
  APIUtil.filterMachines(query)
    .then(response => dispatch(receiveMachines(response.data)))
)

import React from 'react'
import { withTranslation } from 'react-i18next'
import { fetchAllTopics } from '../../services/TopicService'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './Filter.css'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ClassFilter extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      topics: [],
      rating: null,
      languages: [],
      topicExpand: false,
    }
  }

  componentDidMount () {
    this.props.fetchAllTopics()
  }

  changeTopic (selection) {
    let topics = this.state.topics
    if (topics.includes(selection)) {
      topics.splice(topics.indexOf(selection), 1)
    } else {
      topics.push(selection)
    }

    this.setState({
      topics: topics
    }, () => (this.filter()))
  }

  filter () {
    const query = {
      queryIn: {
        topicId: this.state.topics
      }
    }

    this.props.onFilter(query)
  }

  expendHandle (type) {
    switch (type) {
      case 'TOPIC':
        this.setState({
          topicExpand: !this.state.topicExpand
        })
        break
    }
  }

  render () {
    const topics = this.props.topics.map((topic, i) => (
      <label key={i}>
        <input type="checkbox" checked={this.state.topics.includes(topic.id)}
               onChange={this.changeTopic.bind(this, topic.id)}/> {topic.name}
      </label>
    ))
    return (
      <div className="filter-box">
        <div className="filter-box">
          <div className="filter-item-header" onClick={this.expendHandle.bind(this, 'TOPIC')}>
            <div>
              {this.props.t('label.topic')}
            </div>
            <div>
              {this.state.topicExpand ? (
                <FontAwesomeIcon icon={faAngleUp}/>
              ) : (
                <FontAwesomeIcon icon={faAngleDown}/>
              )}
            </div>
          </div>
          {this.state.topicExpand ? (
            <div className="filter-item-list">
              {topics}
            </div>
          ) : null
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  topics: Object.values(state.topics),
})
const mapDispatchToProps = dispatch => ({
  fetchAllTopics: () => dispatch(fetchAllTopics('')),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClassFilter)))

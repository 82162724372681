import * as APIUtil from '../apis/RegisterApi'

export const RECEIVE_REVIEW_REGISTERS = 'RECEIVE_REVIEW_REGISTERS'
export const RECEIVE_REGISTER = 'RECEIVE_REGISTER'

const receiveReviewRegisters = registers => ({
  type: RECEIVE_REVIEW_REGISTERS,
  registers
})

const receiveRegister = data => ({
  type: RECEIVE_REGISTER,
  data
})

export const createRegister = data => dispatch => (
  APIUtil.createRegister(data)
    .then(response => dispatch(receiveRegister(response.data)))
)

export const reviewRegister = (id, data) => dispatch => (
  APIUtil.reviewRegister(id, data)
    .then(response => dispatch(receiveRegister(response.data)))
)

export const fetchReviewRegisters = (query) => dispatch => (
  APIUtil.fetchReviewRegisters(query)
    .then(response => dispatch(receiveReviewRegisters(response.data)))
)

import { RECEIVE_ALL_REVIEWS, RECEIVE_REVIEW, REMOVE_REVIEW } from '../services/ReviewService'

const ReviewReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_ALL_REVIEWS:
      let newState = Object.assign(nextState, action.reviews)
      return newState
    case RECEIVE_REVIEW:
      nextState[action.review.id] = action.review
      return nextState
    case REMOVE_REVIEW:
      delete nextState[action.reviewId]
      return nextState
    default:
      return state
  }
}

export default ReviewReducer

import * as APIUtil from '../apis/TopicApi'

export const RECEIVE_ALL_TOPICS = 'RECEIVE_ALL_TOPICS'
export const RECEIVE_TOPIC = 'RECEIVE_TOPIC'
export const RECEIVE_TOP_TOPIC = 'RECEIVE_TOP_TOPIC'
export const REMOVE_TOPIC = 'REMOVE_TOPIC'

const receiveAllTopics = topics => ({
  type: RECEIVE_ALL_TOPICS,
  topics
})

const receiveTopTopics = topics => ({
  type: RECEIVE_TOP_TOPIC,
  topics
})

const receiveTopic = topic => ({
  type: RECEIVE_TOPIC,
  topic
})

const removeTopic = topicId => ({
  type: REMOVE_TOPIC,
  topicId
})

export const fetchAllTopics = (categoryId) => dispatch => (
  APIUtil.fetchAllTopics(categoryId)
    .then(topics => dispatch(receiveAllTopics(topics.data)))
)

export const fetchTopTopics = () => dispatch => (
  APIUtil.fetchTopTopics()
    .then(topics => dispatch(receiveTopTopics(topics.data)))
)

export const fetchTopic = topicId => dispatch => (
  APIUtil.fetchTopic(topicId)
    .then(topic => dispatch(receiveTopic(topic.data)))
)

export const createTopic = topic => dispatch => (
  APIUtil.createTopic(topic)
    .then(response => dispatch(receiveTopic(response.data)))
)

export const editTopic = (topicId, topic) => dispatch => (
  APIUtil.editTopic(topicId, topic)
    .then(response => dispatch(receiveTopic(response.data)))
)

export const deleteTopic = (topicId) => dispatch => (
  APIUtil.deleteTopic(topicId)
    .then(() => dispatch(removeTopic(topicId)))
)

import * as API from './API'

export const fetchMachine = machineId => (
  API.get(`labs/machines/${machineId}`)
)

export const createMachine = (data) => (
  API.post(`labs/machines`, data)
)

export const editMachine = (machineId, data) => (
  API.put(`labs/machines/${machineId}`, data)
)

export const deleteMachine = (machineId) => (
  API.remove(`labs/machines/${machineId}`)
)

export const filterMachines = (query) => (
  API.post(`labs/machines/filter`, query)
)

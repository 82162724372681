import * as API from './API'

export const createInstructor = (data) => (
  API.post(`instructors`, data)
)

export const updateInstructor = (instructorId, data) => (
  API.put(`instructors/${instructorId}`, data)
)

export const fetchInstructor = instructorId => (
  API.get(`instructors/${instructorId}`)
)

export const fetchAllInstructors = () => (
  API.get(`instructors`)
)

export const deleteInstructor = (instructorId) => (
  API.remove(`instructors/${instructorId}`)
)

import { RECEIVE_ALL_TOPICS, RECEIVE_TOP_TOPIC, RECEIVE_TOPIC, REMOVE_TOPIC } from '../services/TopicService'

export const TopicReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_ALL_TOPICS:
      nextState = action.topics
      return nextState
    case RECEIVE_TOPIC:
      nextState[action.topic.id] = action.topic
      return nextState
    case REMOVE_TOPIC:
      delete nextState[action.topicId]
      return nextState
    default:
      return state
  }
}

export const TopTopicReducer = (state = {}, action) => {
  Object.freeze(state)
  let nextState = Object.assign({}, state)

  switch (action.type) {
    case RECEIVE_TOP_TOPIC:
      nextState = action.topics
      return nextState
    default:
      return state
  }
}

import React from 'react'
import { connect } from 'react-redux'

import CourseHeading from '../../components/course/CourseHeading'
import ContentIndex from '../../components/content/ContentIndex'
import ReviewList from '../review/ReviewList'
import VideoDetail from '../../components/video/VideoDetail'
import SimilarCourse from './SimilarCourse'
import { fetchCourse, fetchCourseByUrl, viewCourse } from '../../services/CourseService'
import { showPlayer } from '../../services/VideoService'
import { fetchContents } from '../../services/ContentService'
import CourseInfo from '../../components/course/CourseInfo'
import { addCourse } from '../../services/LearningService'

class CourseDetail extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      courseId: ''
    }
  }

  componentDidMount () {
    this.fetchData(this.props.match.params.courseId)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.match.params.courseId !== prevProps.match.params.courseId) {
      this.fetchData(this.props.match.params.courseId)
    }
  }

  fetchData (url) {
    window.scrollTo(0, 0)
    this.props.fetchCourseByUrl(url).then((response) => {
      const courseId = response.course.id
      this.props.fetchContents(courseId)
      this.props.viewCourse(courseId)
      this.setState({
        courseId: courseId
      })
    })
  }

  addCourse () {
    const request = {
      courseId: this.state.courseId
    }
    this.props.addCourse(request)
  }

  render () {
    const video = this.props.contents.length > 0 ? this.props.contents[0].videos[0] : null
    return (
      <div className="app-container">
        <CourseHeading course={this.props.course} video={video} showPlayer={this.props.showPlayer}
                       onAddCourse={this.addCourse.bind(this)}/>
        <div className="main-container">
          <CourseInfo course={this.props.course}/>
          <ContentIndex contents={this.props.contents}/>
          <ReviewList courseId={this.state.courseId}/>
          {this.state.courseId ? <SimilarCourse courseId={this.state.courseId}/> : null}
        </div>
        {
          this.props.player.show ? <VideoDetail/> : null
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  player: state.player,
  course: state.course,
  contents: Object.values(state.contents),
  videos: Object.values(state.videos),
})

const mapDisPatchToProps = dispatch => ({
  fetchCourse: (courseId) => dispatch(fetchCourse(courseId)),
  fetchCourseByUrl: (url) => dispatch(fetchCourseByUrl(url)),
  showPlayer: (index, contentId) => dispatch(showPlayer(index, contentId)),
  fetchContents: courseId => dispatch(fetchContents(courseId)),
  viewCourse: (courseId) => dispatch(viewCourse(courseId)),
  addCourse: (data) => dispatch(addCourse(data)),
})

export default connect(mapStateToProps, mapDisPatchToProps)(CourseDetail)

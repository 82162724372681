import * as API from './API'

export const createRegister = (data) => (
  API.post(`registers`, data)
)

export const reviewRegister = (id, data) => (
  API.post(`registers/${id}/review`, data)
)

export const fetchReviewRegisters = (query) => (
  API.post(`registers/review`, query)
)

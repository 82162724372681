import React from 'react'
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MAX_FIRST_NUMBERS = 4

class PagingNumber extends React.Component {
  constructor (props) {
    super(props)
  }

  changePage (page) {
    if (this.props.currentPage === page) {
      return
    }
    this.props.onChangePage(page)
  }

  previousPage () {
    const page = Math.max(this.props.currentPage - 1, 1)
    this.changePage(page)
  }

  nextPage () {
    const page = Math.min(this.props.currentPage + 1, this.props.totalPage)
    this.changePage(page)
  }

  renderPageNumber (number) {
    const className = number === this.props.currentPage ? 'page-number-active' : 'page-number'
    return (
      <div onClick={this.changePage.bind(this, number)} key={number} className={className}>{number}</div>
    )
  }

  renderMorePage () {
    return (
      <div>...</div>
    )
  }

  render () {
    let paging = []
    paging.push(this.renderPageNumber(1))
    if (this.props.currentPage <= MAX_FIRST_NUMBERS) {
      const maxFirstNumbers = Math.min(MAX_FIRST_NUMBERS, this.props.totalPage - 1)
      for (let index = 2; index <= maxFirstNumbers; index++) {
        paging.push(this.renderPageNumber(index))
      }
    } else {
      paging.push(this.renderMorePage())
      if (this.props.currentPage > MAX_FIRST_NUMBERS + 1) {
        paging.push(this.renderPageNumber(this.props.currentPage - 1))
      }
      if (this.props.currentPage !== 1 && this.props.currentPage !== this.props.totalPage) {
        paging.push(this.renderPageNumber(this.props.currentPage))
      }
      if (this.props.currentPage < this.props.totalPage - 1) {
        paging.push(this.renderPageNumber(this.props.currentPage + 1))
      }
    }

    if (this.props.currentPage < this.props.totalPage - 2) {
      paging.push(this.renderMorePage())
    }
    if (this.props.totalPage > 1) {
      paging.push(this.renderPageNumber(this.props.totalPage))
    }

    return (
      <div className="page-switch">
        <div onClick={this.previousPage.bind(this)}>
          <FontAwesomeIcon style={{ color: '#007791' }} className="fa-2x" icon={faChevronCircleLeft}/>
        </div>
        {paging}
        <div className="paging-next" onClick={this.nextPage.bind(this)}>
          <FontAwesomeIcon style={{ color: '#007791' }} className="fa-2x" icon={faChevronCircleRight}/>
        </div>
      </div>
    )
  }
}

export default PagingNumber

import * as APIUtil from '../apis/SessionApi'
import * as Session from '../utils/SessionUtil'

export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER'
export const LOGOUT_CURRENT_USER = 'LOGOUT_CURRENT_USER'
export const RECEIVE_SESSION_ERRORS = 'RECEIVE_SESSION_ERRORS'
export const REMOVE_SESSION_ERRORS = 'REMOVE_SESSION_ERRORS'
export const RECEIVE_USER = 'RECEIVE_USER'

export const receiveCurrentUser = currentUser => ({
  type: RECEIVE_CURRENT_USER,
  currentUser
})

export const logoutCurrentUser = () => ({
  type: LOGOUT_CURRENT_USER,
})

export const receiveErrors = errors => ({
  type: RECEIVE_SESSION_ERRORS,
  errors: errors
})

export const removeErrors = () => ({
  type: REMOVE_SESSION_ERRORS
})

export const fetchUser = user => ({
  type: RECEIVE_USER,
  user
})

export const signup = user => dispatch => (
  APIUtil.signup(user).then(response => {
    Session.storeToken(response.data)
    dispatch(receiveCurrentUser(response.data))
  }, err => (
    dispatch(receiveErrors(err))
  ))
)

export const login = user => dispatch => (
  APIUtil.login(user).then(response => {
    Session.storeToken(response.data)
    dispatch(receiveCurrentUser(response.data))
  }, err => (
    dispatch(receiveErrors(err))
  ))
)

export const logout = () => dispatch => (
  APIUtil.logout().then((response) => {
    dispatch(logoutCurrentUser())
    Session.removeToken()
  }).catch(err => (
    dispatch(receiveErrors(err))
  ))
)

export const userInfo = () => dispatch => (
  APIUtil.userInfo().then(user => {
    dispatch(receiveCurrentUser(user.data))
  }).catch(err => {
    Session.removeToken()
    dispatch(receiveErrors(err))
  })
)

import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from 'ckeditor5-build-classic'
import './editor.css'

class PageEditor extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <div>
        <CKEditor
          editor={ClassicEditor}
          data={this.props.data}
          isReadOnly={true}
          onChange={(event, editor) => {
            const data = editor.getData()
            this.props.onChange(data)
          }}
        />
      </div>
    )
  }
}

export default PageEditor

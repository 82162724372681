import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './index.css'
import { createCategory, deleteCategory, editCategory, fetchAllCategories } from '../../../services/CategoryService'
import FileUpload from '../../../components/file/FileUpload'
import { DOC_TYPE_IMAGE, TABLE_DATA_TYPE } from '../../../utils/Contants'
import { withTranslation } from 'react-i18next'
import ReactEditableTable from '../../../components/table/ReactEditableTable'
import { fetchAllMenus } from '../../../services/MenuService'

class CategoryManagement extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      image: '',
    }

    this.changeName = this.changeName.bind(this)
    this.updateImage = this.updateImage.bind(this)
    this.saveItem = this.saveItem.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.createCategory = this.createCategory.bind(this)
  }

  componentDidMount () {
    this.props.fetchAllCategories()
  }

  changeName (e) {
    this.setState({
      name: e.target.value
    })
  }

  updateImage (fileId) {
    this.setState({
      image: fileId
    })
  }

  saveItem (categoryId, category) {
    this.props.editCategory(categoryId, category).then(() => {
      this.props.fetchAllMenus()
    })
  }

  deleteItem (categoryId) {
    this.props.deleteCategory(categoryId).then(() => {
      this.props.fetchAllMenus()
      this.props.fetchAllCategories()
    })
  }

  createCategory () {
    let category = {
      name: this.state.name,
      image: this.state.image
    }
    this.props.createCategory(category).then(() => {
      this.props.fetchAllMenus()
      this.setState({
        name: '',
        image: ''
      })
    })
  }

  render () {
    const { t } = this.props

    return (
      <div>
        <div className="heading-creation-box">
          {t('Category Management')}
        </div>
        <div>
          <div className="category-creation-box">
            <div className="category-creation-item">
              <span className="label-item">{t('label.name')}:</span>
              <input className="category-creation-input-text" type="text" value={this.state.name}
                     onChange={this.changeName}/>
            </div>
            <div className="category-creation-item">
              <span className="label-item">{t('label.image')}:</span>
              <FileUpload docType={DOC_TYPE_IMAGE} onUpdateImage={this.updateImage}/>
            </div>
          </div>
          <button onClick={this.createCategory} className="create-button">
            {t('management.category.create')}
          </button>
          <ReactEditableTable
            onSaveItem={this.saveItem}
            onDeleteItem={this.deleteItem}
            data={this.props.categories}
            columns={[
              {
                Header: t('management.category.column.name'),
                accessor: 'name',
                minWidth: 50,
                type: TABLE_DATA_TYPE.TEXT_TYPE
              },
              {
                Header: t('management.category.column.image'),
                accessor: 'image',
                width: 200,
                type: TABLE_DATA_TYPE.IMAGE_TYPE
              }
            ]}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  categories: Object.values(state.categories),
})

const mapDispatchToProps = dispatch => ({
  fetchAllCategories: () => dispatch(fetchAllCategories()),
  createCategory: (category) => dispatch(createCategory(category)),
  deleteCategory: (categoryId) => dispatch(deleteCategory(categoryId)),
  editCategory: (categoryId, category) => dispatch(editCategory(categoryId, category)),
  fetchAllMenus: () => dispatch(fetchAllMenus()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CategoryManagement)))
